.parent-container {
    width: 100%;
    margin-top: -2.5rem;
    padding: 1rem;
    display: grid;
    place-items: center;
    display: flex;
    flex-direction: column;
    background: #ffffff !important;
}

.UploadIdAndDocument-title {
    text-align: center;
    font-size: 2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #05595B;
    text-transform: uppercase;
}

.id-proof-container {
    background: #fff;
    padding: 1rem 0.5rem;
    margin: 1rem;
    box-shadow: 0 5px 2rem 1px lightgrey;
    border-radius: 0.5rem;
}

.id-proof-title-tag {
    text-align: center;
    margin-bottom: 1rem;
}

.id-proof-input-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.id-proofi-input-label {
    color: #ec167f;
}

.file {
    background: #f6f6f6;
    padding: 0.5rem;
    border: 1px solid lightgrey;
    border-radius: 0.25rem;
}

.alert-text {
    color: red;
    font-size: small;
    margin-top: 0.5rem;
}

.uploaded-file-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.id-proof-submit-section {
    margin-top: 1rem;
    display: grid;
    place-items: center;
}

.id-page-submit-button1,
.id-page-submit-button2 {
    color: #ec167f !important;
    width: fit-content !important;
    box-shadow: 0 0 0.05rem 0.05rem #ec167f !important;
    background: #fff !important;
    border: 1px solid transparent !important;
    padding: 0.5rem 3rem !important;
    border-radius: 0.25rem !important;
    margin: auto !important;
    letter-spacing: 1px !important;
    transition: all 0.3s !important;

    &:hover {
        transform: translateY(1px);
        border: 1px solid transparent;
        border: 1px solid #ec167f;

    }
}

@media screen and (width <=430px) {
    .id-proof-input-wrapper {
        flex-direction: column;
    }
}