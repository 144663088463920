.profile-card-pop1 {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.profilecard-popup-container1{
    width: 40%;
    /* height: 650px; */
    display: flex;
    flex-direction: column;
    background:whitesmoke;
    border-radius: 10px;
    text-align: center;
}

@media (max-width:768px) {
    .profilecard-popup-container1{
        width: 90%;
        height:500px;
    }
}