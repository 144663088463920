.card-modal {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%; /* Adjust width for smaller screens */
  max-width: 500px; /* Maximum width */
  height: 520px;
  margin-top: -390px;
}

.modal-img{
  width: 50px;
  height:50px;
  margin-left: -60px;
  margin-bottom: -70px;
}

.modal-card {
  margin-left: 50px;
  margin-bottom: 10px;
  z-index: 9999;
}

.modal-details{
  margin-left: 15px;
}

.complete-profile-btn {
  background-color: maroon;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  height: 60px;
  margin-top: 20px;
}

.complete-profile-btn:hover {
  background-color:  rgb(61, 61, 190);
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* Media query for smaller screens */
@media screen and (max-width: 600px) {
  .card-modal {
    width: 90%;
    margin-top: -115%;
    height: auto; /* Adjust height for smaller screens */
    z-index: 9999;
  }

  
  .modal-img {
    margin-left: 0;
    margin-bottom: 0;
  }

  .modal-card {
    margin-left: 20px;

  }

  .modal-details {
    margin-left: 10px;
  }

  .complete-profile-btn {
    height: auto; /* Adjust height for smaller screens */
    margin-top: 10px;
  }
}
