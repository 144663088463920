/* * {
  box-sizing: border-box;
} */

.chatApp-body{
  margin-top: -80px;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100vh;
  background: url('https://cdn.pixabay.com/video/2017/12/20/13495-248644905_large.mp4') no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover; /* For older versions of Chrome and Safari */
}

.container-ChatApp{
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 100%;
  background-color: #f2f2f2;
  background: url('https://cdn.pixabay.com/video/2017/12/20/13495-248644905_large.mp4') no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover; /* For older versions of Chrome and Safari */
}

.header-ChatApp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  height: 90px;
  
}

.username {
  display: flex;
  align-items: center;
  font-weight: bolder;
}
.profile-icon {
  width: 160px; 
  height: 160px;
  /* border-radius: 50%; */
  margin-right: 10px;
  overflow: hidden;
  
}

.profile-icon img {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
}


.call-icons {
  display: flex;
}

.call-icon,
.video-call-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  margin-left: 10px;
  overflow: hidden;
}

.call-icon img,
.video-call-icon img {
  width: 100%;
  height: auto;
}

.chat-container {
  display: inline-block;
  flex: 1;
  padding: 10px 20px;
  overflow-y: auto;
  background-color: #f0fff0;
}

.message {
  display: flex;
  margin-bottom: 10px;
  background: transparent;
}

.user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* background-color: green; */
  margin-right: 10px;
}

.message-body {
  background-color: black;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
}

.footer-ChatApp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 10px 20px;
  background-color: aquamarine;
  border-top: 1px solid #ddd;
}

.input-file-ChatApp {
  flex: 1;
  margin-right: 10px;
  border-radius: 5px;
  border:2px solid black;
  font-weight: bolder;
}

.input-text-ChatApp{
  flex: 1;
  margin-right: 10px;
  border-radius: 5px;
  border:2px solid black;
  font-weight: bolder;
}

.send-button {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bolder;
  
}
.notification {
  position: fixed;
  bottom: 20px; 
  right: 20px; 
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
}
