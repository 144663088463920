
.page-title-safe {
  
    padding-left: 90px;
  }
  .title_safematrimony{
    color:rgb(73, 3, 32);
    font-size:20px;
    font-weight: 700;
  }

  .text_privacy {
    margin-top: 20px;
    max-height: none; 
    overflow: visible; 
}


@media only screen and (max-width: 767px) {
    .page-title-safe {
      margin: 80px 15px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .page-title-safe {
      margin: 60px 30px;
    }
  }
  

  @media only screen and (min-width: 992px) {
    .page-title-safe {
      margin: 118px 90px;
    }
  }

  