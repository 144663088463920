.container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .success-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .description {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
  }
  