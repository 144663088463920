.rowha{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-lg-6,
.col-md-6,
.col-sm-6 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.btnha {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
@media screen and (prefers-reduced-motion:reduce) {
    .btnha {
        transition: none;
    }
}
.btnha:focus,
.btnha:hover {
    text-decoration: none;
}
.btnha:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}
.btnha:disabled {
    opacity: .65;
}
.btnha:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btnha:not(:disabled):not(.disabled):active {
    background-image: none;
}
.d-none {
    display: none !important;
}
@media (min-width:576px) {
    .d-sm-block {
        display: block !important;
    }
}
@media (min-width:768px) {
    .d-md-block {
        display: block !important;
    }
}
@media (min-width:992px) {
    .d-lg-block {
        display: block !important;
    }
}
@media (min-width:1200px) {
    .d-xl-block {
        display: block !important;
    }
}
.textha {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: #888888;
    font-weight: 400;
    margin-bottom: 30px;
}
.auto-containerha {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}
ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
img {
    display: inline-block;
    max-width: 100%;
}
.theme-btnha {
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.theme-btnha {
    position: relative;
    transition: all 300ms ease;
}
.btn-style-threeha {
    position: relative;
    padding: 10px 30px;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    background-color: #eb2869;
    overflow: hidden;
    border-radius: 10px;
}
.btn-style-threeha .btn-titleha {
    position: relative;
}
.btn-style-threeha:hover {
    color: #1c94d2;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
}
.btn-style-threeha:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background-color: #ffffff;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
    -webkit-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
}
.btn-style-threeha:hover:before {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
}
.btn-titleha{
    color: white;
    font-size: 20px;
    font-weight: 600;
}
.list-style-oneha {
    position: relative;
}
.list-style-oneha li {
    position: relative;
    font-size: 15px;
    line-height: 26px;
    color: #444444;
    font-weight: 500;
    padding-left: 35px;
    margin-bottom: 10px;
    margin-left: 0px;
}
.list-style-oneha li:before {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 16px;
    content: "\52";
    font-family: 'ElegantIcons';
    color: #e1137b;
}
img {
    display: inline-block;
    max-width: 140%;
    height: auto;
}
.titleha{
    font-size: 40px;
    font-weight: 700;
    background: url('https://www.shutterstock.com/image-vector/orange-blue-pink-gradient-color-260nw-787735393.jpg');
    background-clip: text;
    color: transparent;
 
 }
.sec-titleha {
    position: relative;
    margin-bottom: 30px;
}
.sec-titleha .title {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 1em;
    color: #ff8a01;
    font-weight: 500;
    background: rgb(247, 0, 104);
    background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
    background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
    background: linear-gradient(to left, rgba(247, 0, 104) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
    color: transparent;
    /* -webkit-background-clip: text; */
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 15px;
}
.sec-titleha h2 {
    position: relative;
    display: inline-block;
    font-size: 48px;
    line-height: 1.2em;
    color: #1e1f36;
    font-weight: 700;
}
.sec-titleha .textha {
    position: relative;
    font-size: 16px;
    line-height: 28px;
    color: #888888;
    margin-top: 30px;
}
.about-sectionha {
    position: relative;
    padding: 50px 0 30px;
}
.about-sectionha .content-column {
    position: relative;
    margin-bottom: 50px;
}
.about-sectionha .content-column .inner-columnha {
    position: relative;
    padding-right: 80px;
}
.about-sectionha .content-column .sec-titleha {
    position: relative;
    margin-bottom: 20px;
}
.about-sectionha .content-column .sec-titleha .textha {
    margin-top: 20px;
}
.about-sectionha .content-column .sec-titleha h2 {
    font-size: 40px;
}
.about-sectionha .content-column .list-style-oneha {
    margin-bottom: 30px;
}
.about-sectionha .content-column .btn-boxha {
    position: relative;
}
.about-sectionha .content-column .btn-boxha a {
    padding: 15px 30px;
    line-height: 20px;
    font-weight: 600;
    font-size: 15px;
}
.about-sectionha .image-column {
    position: relative;
    margin-bottom: 50px;
}
.about-sectionha .image-column .image-box {
    position: relative;
    padding-left: 80px;
}
.about-sectionha .image-column .image-box img {
    position: relative;
    z-index: 1;
    border-radius: 50%;
    border: 15px solid #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
}
.about-sectionha .image-column .image {
    position: relative;
    margin-bottom: 0;
}
.about-sectionha .image-column .image:before {
    position: absolute;
    right: -12%;
    top: -10%;
    height: 120%;
    width: 100%;
    background-image: url(https://www.matrimonysoftware.in/images/icons/shape-2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    -webkit-animation: dizzling 10s infinite;
    -moz-animation: dizzling 10s infinite;
    -ms-animation: dizzling 10s infinite;
    -o-animation: dizzling 10s infinite;
    animation: dizzling 10s infinite;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    content: "";
}
.about-sectionha .icon-dots {
    left: 50%;
    top: 30%;
}

.about-sectionha .icon-circle-1 {
    left: -200px;
    top: -200px;
    opacity: .05;
}
.btn-boxha{
    display: flex;
    margin-left: 80px;
}
@media only screen and (max-width: 845px) {
    .about-sectionha .content-column .inner-column {
        padding-right: 0;
    }
}
@media only screen and (max-width: 850px) {
    .about-sectionha .icon-dots {
        display: none;
    }
    .about-sectionha .image-column .image-box {
        padding-left: 0;
        text-align: center;
    }
    .about-section .image-column .image {
        max-width: 840px;
        display: inline-block;
    }
}
@media (min-width:310px) and (max-width: 480px) {
    .btn-boxha {
        margin-top: -26px;
        margin-bottom: 10px;
        margin-left: 100px;
    }
    .about-sectionha {
        margin-top: -60px;
    }
    .sec-titleha {
        margin-bottom: 40px;
        margin-top: 12px;
    }
    .about-sectionha .content-column {
        position: relative;
        margin-bottom: 5px;
    }
    .content-column {
        margin-top: 0px;
    }
    .about-sectionha .image-column {
        display: none;
    }
    .sec-titleha {
        margin-bottom: 40px;
    }
    .sec-titleha h2 {
        font-size: 12px;
    }
    .about-sectionha {
        padding: 20px 0 20px;
    }
    .about-section .content-column .sec-titleha h2,
    .sec-titleha h2 {
        font-size: 28px;
        line-height: 1.2em;
    }
}
.theme-btnha {
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.theme-btnha {
    position: relative;
    transition: all 300ms ease;
}
.about-sectionha {
    padding-top: 100px;
}
.about-sectionha {
    position: relative;
    padding: 50px 0 5px;
}
@media screen and (max-width:480px) {
    .titleha{
font-size: 20px;
justify-content: center;
padding-top: 50px;
color: #eb2869;
margin-left: -40px;
padding-left: 100px;
    }
     .sec-titleha h2{
        font-size: 25px;
        width: 500px;
        margin-left: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 150px;
    } 
    .textha{
        width: 450px;
        margin-left: 20px;
        padding-left: 40px;
        padding-right: 50px;
        font-size: 20px;
        display: block; 
        flex-direction: column;
    }
    .btn-boxha{
        padding-top: 2rem;
    }
    .about-sectionha{
        width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .auto-containerha{
        width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .list-style-oneha{
        width: 500px;
        display: flex;
        flex-direction: column;
      align-items: flex-start;
        justify-content: flex-start;
    }
    .list-style-oneha li{
        margin-left: 20px;
    }
    .about-sectionha .content-column {
        position: relative;
        display: flex;
        flex-direction: row;
    }
    .sec-titleha {
        margin-bottom: 40px;
        margin-top: 40px;
    }
}
@media (min-width:768px) and (max-width:1024px){
    .inner-columnha{
        width: 100%;
        padding: 0px 50px;
     
    }
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
 
    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.5, .5, .5);
        transform: scale3d(.5, .5, .5);
    }
    50% {
        opacity: 1;
    }
}
@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.5, .5, .5);
        transform: scale3d(.5, .5, .5);
    }
    50% {
        opacity: 1;
    }
}
@keyframes dizzling {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        -webkit-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
        -o-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    100% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
}
