.previous,
.next {
    font-size: 15px;
    font-weight: 500;
    background-color: rgb(240, 60, 210);
    border: none;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 10px 10px;
    cursor: pointer;
    font-size: medium;
}

.previous:hover {
    background-color: rgb(35, 85, 192);
}

.next:hover {
    background-color: rgb(35, 85, 192);
}

.previous:active {
    scale: 95%;
}

.next:active {
    scale: 95%;
}

#step {
    background-color: gray;
    color: white;
    text-align: center;
    padding: 1.1rem;
    border-radius: 50%;
    width: 20px;
    height: 20px;

}

.num {
    position: relative;
    bottom: 10px;
    right: 5px;
}

#step-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0.5rem;
}

#progress {
    display: block;
    height: 9px;
    position: absolute;
    background-color: rgb(247, 32, 32);
    top: 40%;
    left: 0;
    z-index: -1;
}

.active {
    background-color: rgb(233, 53, 30) !important;
}

.stepper-btn {
    display: flex;
    justify-content: center;
}

.stepper-text {
    color: white;
}