.main-container{
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    width:95%;
    margin-top: 170px;
    margin-left: 30px;
    margin-bottom: 50px;
}

.main-profile{
  margin-top: -70px;
}

.complete_profile-main-user{
  /* width:100%;
  min-height:50px;
 
  margin-top:10px; */
  display: none;
}

/* .complete_profile-main-user p {
  margin-bottom: 0px;
} */

/* .complete_profile-main-user input[type="range"] {
  width: 100px;
  height: 10px;
  margin-left: 200px;
  cursor: pointer;
  appearance: none;

  border-radius: 5px;
} */

.complete_profile-main-user input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 50%;
  cursor: pointer;
}

.complete_profile-main-user input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

/* .complete_profile-main-user input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
} */


.drawer-content .label {
    font-weight: bold;
    margin-top: 10px;
  }
  
 
  .drawer-content .label {
    color: #ff5722; 
  }
  
  .info-container {
    display: flex;
    flex-direction: column;
  }
  
  .label-value {
    display: flex;
    margin-bottom: 8px;
  }
  
  .label {
    font-weight: bold;
    margin-right: 8px;
  }
  
  .value {
    flex-grow: 1;
    margin-top: 10px; 
  }
  
.drawer-content {
    width: 250px;
    padding: 20px;
    margin-top: 20px;
    /* border: 4px solid #ccc; */
  }
  
  .drawer-content img {
    margin-bottom: 20px; 
    border: 4px solid #ccc;
  }
  
  .drawer-content b {
    font-family: 'Arial', sans-serif; 
    font-size: 18px; 
  }
  
  .drawer-content div {
    margin-bottom: 5px; 
  }
  
  
  .drawer-content .label {
    color: #ff5733; 
  }
  
.update-user{
  
    position: absolute;
    margin-top: -50%;
    margin-left: 410px;
}
.main-profile{
    background-image: linear-gradient(to right,rgb(255, 0, 149),rgb(250, 250, 246));
    display: flex;
    justify-content: center;
}
.Image-profile{
    display: flex;
}
.Image-profile img{
    height: 150px;
    width: 150px;
    border-radius: 50%;
    object-fit:fill;
    margin-top: 20px;
 
}
.Name-employe{
    color: rgb(3, 3, 71);
    margin-left: 10px;
    margin-top: 60px;
    font-size:larger;

}
/* .progress-container {
  position: relative;
} */


.file_upload_button{
    background-color: transparent;
}

#view-profile-button{
    color: blueviolet;
    font-size: larger;
    font-weight: 700;
    text-decoration: underline;
    margin-left: 15px;
}

@media (max-width:768px){
    .main-container{
        width: 90%;
        margin-left: 20px;
    }
    .Name-employe{
       
        font-size:2px;
        font-weight: 400;
        color: rgb(3, 3, 71);
        margin-left: 10px;
        margin-top: 60px;
        font-size:15px;
    
    }
   
    .progress-container {
      display: none;
    }
    .update-user{
      display: none;
    }
    
.complete_profile-main-user{
  width:100%;
  text-align: center;
  display: block;
  min-height:50px;
  /* background:whitesmoke; */
  margin-top:10px;
}

.complete_profile-main-user p {
  margin-bottom: 0px;
}

.complete_profile-main-user input[type="range"] {
  width: 100px;
  height: 10px;
  margin-left: 200px;
  cursor: pointer;
  appearance: none;
  /* background-color: #ddd; */
  border-radius: 5px;
}

.complete_profile-main-user input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 50%;
  cursor: pointer;
}

.complete_profile-main-user input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.complete_profile-main-user input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}
.main-profile{
  margin-top: -80px;
}

}

@media (max-width:360px){
  .Image-profile img{
    width: 100px;
  }
}