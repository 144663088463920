
   .phone-settings, .photo-settings, .horoscope-settings, .profile-settings {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 20px;
  }
  .my-settings-container-pro {
    border: 4px solid burlywood;
    margin-bottom: 20px; /* Add border around the entire settings container */
    padding: 10px;
  }
  
  
  .profile-sections {
    display: flex;
    justify-content: space-between;
  }
  
  .section {
    flex: 1;
    margin-right: 20px;
  }
  
  .section:last-child {
    margin-right: 0;
  }
  
 
  
  
  
  
  .profile-info {
    margin-bottom: 10px;
    font-weight: 800;
    margin-left: 30px;
  }

 

.profile-info {
  margin-bottom: 10px;
}

.info-label {
  font-weight: bold;
}
 

.profile-table {
  width: 100%;
  margin-bottom: 60px;
  border-collapse: collapse;
}

.profile-table td {
  padding: 8px;
  border-bottom: 3px solid #ddd;
 
}

.info-label {
  font-weight: bold;
}

.edit-cell {
  cursor: pointer;
  color: blue;
}


.edit-cell:hover {
  text-decoration: underline;
}


  
 
  .set-btn{
    margin-bottom: 20px;
  }
  .pri-btn {
    background-color: pink; /* Blue color, you can change it to any color you prefer */
    color: black;
    width:40px;
    border: none;
    margin-left: 0%;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 50%; /* This will make it circular */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pri-btn:hover {
    background-color: #0056b3; /* Darker shade of blue on hover */
  }
  
  
  .phone-privacy-options, .horoscope-privacy-options {
    display: flex;
    flex-direction: column;
    /* margin-bottom: -430px; */
  }
  .profile-privacy-options-user{
    display: flex;
    flex-direction: column;
    /* margin-bottom: -290px; */
  
  }
  
  .radio-setting-btn[type="radio"] {
    margin-top: 30px;
    margin-right: 5px;
    height: 20px;
  
  }
  
  .label-setting{
    margin-right: 390px;
    margin-top: -20px;
   
  }
  .photo-use{
    margin-top: 0px;
  }
  
  .save-btn {
    
    width:20%;
    margin-bottom: -800px;
    margin-left: -300px;
  }
  .save-btn0{
    width:20%;
    margin-bottom: -800px;
    margin-left: -300px;
  }
  .save-btns{
    width:20%;
    margin-bottom: -900px;
    margin-left: -500px;
    
  }
  .save-button{
    width:20%;
    margin-bottom: -300px;
  }
  .privacy-setting-pro, .photo-head {
    color: rgb(202, 11, 123);
    
  }

 
   .privacy-setting, .photo-head {
    color: rgb(202, 11, 123);
    height:150px
  }
  .photo-head1 {
    text-align: center;
   margin-top: -230px;
 margin-right: 60px;
 color: black;
 font-size: 20px;
 font-weight: 700;
  }

  .photo-head2 {
    text-align: center;
   margin-top: -20px;
   margin-right: 60px;
   color: black;
   font-size: 20px;
   font-weight: 700;
  }
  .photo-head3 {
    text-align: center;
   margin-top: 30px;
   margin-right: 60px;
   color: black;
   font-size: 20px;
   font-weight: 700;
  }
  .photo-head4 {
    text-align: center;
   margin-top: -100px;
   color: black;
   font-size: 20px;
   font-weight: 700;
  
  }
  
  .buttons-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .save-btn {
    background-color: pink;
    color: black;
    padding: 10px 20px;
    width:20%;
    border: none;
    border-radius: 4px;
    margin: 50px;
    cursor: pointer;
  }
  .save-button{
    background-color: pink;
    color: black;
    padding: 10px 20px;
    width:20%;
    border: none;
    border-radius: 4px;
    margin: 50px;
    cursor: pointer;
  }
  
  /* button:hover {
    background-color:  pink;
  }
   */
  .content-container {
    margin-top: 20px;
    height: 400px;
  }
  .buttons-container button{
    color: blue;
    font-size: 20px;
    font-weight: 700;
  }
  
  /* Media Query for screens smaller than 600px */
  @media screen and (max-width: 480px) {
    .my-settings-container {
      max-width: 100%;
    }
    .label-setting{
      margin-left: 330px;
    }
    .radio-setting-btn[type="radio"] {
      margin-left: 290px;
      margin-top: 40px;
    }
  
    .buttons-container {
      flex-direction: column;
      align-items: center;
      width:100%;
      /* margin-left: 100px; */
      gap: 20px;
      /* margin: 20px 0; */
      margin-bottom: 300px;
    }

    .photo-head2{
      margin-top: -350px;
    }

    .photo-head3{
      margin-top: -250px;
    }

    .photo-head4{
      margin-top: -230px;
    }
  
    .phone-privacy-options,
    .horoscope-privacy-options,
    .profile-privacy-options-user {
      margin-bottom: 0; /* Adjust margins as needed */
    }
  
    .save-btn,
    .save-btn0,
    .save-btns,
    .save-button {
      width: 100%; /* Full width on smaller screens */
      margin: 10px 0; /* Adjust margins as needed */
    }
  
    /* Add more responsive styles as needed */
  }
  
  @media screen and (max-width: 1100px) and (min-width:770px){
    .phone-privacy-options{
      margin-top: 250px;
    }
    .profile-privacy-options-user{
      margin-top: 100px;
    }
    .photo-head4{
      margin-top: 40px;
      margin-bottom: -70px;
    }
    .photo-head1{
      margin-top: 50px;
      margin-bottom: -200px;
    }
    
  }
  /* Default styles */

.profile-sections {
  display: flex;
  flex-wrap: wrap; /* Allow sections to wrap on smaller screens */
}

.section {
  flex: 1 1 300px; /* Each section occupies at least 300px width */
  margin-bottom: 20px;
}

/* Adjust button width for smaller screens */
.section button {
  width: 40%; /* Make buttons occupy full width */
}

/* Adjust section width for smaller screens */
@media only screen and (max-width: 768px) {
  .section {
    flex-basis: calc(50% - 10px); /* Each section occupies 50% width with some spacing */
  }
  .section button {
    width: 40%;
    justify-content: center;
    margin-left: 20%; /* Make buttons occupy full width */
  }
  
}

/* Adjust section width for even smaller screens */
@media only screen and (max-width: 576px) {
  .section {
    flex-basis: 100%; /* Each section occupies full width */
  }
}

