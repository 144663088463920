.App-title {
  background: url("https://img.freepik.com/free-vector/gradient-grainy-gradient-background_23-2149911306.jpg?size=626&ext=jpg&ga=GA1.1.1705547883.1714394798&semt=ais_user");
  background-clip: text;
  color: transparent;
}
.title-App {
  font-size: 40px;
  font-weight: 700;
  background: url("https://img.freepik.com/free-vector/gradient-grainy-gradient-background_23-2149911306.jpg?size=626&ext=jpg&ga=GA1.1.1705547883.1714394798&semt=ais_user");
  background-clip: text;
  color: transparent;
}
.text-boxapp {
  color: rgba(29, 27, 27, 0.877);
  font-weight: 600;
}
.subtext-boxapp {
  color: rgba(29, 27, 27, 0.849);
  width: 100%;
  font-weight: 600;
}
.rowapp {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.auto-containerapp {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}
.sec-titleapp {
  position: relative;
  margin-bottom: 30px;
}
.app-sectionapp {
  width: 100%;
  height: 400px;
  position: relative;
  background-image: url("https://images.pexels.com/photos/1939485/pexels-photo-1939485.jpeg?auto=compress&cs=tinysrgb&w=600");
  padding: 10px 10px;
  z-index: 9;
  margin-top: 190px;
}
.app-sectionapp .image-column {
  position: relative;
}
.app-sectionapp .image-column .inner-columnapp {
  position: relative;
  text-align: right;
}
.app-sectionapp .image-column .image-boxapp {
  position: relative;
}
.app-sectionapp .image {
  position: relative;
  margin-bottom: 0;
}
.app-sectionapp .image img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.app-sectionapp .content-column {
  position: relative;
  margin-bottom: 50px;
}
.app-sectionapp .content-column .inner-columnapp {
  position: relative;
  padding-top: 80px;
}
.app-sectionapp .content-column .sec-titleapp {
  margin-bottom: 30px;
}
.app-sectionapp .content-column .text-boxapp {
  font-size: 16px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1023px) {
  .app-sectionapp,
  .app-sectionapp .image-column .image-boxapp {
    text-align: center;
  }
  .app-sectionapp .content-column .inner-columnapp {
    padding-top: 70px;
  }
}
figure img {
  border-radius: 50%;
  margin-top: 30px;
}
@media only screen and (min-width: 340px) and (max-width: 480px) {
  .app-sectionapp {
    width: 100%;
    height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    padding: 10px 10px;
  }
  .auto-containerapp {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .rowapp {
    width: 100%;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .inner-columnapp {
    width: 100%;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .image-boxapp {
    width: 90%;
    display: inline-block;
    flex-direction: column;
    justify-content: center;
    display: none;
  }
  .sec-titleapp {
    width: 100%;
  }
  .sec-titleapp h2 {
    font-size: 20px;
    color: black;
  }
  .text-boxapp {
    width: 100%;
    display: inline-block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .link-box img {
    width: 90%;
  }
  figure img {
    width: 90%;
  }
}
