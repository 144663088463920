.page-title-aboutus{
    margin-top: 50px;
}

.aem-Grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
   
}
/* .footer-card{
    margin-top: 100px;
} */
.aem-GridColumn {
    padding: 0 15px;
}

.aem-Grid--12>.aem-GridColumn--default--4 {
   
    width: 95%;
    margin: auto;
}

.aem-Grid--12>.aem-GridColumn--default--12 {
    width: auto;
}

.container__base .cmp-container {
   
  
    position: relative;
}

.container__base .cmp-container:before,
.container__base .cmp-container:after {
    content: '';
    display: table;
}

.container__base .cmp-container:after {
    clear: both;
}


.text__container {
    word-wrap: break-word;
    
}

.text__container h1{
    margin-top: -100px;
    text-align: center;
    word-wrap: break-word;
    clear: both;
}

.text__container h2 {
    font-size: 2rem;
    font-family: var(--ff-header-bold);
    font-weight: 400;
    letter-spacing: var(--ls-header);
    line-height: 1.1;
    padding-top: .1337em;
    padding-bottom: .1073em;
    margin-bottom: .3125em;
}

.text__containerab h3 {
    font-size: 1rem;
    font-family: var(--ff-header-bold);
    font-weight: 400;
    letter-spacing: var(--ls-header);
    line-height: 1.5;
    padding-top: .251em;
    padding-bottom: .215em;
}
.image-container-about{
    display: flex;
    margin: auto;
}
.content-matrimony {
    width: 100%;
    display: block;
    flex-direction: column;
    justify-content: center;
    
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 1.5;
   
}

.content-container{
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
}
.image-container-about {
    width: 70%;
    margin: auto;
}

.image-container-about img{
   
    width: 100%;
   
}
.aboutimage{
    width: 100%;
}

.floating-image-top {
   
    float:left;
    width:30%;
    margin-right: 20px;
  }
  
  .floating-image-bottom {
    
  margin-bottom: 120px;
  margin-top: 16px;
  margin-left: 20px;
   
    float:right;
  }
  .text-abouts{
    margin-top: 100px;
    color:black;
  }
  
 
@media screen and (max-width: 768px) {
    .content-container {
      flex-direction: column;
      margin-bottom: 30px;
    }
    .text__containerab {
        margin-top: 80px;
        margin-bottom: 50px;
    }
    .floating-image-top {
   
        float:left;
        margin-top: 4px;
        margin-right: 10px;
        width:43%;
      }
      
      .floating-image-bottom {
        
      margin-bottom: 5px;
      margin-top: 18px;
      margin-left: 9px;
       width:43%;
        float:right;
      }
  
    
    .content-matrimony {
        width: 100%;
        display: block;
        
       margin-top: -120px;
        font-size: 13px;
        font-weight: 600;
        text-align: justify;
        letter-spacing: -.4px;
        line-height: 1.2;
       
    }
    .aem-Grid {
        display: flex;
        flex-wrap: wrap;
        margin-top: 70px;
       
    }
    .text-abouts{
        margin-top: 30px;
    }
    
  }
  @media (max-width: 360px) {
    .container__base{
        margin-top: 100px;
    }
    .text__containerab {
        margin-top: 70px;
        margin-bottom: 90px;
    }
    .text-abouts{
        margin-top: 100px;
      }
  }

  


  