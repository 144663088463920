.my-paln-head{
    width: 100%;
    height: 90px;
    background-color: rgb(19, 19, 19);
    color:white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border-bottom-left-radius:25px ;
    border-bottom-right-radius: 25px;
}
.my-plan-table{
    width: 40%;
    height: 25rem;
    border: 0.5px solid black;
    background-color:transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    font-size:x-small;
    font-weight:bold;
    
}
.my-plan-table-content{
    width: 95%;
    height: 95%;
    margin: auto;
    background-color:whitesmoke;
}
.my-plan-table-row{
  color: black;
 
}
.my-plan-table-td{
    width: 50%;
    height: 25px;
    margin-left: 5px;
    padding-left: 10px;
    color: black;
    font-weight: bold;
    margin-bottom: 5px;
    border-top: 3px solid white;
    border-left:5px solid white ;
    border-right:5px solid white;
    border-bottom: 3px solid white; 
}

.my-plan-upgrade-btn{
    height: 10px;
    background:transparent;
    color:rgb(250, 5, 5);
    font-weight: bold;
    display:flex;
    margin-left: 120px;
    margin-top: 5px;
}

.my-plan-link{
    color: red !important;
    margin-right: 10px;
}
.my-plan-link:hover{
    color:blue !important;
}