/* Addcouplesimage.css */

/* General Styles */
.text-center {
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

/* Image Upload Styles */
.add-img {
    border: 2px dashed #ccc;
    padding: 20px;
    border-radius: 10px;
    background-color: #f8f8f8;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-img p {
    font-size: 16px;
    color: #333;
}

.add-img .fa-clone {
    font-size: 24px;
    color: #666;
    margin-top: 10px;
}

.add-img:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.add-img svg {
    color: #fff;
    font-size: 2em;
}

/* Couples Image Styles */
.couples-outer-box {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    border: 3px solid #fff;
    max-width: 650px;
    height: auto;
    background: lightgrey;
    border-radius: 10px;
    justify-content: space-between;
}

.couples-container {
    width: calc(50% - 56px);
    margin: 28px;
    padding: 45px;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    .couples-container {
        width: calc(100% - 56px);
    }
}

.couples-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed #aaa;
    padding: 8px;
    cursor: pointer;
}

.couples-img .fa-clone {
    font-size: 24px;
    margin-bottom: 10px;
    color: #555;
}

.couples-name {
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: rgb(201, 5, 119);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* Button Styles */
.button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
}

.button-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
}

.button-primary:hover {
    background-color: #0056b3;
}

.button-secondary {
    background-color: rgb(231, 21, 142);
    color: white;
    border: none;
}

.button-secondary:hover {
    background-color: rgb(240, 57, 164);
}
