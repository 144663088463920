.body{
    width: 100%;
    margin: auto;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.slider{
    position: relative;
    width: 180px;
    height: 170px;
    transform-style: preserve-3d;
    animation: rotate 30s linear infinite;
}
@keyframes rotate{
    0%{
        transform: perspective(1000px) rotateY(0deg);
    }
    100%{
        transform: perspective(1000px) rotateY(360deg);
    }
}
.slider span{
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(calc(var(--i)*45deg)) translateZ(350px);
}

.slider span img{
    position: absolute;
    top: 0;
    left: 30px;
    width: 200px;
    height: 200px;
    border-radius: 1px;
    gap: 0.5rem;
    object-fit: cover;
    transition: 2s;
    cursor: pointer;
    box-shadow: rgb(38,57,77) 0px 20px 30px -10px;
}
.slider span:hover img{
    transform: translateY(-15px) scale(1.2);
} 