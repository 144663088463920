.contact-form {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 2rem;
}

.form-group {
  margin-bottom: 50px;
}

.btn-box {
  margin-top: 20px;
}
.btn-submit{
  margin-bottom: 50px;
}

@media only screen and (max-width: 468px) {
  /* .li{
    width: 100px;
  } */
  .contact-form {
    max-width: 80%;
    margin-bottom: 20%;
    padding-bottom: 0%;
    /* margin-top: 100px; */
  }
  
  .btn-submit{
    margin-bottom: 0%;
    margin-top: 0%;
    padding-bottom: 10%;
    padding-top: 10%;
  }
  .page-title{
    margin-bottom: 10%;
    margin-top: 20px;
    width: 100%;

  }
  
}
