
 .container-verify {
   width: 100%;
   margin-top: -40px;
    padding: 20px;
   
  } 
  
  .verify{
    color:white;
    display: block;
    font-size: 50px;
    text-align: center;
    width:100%;
    height:140px;
    background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 9%,
        rgba(9, 9, 121, 1) 68%,
        rgba(0, 91, 255, 1) 97%
      );
  }
  
  /* .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
   */
  .col {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px;
    padding-left: 15px;
  }
  
   .card-profile {
    background-color: palevioletred;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
  } 
  .card-profile-verify {
    background-color: palevioletred;
    width: 100%;
    height: 370px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
  } 
  
  .card-profile h2 {
    margin-top: 0;
  }
  
  .form-group-profile {
    margin-bottom: 20px;
  }
  
  .verify-radio {
    display: block;
    
    margin-bottom: 5px;
  }
  
 
  
   .document-options {
    display: flex;
    flex-direction: column;
  } 
  
  .document-options label {
    margin-bottom: 0px;
  }
  .verify-btn{
    border-radius: 30px;
 padding: 10px;
    color: black;
    background-color: bisque;

  }
  .verify-btn:hover {
    background-color: #0056b3;
  }
 
  .form-group-profile p.consent {
    margin-top: 10px;
    font-style: italic;
    color: #666;
  }
  

.verification-pending-header {
  text-align: center;
}

.verification-pending-image {
  width: 80px; 
  height: 80px; 
  margin-bottom: 20px; 
  display: block; 
  margin-left: auto;
  margin-right: auto;
}

.verify {
  margin: 0; 
}
.consent-label {
  display: block;
  margin-top: 10px;
  font-style: italic;
  color: black
}

.consent-checkbox {
  margin-right: 5px;
}



  
 

  

  @media (max-width: 768px) {
    .col {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .verify{
      color:white;
      display: block;
      font-size: 40px;
      text-align: center;
      width:100%;
      height:140px;
      background: linear-gradient(
          90deg,
          rgba(2, 0, 36, 1) 9%,
          rgba(9, 9, 121, 1) 68%,
          rgba(0, 91, 255, 1) 97%
        );
    }
    .verification-pending-image {
      width: 80px; 
      height: 80px; 
      margin-bottom: 20px; 
      display: block; 
      padding: 0px;
      margin-left: auto;
      margin-right: auto;
    }
    
  }