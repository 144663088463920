.custom-select-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .custom-select {
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
    cursor: pointer;
    width: 100%;
  }
  
  .custom-select-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    margin-top: -2px;
  }
  
  .custom-select-search {
    width: calc(100% - 16px);
    padding: 8px;
    border: none;
    background-color: grey;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .custom-select-search:hover {
    background-color: blue;
  }
  
  .custom-select-options {
    max-height: 150px;
    overflow-y: auto;
  }
  
  .custom-select-option {
    padding: 8px;
    cursor: pointer;
  }
  
  .custom-select-option:hover {
    background-color: #f1f1f1;
  }
  /*  */

.custom-select-container{
    /* margin-left: -30px; */
    width: 98%;
}
/* .select-all{
    margin-left: 30px;
} */

/* .select-allc{
    margin-left: 20px;
} */

.row-profile{
    display: flex;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
}


/* .auto-container-profile{
    margin-left: -50px;
} */

.col-lg-3-profile,
.col-lg-5-profile,
.col-lg-9-profile,
.col-md-12-profile,
.col-md-5-profile,
.col-sm-6-profile,
.col-lg-10-profile,
.col-md-10-profile,
.col-sm-12-profile {
    position: relative;
    /* width: 1300px; */
    min-height: 1px;
    /* padding-right: 15px;
    padding-left: 15px; */
}

div.col-lg-3-profile.col-md-5-profile.col-sm-6-profile.form-group-profile{
    margin-right: 20px;
}
button.theme-btn-profie.btn-profile.btn-style-one-profile.mt-4-profile{
/* margin-left: 400px; */
width: 170px;
}




.form-group-profile {
    margin-bottom: 1rem;
}

.btn-profile {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}



.btn-profile:focus,
.btn-profile:hover {
    text-decoration: none;
}

.btn-profile:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-profile:disabled {
    opacity: 0.65;
}

.btn-profile:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn-profile:not(:disabled):not(.disabled):active {
    background-image: none;
}

.clearfix-profile::after {
    display: block;
    clear: both;
    content: "";
}


    input.agile-ltext-profiles{
        width: 98%;
    }
    select.agile-ltext-profiles{
        width: 300px;
    }
    textarea.agile-ltext-profiles{
        width:300px;
        height: 150px;
    }


.mt-2-profile {
    margin-top: 0.5rem !important;
}



.mt-3-profile {
    margin-top: 1rem !important;
}


.text-center {
    text-align: center !important;
}

.text-white {
    color: #fff !important;
}


.auto-container-profile {
    position: static;
    padding: 0px 15px;
    /* margin: 0 auto; */
    /* margin-top: 30px; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* border-radius: .5rem; */
}

.profile-wrappers {
    position: relative;
    margin: 0 auto;
    width: 100%;
    /* min-width: 300px;
    overflow: hidden; */
   
   
}


.theme-btn-profile {
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}


.theme-btn-profile {
    position: relative;
    transition: all 300ms ease;
}

.btn-style-one-profile {
    position: relative;
    padding: 10px 30px;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    background-color: #ec167f;
    overflow: hidden;
    border-radius: 10px;
}

.btn-style-one-profile .btn-title-profile {
    position: relative;
}

.btn-style-one-profile:hover {
    color: #ec167f;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.btn-style-one-profile:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background-color: #ffffff;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
    -webkit-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.btn-style-one-profile:hover:before {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
}





.profile-title {
    position: relative;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    padding: 120px 0;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
}

.profile-title h1 {
    position: relative;
    text-transform: uppercase;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color:#05595B;
    line-height: 1em;
    /* font-weight: 700; */
    margin-bottom: 10px;
}

.profile-title .bread-crumb-profile {
    position: relative;
}

.profile-title .bread-crumb-profile li {
    position: relative;
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 500;
    cursor: default;
    padding-right: 15px;
    margin-right: 15px;
}

.profile-title .bread-crumb-profile li:before {
    position: absolute;
    right: -3px;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 400;
    content: "|";
}

.profile-title .bread-crumb-profile li:last-child {
    padding-right: 0;
    margin-right: 0;
}

.profile-title .bread-crumb-profile li:last-child:before {
    display: none;
}

.profile-title .bread-crumb-profile li a {
    color: #ffffff;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}
.bgshadowwhite1 {
    background-color: #f3f3f3;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: .5rem;
    padding: 3rem;
    color: black;
    /* display: flex;
    flex-wrap: wrap; */
}

.profile-title .bread-crumb-profile li a:hover {
    color: #f0c52e;
}

.contact-page-section-profile {
    position: relative;
    padding: 110px 0 70px;
}

.contact-page-section-profile .form-column-profile {
    position: relative;
    margin-bottom: 40px;
    width: 80%;
}



.contact-form-profile .form-group-profile {
    position: relative;
    margin: 1rem auto;
    /* margin-bottom: 30px; */
}


.contact-form-profile .form-group-profile input:focus {
    border-color: #f20487;
}

.contact-page-section-profile .contact-column-profile {
    position: relative;
    margin-bottom: 30px;
}
.newsletter-section-profile {
    position: relative;
    padding: 9px 0;
    z-index: 1;
    background-color: white;
    display: flex;
    justify-content: space-around;
    /* background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 9%,
        rgba(9, 9, 121, 1) 68%,
        rgba(0, 91, 255, 1) 97%
      ); */
}



.label-profile{
    margin-left: 5px;
    margin-bottom: 2px;
    /* color: #ff719e; */
    color: black;

  
}

.profile-title {
    padding: 20px 0 1px 0;
}

.agile-ltext-profiles{
    
    width:100% ;
   
}

.theme-btn-profile.btn-profile.btn-style-one-profile.mt-4-profile{
  /* margin-left: 30%; */
  width: 25%;
  margin-top: 1rem;
 
}

/* .stateInput input{
    margin-left: 10px;
} */

select.agile-ltext-profiless{
    width: 100%;
}

select.agile-ltext-profilesss{
    width: 100%;
}


@media (max-width: 576px) {
    .col-sm-12-profile {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .contact-form-profile .form-group-profile {
        position: relative;
        /* margin: 1rem auto; */
        /* margin-bottom: 30px; */
    }
    div.col-lg-3-profile.col-md-5-profile.col-sm-6-profile.form-group-profile {
       margin: 1rem 1rem;
    }
}

@media (min-width: 768px) {
    .d-md-block-profile {
        display: block !important;
    }
    .col-md-5-profile, .col-lg-5-profile {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-md-12-profile {
        flex: 0 0 100%;
        max-width: 100%;
    }
    /* .contact-page-section-profile {
        padding: 21px 99px 3px 212px;
    } */
    /* .row-profile {
        margin-right: -54px;
        margin-left: -109px;
    } */
    /* .alertmes-profile {
        margin-right: -29px;
        margin-left: -185px;
    } */
}

@media (min-width: 992px) {
    .d-lg-block-profile, .d-xl-block-profile {
        display: block !important;
    }
    .col-lg-3-profile {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-9-profile {
        flex: 0 0 75%;
        max-width: 75%;
    }
    /* .col-lg-10-profile {
        margin-right: -400px;
        margin-left: -220px;
    } */
}

@media screen and (max-width: 568px) {
    .profile-title h1 {
        font-size: 40px;
        margin-bottom: 10px;
    }
    /* .alertmes-profile {
        margin-right: -29px;
        margin-left: -185px;
    } */
    
    input.agile-ltext-profiles, select.agile-ltext-profiles {
        width: 98%;
        /* margin-left: -20px; */
    }
}

@media only screen and (max-width: 450px) {
    .d-none-profile.d-lg-block-profile.d-xl-block-profile.d-md-block-profile {
        font-size: xx-large;
    }
    .label-profile {
        display: flex;
        flex-direction: column;
    }
    .agile-ltext-profiles {
        font-size: medium;
        width: 25%;
    }
    .theme-btn-profile.btn-profile.btn-style-one-profile.mt-4-profile {
        width: 25%;
    }
    .bgshadowwhite1{
        padding: .8rem;
      }
      .contact-page-section-profile .form-column-profile {
        width: 100%;
    }
    .row-profile {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 1px auto;
    }
    
}

@media only screen and (max-width: 1100px) and (min-width: 769px) {
    input.agile-ltext-profiles, select.agile-ltext-profiles {
        width: 200px;
    }
    .col-lg-3-profile, .col-lg-9-profile, .col-md-12-profile {
        margin-right: -400px;
        margin-left: -220px;
    }
    .theme-btn-profile.btn-profile.btn-style-one-profile.mt-4-profile {
        margin-left: 40%;
        width: 25%;
        margin-top: 5%;
    }
}

@media (max-width: 600px) {
    .profile-wrappers {
        width: 100%;
        /* margin-left: -10px; */
    }
    input.agile-ltext-profiles, select.agile-ltext-profiles {
        width: 98%;
        /* margin-left: -230px; */
        /* margin-top: 40px; */
    }
    .select-input {
        width: 1150px;
        margin-left: 200px;
        margin-top: -40px;
    }
    .theme-btn-profile.btn-profile.btn-style-one-profile.mt-4-profile {
        width: 250px;
    }
    input.stateInput, input.casteInput {
        width: 290px;
    }
    .state-select, .caste-select {
        margin-left: 210px;
        width: 100%;
        margin-top: -30px;
    }
    .edu-select, .sta-select, .cas-select {
        margin-left: -25px;
    }
    /* .auto-container-profile {
        margin-left: -2px;
    } */
}


/* CONVERTED 800 TO 579 */