.success-container{
  margin-top: 90px;
}

  .success-details{
    justify-content: center;
    align-items: center;
    display: flex;
    color: crimson;
  }

  .success-form {
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 9%,
      rgba(9, 9, 121, 1) 68%,
      rgba(0, 91, 255, 1) 97%
    );
    backdrop-filter: blur(9px);
  }


  .success-title {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: rgb(223, 217, 217);
  }

  .success-input[type='text'],
  .success-input[type='file'],
  .success-textarea,
  .success-button,
  .success-input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    box-sizing: border-box;
  }
  
  .success-textarea {
    resize: vertical; /* Allow vertical resize for textarea */
  }
  
  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .success-button {
    background-color: #4caf50;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    /* width: 100%; */
    
  }
  
  .success-button:hover {
    background-color: #45a049;
  }

  
.Image{
  margin-bottom: 20px;
}
  
  @media (max-width: 768px) {
    .success-form {
      width: 80%;
    }
  }