
.heading{
    display: flex;
    justify-content: center;
    color: rgb(225, 7, 138);
    background: whitesmoke;
    font-family: sans-serif;
    font-size:42px;
    padding-top: 10px;
}
  
  .change-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:300px;
    background:whitesmoke;
    padding: 20px;
    margin-bottom: 10px;
  }
  .change-password-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
  }
  

  .change-password-label {
    margin-bottom: 10px;
    font-size: large;
    font-weight: 500;
  }
  
  .password-input {
    padding: 8px;
    margin-bottom: 20px;
    width: 100%;
    border: none;
  }
  
  .btn-change {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  @media screen and (max-width: 600px) {
    .change-password-form {
      max-width: 300px;
    }
  }
  























/* .new-div{
    margin-bottom: 30px;

} */

/* @media (max-width:600px){
    .container-newpass{
        width: 90%;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
    .btn-change{
        margin-left: 70px;
        width: 200px;
    }
    .new-div{
        margin-bottom: 30px;
    
    }
} */
