.basic-search-container {
    padding: 20px;
  }
  
  .search-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  
  .search-input {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
  }
  
  .arrow-button {
    padding: 10px;
    cursor: pointer;
    background-color: rgb(219, 10, 10);
    color: white;
    border: none;
    border-radius: 7px;
    width: 50px;
    height: 44px;
  }
  
  .arrow-button:hover {
    background-color: red;
  }
  
  .search-options {
    display: flex;
    border-bottom: 3px solid rgb(219, 10, 10) ;
    padding-bottom: 15px;
    padding-top: 20px;
  }
  

.search-profile:hover{
    background-color: rgb(219, 10, 10);
    color: white;
    border-radius: 10px;
    width: 200px;
}

.advance-search:hover{
    background-color: rgb(219, 10, 10);
    color: white;
    border-radius: 10px;
    width: 240px;
  
}

.global-search:hover{
    background-color: rgb(219, 10, 10);
    color: white;
    border-radius: 10px;
    width: 200px;
}

  .search-profile-container {
    width: 1200px;
}

.search-option {
    margin: 0 10px;
    flex: 1;
    margin-right: -900px;
}

.advance-profile-container{
    width: 1200px;
   margin-left: -400px;
}

.global-profile-container{
    width: 1200px;
    margin-left: -820px;
}

@media (max-width: 600px) {

  .basic-search-container{
    width: 80%;
  }
  
 .search-profile{
  margin-left: -20px;
  font-size: 18px;
  border-bottom: 3px solid rgb(219, 10, 10) ;
 }

 .advance-search{
  margin-top: 0;
  font-size: 18px;
  margin-left: 5px;
  border-bottom: 3px solid rgb(219, 10, 10) ;
 }

 .global-search{
  margin-top: 0;
  margin-left: 50px;
  font-size: 18px;
  border-bottom: 3px solid rgb(219, 10, 10) ;
 }
/* 
  .search-option {
  display: flex;
  justify-content: space-between;
  } */

  .search-option-search{
    margin-left: -30px;
    width: 500px;
  }

 .search-option-advance{
  margin-left: -120px;
  width: 500px;
 }

 .search-option-global{
  margin-left: -200px;
  width: 500px;
 }

  .search-profile-container,
  .advance-profile-container,
  .global-profile-container {
    width: 80%;
    margin-left: 0;
  }

  .advance-search:hover{
    background-color: rgb(219, 10, 10);
    color: white;
    border-radius: 10px;
    width: 140px;
  }

  .search-profile:hover{
    background-color: rgb(219, 10, 10);
    color: white;
    border-radius: 10px;
    width: 120px;
}

.global-search:hover{
  background-color: rgb(219, 10, 10);
  color: white;
  border-radius: 10px;
  width: 120px;
}

.label-Global{
  padding-top: 25px;
}
}
