.profile-dropdown-toggle {
  margin-top: 50px;
  max-width: 120px;
  padding: 5px;
  border-radius: 5px;
  color: whitesmoke;
  font-weight: 700;
  background-color: crimson;
  border: none;
  cursor: pointer;
}
.profile-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  padding: 0;
  margin: 8px 0 0;
}
.profile-dropdown-menu:hover {
  color: #e1137b;
}

.profile-dropdown-menu li {
  padding: 8px 16px;
}
.dropdown-dashboard-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: 5px 16px;
  font-weight: 700;
}
.dropdown-dashboard-list:hover {
  color: #e1137b;
}
@media (max-width: 1151px) {
  .profile-dropdown-toggle {
    display: none;
  }
}
.profile-dropdown-menu li a,
.profile-dropdown-menu li button {
  display: block;
  color: #333;
}
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: #f20487;
}
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none !important;
}
ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: #f20487;
}
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none !important;
}
.navbar-header-dashnav {
  margin-top: 40px;
}
.theme-btnUD {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.theme-btnUD {
  position: relative;
  transition: all 300ms ease;
}
.btnUD {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btnUD:focus,
.btnUD:hover {
  text-decoration: none;
}
.btnUD:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btnUD:disabled {
  opacity: 0.65;
}
.btnUD:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btnUD:not(:disabled):not(.disabled):active {
  background-image: none;
}
.btn-style-oneUD .btn-titleUD {
  position: relative;
  font-size: 17px;
  font-weight: 550;
  color: white;
}
.btn-style-oneUD {
  position: relative;
  padding: 2px 20px;
  line-height: 30px;
  color: #f8f2f2;
  text-align: center;
  background-color: #ec167f;
  overflow: hidden;
  border-radius: 10px;
}
.btn-style-oneUD:hover {
  color: #ec167f;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-style-oneUD:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: #ffffff;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
  -webkit-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.btn-style-oneUD:hover:before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}
.main-header-dashnav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  background: linear-gradient(270deg, #57093f, #0c0707);
  /* box-shadow: 5px 10px 18px #cacaca; */
  height: 90px;
}
.main-header-dashnav .main-box-dashnav {
  position: relative;
  padding: 0px 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header-dashnav .logo-box-dashnav {
  position: relative;
  float: left;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header-dashnav .logo-box-dashnav .logo-dashnav .logo-dashnav img {
  display: inline-block;
  max-width: 100%;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header-dashnav .nav-outer-dashnav {
  position: relative;
  float: right;
  margin-top: -30px;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
}
.main-header-dashnav .nav-outer-dashnav .main-menu-dashnav {
  position: static;
  float: left;
}
.main-menu-dashnav .navigation-dashnav {
  position: static;
  margin: 0px;
}
.main-menu-dashnav .navigation-dashnav > li {
  position: relative;
  float: left;
  margin-left: 24px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu-dashnav .navigation-dashnav > li > a {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  padding: 10px 0;
  color: #fefefe;
  opacity: 1;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu-dashnav .navigation-dashnav > li > a:before {
  position: absolute;
  left: 50%;
  bottom: 10px;
  height: 2px;
  width: 0%;
  content: "";
  -webkit-transform: scale(-1);
  -moz-transform: scale(-1);
  -ms-transform: scale(-1);
  -o-transform: scale(-1);
  transform: scale(-1);
  background: rgb(247, 0, 104);
  background: -moz-linear-gradient(
    to left,
    rgba(247, 0, 104, 1) 0%,
    rgba(68, 16, 102, 1) 100%
  );
  background: -webkit-linear-gradient(
    to left,
    rgba(247, 0, 104, 1) 0%,
    rgba(68, 16, 102, 1) 100%
  );
  background: linear-gradient(
    to left,
    rgba(247, 0, 104) 0%,
    rgba(68, 16, 102, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu-dashnav .navigation-dashnav > li.current-dashnav > a:before,
.main-menu-dashnav .navigation-dashnav > li:hover > a:before {
  left: 0;
  width: 100%;
}
.main-menu-dashnav .navigation-dashnav > li > ul {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 250px;
  z-index: 100;
  display: none;
  opacity: 0;
  visibility: hidden;
  padding: 20px 0px;
  background: #ffffff;
  border-radius: 3px;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  -moz-transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transform: rotateX(90deg);
  border-bottom: 3px solid #f20487;
  border-radius: 0 0 6px 6px;
}
.main-menu-dashnav .navigation-dashnav > li > ul:before {
  position: absolute;
  content: "";
  left: 0px;
  top: -30px;
  width: 100%;
  height: 30px;
  display: block;
}
.main-menu-dashnav .navigation-dashnav > li > ul > li {
  position: relative;
  width: 115%;
  margin-bottom: 0px;
}
.main-menu-dashnav .navigation-dashnav > li > ul > li:last-child {
  margin-bottom: 0px;
}
.main-menu-dashnav .navigation-dashnav > li > ul > li > a {
  position: relative;
  display: block;
  padding: 5px 25px;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #222222;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.main-menu-dashnav .navigation-dashnav > li > ul > li:hover > a {
  color: #e1137b;
}
.main-menu-dashnav .navigation-dashnav > li.dropdown-dashnav:hover > ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.main-menu-dashnav
  .navbar-collapse-dashnav
  > ul
  li.dropdown-dashnav
  .dropdown-btn-dashnav {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #ffffff;
  line-height: 28px;
  border: 1px solid #ffffff;
  background-size: 20px;
  cursor: pointer;
  z-index: 5;
  display: none;
}
.outer-box-dashnav {
  position: relative;
  float: right;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 10px;
  display: flex;
}
.main-header-dashnav .search-box-btn {
  position: relative;
  float: left;
  font-size: 24px;
  line-height: 30px;
  cursor: pointer;
  background: none;
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
  margin-top: 10px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header-dashnav .outer-box-dashnav .btn-boxL-dashnav {
  position: relative;
  float: right;
  padding: 0 0;
  margin-left: 20px;
}
.main-header-dashnav .outer-box-dashnav .btn-boxL-dashnav a {
  position: relative;
  float: right;
  line-height: 20px;
  font-size: 14px;
}
.nav-outer-dashnav .mobile-nav-toggler-dashnav {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  color: #e1137b;
  margin: 7px 0px 2px;
  display: none;
}
.nav-outer-dashnav .mobileapp_Icon1-dashnav {
  display: none;
}
.header-style-two-dashnav .main-box {
  padding: 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.header-style-two-dashnav .outer-box-dashnav,
.header-style-two-dashnav .logo-box-dashnav,
.header-style-two-dashnav .main-menu-dashnav .navigation-dashnav > li {
  padding: 0px 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media only screen and (max-width: 1139px) {
  .main-menu-dashnav .navigation-dashnav > li {
    margin-left: 35px;
  }
}
@media only screen and (min-width: 768px) {
  .main-menu-dashnav .navigation-dashnav > li > ul {
    display: block !important;
  }
}

@media only screen and (max-width: 1100px) {
  .main-header-dashnav,
  .main-header-dashnav .main-box {
    background-color: #1e1f36;
  }
  .main-header-dashnav .main-box {
    padding: 10px 0;
    background-color: #1e1f36;
  }
  .header-style-two-dashnav,
  .header-style-two-dashnav .main-box {
    background-color: #000000;
    padding: 0;
  }
  .header-style-two-dashnav .outer-box-dashnav,
  .header-style-two-dashnav .logo-box,
  .header-style-two-dashnav .main-menu-dashnav .navigation-dashnav > li {
    padding: 10px 0;
  }
  .header-style-two-dashnav .outer-box-dashnav .theme-btnL {
    background-color: #e1137b;
    color: #ece9e9;
  }
  .header-style-two-dashnav .nav-outer-dashnav {
    float: right;
  }
  .main-header-dashnav .outer-box-dashnav {
    display: none;
  }
}
@media only screen and (max-width: 1151px) {
  .main-header-dashnav .nav-outer-dashnav .main-menu-dashnav {
    display: none !important;
  }
  .nav-outer-dashnav .mobileapp_Icon1-dashnav {
    position: absolute;
    left: -44px;
    top: 22px;
    display: block;
  }
  .main-header-dashnav .outer-box-dashnav {
    position: absolute;
    left: 0;
    top: 0;
  }
  .nav-outer-dashnav .mobile-nav-toggler-dashnav {
    display: block;
    margin: 0;
  }

  .header-style-two-dashnav .nav-outer-dashnav {
    padding-top: 30px;
  }
  .main-header-dashnav,
  .header-style-two-dashnav {
    height: 80px;
  }
}
@media (max-width: 1100px) {
  .nav-outer-dashnav .menu-icon-button-dashnav {
    margin-top: -20px;
    display: flex;
    justify-content: end;
  }
}
@media (min-width: 1100px) {
  .nav-outer-dashnav .menu-icon-button-dashnav {
    display: none;
  }
}
