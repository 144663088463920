.custom-dropdown-basic {
  position: relative;
  width: 98%;
  height: 30px;
  /* margin-left: -50px; */
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}
.selected-option-basic {
  padding: 5px;
  margin-left: 20px;
  font-size: 15px;
  color: #1f1f1f;
}
.selected-option-basic::after {
  content: "▼";
  float: right;
  margin-right: 10px;
}
.dropdown-menu-basic {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.custom-select-box-basic {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  box-sizing: border-box;
}
.dropdown-options-basic {
  max-height: 200px;
  overflow-y: auto;
}
.dropdown-option-basic:hover {
  background-color: #f5f5f5;
}
span[style*="color: red"] {
  display: block;
  margin-top: 5px;
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .custom-dropdown-basic {
    width: 55%;
    height: 50px;
  }
}
@media screen and (max-width: 768px) {
  .select-container-cas {
    flex-direction: column;
    margin-left: 0px;
    width: 100%;
  }
}
.labelb {
  display: inline-block;
  margin-bottom: 0.5rem;
  width: 200px;
}
.rowb {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  /* width: 1200px; */
  border: 1px solid white;
  width: 100%;
}
.bgshadowwhite{
  background-color: #f3f3f3;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: .5rem;
  padding: 3rem;
  color: black;
  display: flex;
  
}
.col-lg-3b,
.col-lg-5b,
.col-lg-9b,
.col-md-12b,
.col-md-5b,
.col-sm-6b,
.col-lg-10b,
.col-md-10b,
.col-sm-12b {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
div.col-lg-3b.col-md-5b.col-sm-6b.form-groupb {
  margin-right: 20px;
}
.theme-btnb,
.btnb,
.btn-style-oneb {
  width: 270px;
  margin-left: 450px;
}
.form-groupb {
  margin-bottom: 1rem;
}
.btnb {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
 border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btnb:focus,
.btnb:hover {
  text-decoration: none;
}
.btnb:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btnb:disabled {
  opacity: 0.65;
}
.btnb:not(:disabled):not(.disabled) {
  cursor: pointer;
  /* margin-left: 390px; */
  margin: 1px auto;
}
.buttonsubmitdetails{
  display: flex;
  justify-content: center;
  
}
.btnb:not(:disabled):not(.disabled):active {
  background-image: none;
}
.clearfixb::after {
  display: block;
  clear: both;
  content: "";
}
input.agile-ltextb {
  width: 98%;
  /* margin-left: -50px; */
  border: 0.1px solid rgb(116, 112, 112);
}
select.agile-ltextb {
  width: 98%;
  /* margin-left: -50px; */
}
textarea.agile-ltextba {
  width: 300px;
  /* height: 150px; */
  /* margin-left: -50px; */
}
.mt-2b {
  margin-top: 0.5rem !important;
}
.mt-3b {
  margin-top: 1rem !important;
}
.auto-containerb {
  width: 100%;
  padding: 5px 15px;
  background-color: white;
  /* margin-top: 30px; */
}
/* .auto-containerb h1{
  text-transform: ;
} */
.page-wrapperb {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  margin-top: -25px;
}
.theme-btnb {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.theme-btnb {
  position: relative;
  transition: all 300ms ease;
}
.btn-style-oneb {
  position: relative;
  padding: 10px 30px;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  background-color: #ec167f;
  overflow: hidden;
  border-radius: 10px;
}
.btn-style-oneb .btn-title {
  position: relative;
}
.btn-style-oneb:hover {
  color: #ec167f;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-style-oneb:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: #ffffff;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
  -webkit-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.btn-style-oneb:hover:before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}
.page-titleb {
  position: relative;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  /* margin-top: -50px; */
}
.page-titleb h1 {
  position: relative;
  display: block;
  padding-top: 1.5rem;
  /* font-size: 35px; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #05595B;
  text-align: center;
  line-height: 1em;
  text-transform: uppercase;
  /* font-weight: 700; */
}
.contact-page-sectionb {
  position: relative;
  padding: 110px 0 70px;
}
.contact-page-sectionb .form-columnb {
  position: relative;
  margin-bottom: 40px;
  width: 100%;
}
.contact-formb .form-groupb {
  position: relative;
  margin-bottom: 30px;
}
.contact-formb .form-groupb input:focus {
  border-color: #f20487;
}
.newsletter-sectionb {
  position: relative;
  padding: 10px 0;
  z-index: 1;
  background-color: white;
  /* background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 9%,
    rgba(9, 9, 121, 1) 68%,
    rgba(0, 91, 255, 1) 97%
  ); */
  /* margin-left: 30px;
  margin-right: 30px; */
}
.labelb {
  /* margin-left: -35px; */
  margin-bottom: 2px;
  /* color: white; */
}
/* .page-titleb {
  padding: 20px 0;
} */
@media (min-width: 576px) {
  .col-sm-12b {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md-5b {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-12b {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg-3b {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-5b {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-9b {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media (min-width: 1200px) {
  .d-xl-blockb {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .d-md-blockb {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .d-lg-blockb {
    display: block !important;
  }
}
@media screen and (max-width: 568px) and (min-width: 320px) {
  /* .rowb {
    margin-right: -54px;
    margin-left: -119px;
  } */
  .alertmesb {
    margin-right: -29px;
    margin-left: -185px;
  }
  .custom-dropdown-basic {
    width: 98%;
    height: 50px;
    /* margin-left: -2px; */
  }
  .page-titleb h1 {
    /* font-size: 3rem; */
    /* color: #ffffff; */
    line-height: 1em;
    font-weight: 500;
  }
  .bgshadowwhite{
    padding: .5rem;
  }
  .auto-containerb {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    margin-top: 25px;
}

  /* .contact-page-sectionb {
    padding: 21px 99px 3px 212px;
  } */
  label.labelb {
    /* margin-left: -10px; */
    display: flex;
    flex-direction: column;
  }
  input.agile-ltextb {
    width: 98%;
    /* margin-left: -1px; */
  }
  div.col-lg-3b.col-md-5b.col-sm-6b.form-groupb{
    margin: 10px 5px;
  }
  select.agile-ltextb {
    width: 98%;
    /* margin-left: -1px; */
  }
  textarea.agile-ltextba {
    width: 98%;
    /* margin-left: -7px; */
  }
  .page-title h1 {
    font-size: 40px;
    margin-bottom: 10px;
  }
  button.theme-btnb.btnb.btn-style-oneb {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1100px) and (min-width: 770px) {
  .rowb {
    width: 900px;
    margin-right: -54px;
    margin-left: -119px;
  }
  .alertmesb {
    margin-right: -29px;
    margin-left: -185px;
  }
  .contact-page-sectionb {
    padding: 21px 99px 3px 212px;
  }
  label.labelb {
    margin-left: -10px;
    display: flex;
    flex-direction: column;
  }
  input.agile-ltextb {
    width: 68%;
  }
  select.agile-ltextb {
    width: 68%;
  }
  .page-title h1 {
    font-size: 40px;
    margin-bottom: 10px;
  }
  button.theme-btnb.btnb.btn-style-oneb {
    margin-left: 270px;
  }
}
