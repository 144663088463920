.filter-search {
  display: flex;
  flex-direction: column;
}

/* Existing CSS styles... */

.filter-options-container {
  display: flex;
  flex-direction: column;
}

.filter-option {
  margin-bottom: 20px;
}

.filter-title {
  background-color: #f0f0f0;
  padding: 10px;
  border: none;
  cursor: pointer;

}

.food-habit-list li,
.physical-status-list li {
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.food-habit-list li span {
  color: green;
}

.filter-title::before {
  content: "▶";
  display: inline-block;
  margin-right: 1.5rem;
}


.filter-title.open::before {
  content: "▼";
}

.filter-options-para {
  text-align: center;
  margin-left: 310px;
}

.filter-search-btn {
  text-align: center;
  margin-left: 600px;
  color: black;
  background-color: pink;

}


.filter-options-container {
  margin-top: 20px;
  width: 250px;
  height: auto;

}

.filter-option {
  margin-bottom: 10px;
}

.filter-title {
  background-color: #f0f0f0;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.filter-options {
  list-style-type: none;
  margin: 0;
  padding: 20px;
}

.filter-options li {
  padding: 5px 10px;
  cursor: pointer;
  color: black
}

.filter-options li:hover {
  background-color: #ddd;
  color: rgb(184, 27, 53);
}

.filter-options-search {
  display: flex;
}

.filter-options-search-3:hover {
  background-color: pink;
  color: black;
}


input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: linear-gradient(to right, pink 0%, pink 100%);
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #3e8e41;
}

input[type="range"]:hover {
  opacity: 1;
}

.filter-options input[type="text"] {
  margin-bottom: 5px;
}

.religion-list {
  list-style-type: none;
  padding: 0;
}

.religion-list li {
  cursor: pointer;
  padding: 5px 10px;
}

.religion-list li:hover {
  color: palevioletred;
}


@media (max-width: 600px) {

  .filter-search {
    display: flex;
    flex-direction: column;
    text-align: center;

  }

  .auto-container-sea {

    margin-left: -490px;
    font-size: small;

  }

  /* .filter-options-container {
        margin-top: 0px;
        width: 250px; 
        text-align: center;
        margin-left: 70px;
      } */
  .filter-options li {
    padding: 5px 10px;
    cursor: pointer;
    color: black;
    font-size: 20px;

  }

}




.profile-cards-container {


  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));


  /* margin-top: -800px; */
  margin-left: 200px;
  width: 200px;
  height: 150px;


}

.filter-serch-option {
  height: 100%;
  display: flex;

}

/* .profile-cards-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  width: 250px;
  margin-left: 79%;
  margin-top: -929px;
 
  flex-wrap: wrap;
  
} */
.filter-search {
  display: flex;
  flex-wrap: wrap;
}

.auto-container-sea {
  color: #ddd;
  margin-left: 500px;
}

.profile-card {
  border: 1px solid black;
  border-radius: 10px;
  padding: 1px;

  background-color: #f4f4f4;
  width: 200px;
  text-align: center;
  margin: 10px;
}

.profile-image {
  border-radius: 50%;
  width: 150px;
  height: 100px;
}


@media (max-width: 600px) {
  .profile-cards-container {
    width: 160px;
    margin-left: 40px;
    margin-top: 30px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .filter-serch-option {
    height: 100%;
    display: flex;
  }

  .auto-container-sea {
    margin-left: -30px;
    font-size: small;
  }

  .profile-card {
    width: 100%;
    margin: 10px 0;
  }

  .filter-options-container {
    margin-top: 30px;
    width: 150px;
    margin-left: 10px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .profile-cards-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(270px, 1fr));
    margin-left: 50px;
  }

  .auto-container-sea {
    margin-left: 270px;
    font-size: small;
  }

  .filter-options-container {
    width: 200px;
    margin-top: 6px;
  }
}