.page-title p{
  position: relative;
  display: block;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding-top: 1.5rem;
  font-size: 45px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #05595B;
  text-align: center;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 150px;
}



.who-contacted-me-container {
  width: 50%;
    margin: 85px auto;
    padding: 20px;
    background-color: #f3f3f3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    border-radius: 20px;
}

.who-contacted-me-heading {
  text-align: center;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    color: rgb(225, 7, 138);
    /* background: #f3f3f3; */
    font-family: sans-serif;
    font-size: 42px;
    padding-top: 10px;
}

.who-contacted-me-input-container {
  display: flex;
  margin: auto;
  justify-content: space-around;
  /* align-items: center; */
  /* gap: 2rem; */
  margin-bottom: 50px;
}

.who-contacted-me-input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #232323;
  background-color: rgb(247, 218, 240);
}

.who-contacted-me-input:hover{
  border: 1.5px solid rgb(225, 7, 138);
}

@media screen and (width <=430px) {
  .who-contacted-me-page-title {
    margin-top: -3.2rem !important;
  }

  .who-contacted-me-center-tag-container {
    height: fit-content;
    margin-top: -1rem;
  }

  .who-contacted-me-container {
    width: fit-content;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .who-contacted-me-input-container {
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 50px;
  }
}

@media screen and (width<=768px) {
  .who-contacted-me-page-title {
    margin-top: -3.2rem !important;
  }

  .who-contacted-me-center-tag-container {
    height: fit-content;
    margin-top: -1rem;
  }

  .who-contacted-me-container {
    width: 80%;
  }

  .who-contacted-me-input-container {
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 50px;
  }

  .who-contacted-me-input {
    width: 80%;
  }
}