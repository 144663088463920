.common-container-search-components {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.common-search-select-wraper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.common-search-title-tag {
    color: #05595B;
    text-transform: uppercase;
    font-size: calc(1.375rem + 1.5vw);
}

.select-tag-parent-wraper {
    display: grid;
    grid-template-columns: repeat(3, 250px);
    gap: 1rem 3rem;
    width: fit-content;
    margin: auto;
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    border: 1px solid #f2f2f2;
    box-shadow: 0 0 1rem #f2f2f2;
}

.id-search-select-tag-parent-wraper {
    grid-template-columns: repeat(1, 250px);
}

.select-tag-wraper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.search-tag-lable {
    font-size: small;
    color: #E30B5C;
}

.search-section-select-tag {
    width: 100%;
    padding: 0.25rem 0.5rem;
    font-size: small;
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid lightgrey;

    &:focus {
        border: 1px solid #E30B5C;
        outline: 1px solid #E30B5C;
        color: #E30B5C;
    }
}

.global-search-button-wraper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.search-section-button {
    padding: 0.5rem 2rem;
    border: 1px solid transparent;
    border: 1px solid #E30B5C;
    border-radius: 0.25rem;
    background: transparent;
    color: #E30B5C;
    transition: 0.35s all;

    &:hover {
        background: #E30B5C;
        color: #ffffff;
        cursor: pointer;
    }
}

.horoscope-warning-tag {
    font-size: small;
    color: #DE3163;
}



@media screen and (width <=885px) {
    .select-tag-parent-wraper {
        grid-template-columns: repeat(2, 300px);
        padding: 1rem 1.5rem;
    }

    .id-search-select-tag-parent-wraper {
        grid-template-columns: repeat(1, 250px);
    }
}

@media screen and (width <=430px) {
    .select-tag-parent-wraper {
        grid-template-columns: repeat(1, 300px);
        padding: 1rem 1.5rem;
    }

    .id-search-select-tag-parent-wraper {
        grid-template-columns: repeat(1, 250px);
    }
}