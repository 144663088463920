
.list-group-item:hover{
    color:red;
}
.rowUP{
    /* display: flex; */
}
.col-lg-3UP {
    position: relative;
    width: 25%;
    padding-right: 15px;
    padding-left: 15px;
}


.userProfile-ad-card-btn{
    width: 90%;
    background: #ec167f;
    color: white;
    font-weight: 700;
    border-radius: 40px;
   
}