.main-container-one {
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    width: 95%;
    margin-top: 2rem;
    margin-left: 30px;
    margin-bottom: 1rem;
    background-image: linear-gradient(to right, rgb(255, 0, 149), rgb(250, 250, 246));
    display: flex;
    justify-content: center;
}

.Image-profile-one {
    display: flex;
}

.Image-profile-one img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    object-fit: fill;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: -20%;
}

.Name-employe-one {
    color: rgb(3, 3, 71);
    margin-left: -10px;
    margin-top: 60px;
    font-size: larger;
}

.tabs-boxpro {
    position: relative;
}

.schedule-tabspro {
    position: relative;
    margin-top: 30px;
}

.schedule-tabspro .btns-box {
    position: relative;
    display: block;
    margin-bottom: 40px;
}

.schedule-tabspro .tab-buttonspro li {
    position: relative;
    display: inline-block;
    height: 135px;
    width: 160px;
    border-radius: 20px;
    background-color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
    font-weight: 600;
    text-align: left;
    padding: 15px 15px;
    padding-top: 60px;
    padding-left: 90px;
    cursor: pointer;
    margin: 0 15px 20px;
    border: 1px solid #dddddd;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.schedule-tabspro .tab-buttonspro li .day {
    position: absolute;
    left: 0px;
    top: 20px;
    padding: 5px 20px;
    background-color: #222222;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 0 15px 15px 0;
}

.schedule-tabspro .tab-buttonspro li .date {
    position: absolute;
    left: -0;
    top: 80px;
    width: 150px;
    text-align: center;
    font-size: 38px;
    line-height: 20px;
    font-weight: 600;
    color: #222222;
}

.schedule-tabspro .tab-buttonspro li.active-btnpro {
    box-shadow: 0 20px 30px rgba(0, 0, 0, .10);
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 1) .day {
    background-color: #ffc20b;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 2) .day {
    background-color: #40cbb4;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 3) .day {
    background-color: #4c35a9;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 4) .day {
    background-color: #e6275a;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 5) .day {
    background-color: #05a7dc;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 1) .datepro {
    color: #ffc20b;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 2) .datepro {
    color: #40cbb4;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 3) .datepro {
    color: #4c35a9;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 4) .datepro {
    color: #e6275a;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 5) .datepro {
    color: #e6275a;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 1).active-btnpro {
    box-shadow: 0 0px 30px rgba(255, 194, 11, .30);
    border: 1px solid #ffc20b;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 2).active-btnpro {
    box-shadow: 0 0px 30px rgba(64, 203, 180, .30);
    border: 1px solid #40cbb4;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 3).active-btnpro {
    box-shadow: 0 0px 30px rgba(76, 53, 169, .30);
    border: 1px solid #4c35a9;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 4).active-btnpro {
    box-shadow: 0 0px 30px rgba(230, 39, 90, .30);
    border: 1px solid #e6275a;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 5).active-btnpro {
    box-shadow: 0 0px 30px rgba(43, 199, 234, .30);
    border: 1px solid #05a7dc;
}

.about-section-two-indexpro {
    position: relative;
    padding: 1rem 10rem !important;
}

.about-section-two-indexpro .icon-circle-1 {
    top: -200px;
    opacity: .05;
}

.schedule-tabspro .tab-buttonspro {
    position: relative;
    text-align: left;
}

/*! CSS Used from: https://www.matrimonysoftware.in/css/bootstrap.css */

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto;
}

.back-button-paypro1 {
    background-color: #ec167f;
    width: 2%;
    margin-left: 1%;
    border-radius: 3%;
}

@media(max-width:768px) {
    .img-thumbnail {
        margin-left: 0px;
        margin-top: 70px;
    }

    .rowp {
        flex-direction: column;
        display: -ms-flexbox;
        display: flex;
        width: 300%;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
}

.rowp {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
}

.col-lg-12p,
.col-lg-4p,
.col-md-6p,
.col-sm-12p {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width:992px) {
    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.textpro {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: black;
    font-weight: 500;
}

.textpro span {
    color: #ec167f;
}

.auto-containerp {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

@media(max-width:768px) {
    .theme-btnpro {
        margin-left: 70px;
        position: relative;
        transition: all 300ms ease;
    }

    .feature-block-twop .inner-boxp {
        position: relative;
        width: 100%;
        padding: 40px 30px;
        margin-left: 40px;
        padding-left: 100px;
        background-color: #ffffff;
        border: 1px solid #eeeeee;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
        overflow: hidden;
    }
}

.feature-block-twop {
    display: grid;
    width: 700px;
    color: #007bff;
    height: 95%;
    margin-bottom: 5%;
    margin-left: 15%;
    margin-top: 2%;
}

.inner-boxp.inner-boxp.text2:hover:hover {
    color: #5b626a;
}

.feature-block-twop .inner-boxp {
    position: relative;
    width: 100%;
    padding: 40px 30px;
    padding-left: 100px;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.10);
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    overflow: hidden;
}

.feature-block-twop .inner-boxp:hover {
    box-shadow: none;
}

.feature-block-twop .icon-boxp {
    position: absolute;
    left: 30px;
    top: 50px;
    display: block;
    margin-bottom: 30px;
}

.feature-block-twop h4 {
    position: relative;
    display: block;
    font-size: 22px;
    line-height: 1.2em;
    color: #12114a;
    font-weight: 700;
    margin-bottom: 10px;
}

.feature-block-twop h4 a {
    color: #12114a;
    display: inline-block;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.feature-block-twop h4 a:hover {
    color: #f14b59;
}

.feature-block-twop .textpro {
    position: relative;
    display: block;
    font-size: 15px;
    line-height: 24px;
    color: black;
    font-weight: 400;
    cursor: default;
    font-size: large;
    margin-bottom: 2%;
}

.editpro {
    color: #0056b3;
    cursor: pointer;
    padding: 0;
    font-size: 20px;
}

.editpro:hover {
    color: #f14b59;
}

.modal-content {
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg,
            rgba(2, 0, 36, 1) 9%,
            rgba(9, 9, 121, 1) 68%,
            rgba(0, 91, 255, 1) 97%);
    backdrop-filter: blur(9px);
}

.close {
    width: 20%;
}

.modal-title {
    color: #e90975;
}

.form-group {
    color: black;
    font-weight: 500;
    margin-bottom: 2%;
}

@media screen and (width <= 885px) {
    .about-section-two-indexpro {
        padding: 1rem 2% !important;
    }
    .active-btnpro.active-btnpro {
        width: 95% !important;
    }
}


@media screen and (max-width: 430px) {
    .main-container-one {
        width: 85%;
        margin-left: 30px;
    }

    .Image-profile-one img {
        width: 80%;
        margin-left: 2%;
    }

    .Name-employe-one {
        font-size: small;
        font-weight: 900;
    }

    .feature-block-twop {
        margin-left: -3%;
        width: 98%;
    }

    .feature-block-twop .textpro {
        font-size: 15px;
    }

    .modal-content {
        margin-top: 40%;
        background: rgb(2, 0, 36);
        background: linear-gradient(90deg,
                rgba(2, 0, 36, 1) 9%,
                rgba(9, 9, 121, 1) 68%,
                rgba(0, 91, 255, 1) 97%);
        backdrop-filter: blur(9px);
    }

    .close {
        width: 10%;
    }

    .modal-body {
        background: rgb(2, 0, 36);
        background: linear-gradient(90deg,
                rgba(2, 0, 36, 1) 9%,
                rgba(9, 9, 121, 1) 68%,
                rgba(0, 91, 255, 1) 97%);
        backdrop-filter: blur(9px);
    }

    .radio-group {
        width: 20px;
    }

    .about-section-two-indexpro {
        padding: 1rem 8% !important;
    }

    .active-btnpro.active-btnpro {
        width: 300px !important;
    }
}