
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

.review-container {
  background: linear-gradient(to bottom right, blue, black);
  color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  /* margin: 50px auto; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.review-h2 {
  text-align: center;
  color: white;
}

.review-p {
  text-align: center;
  color: white;
}

.name-email-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.name-input-container, .email-input-container {
  margin-bottom: 10px;
}

.review-label {
  display: block;
  margin-bottom: 5px;
  color: white;
}

.name-input, .email-input, .review-textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
}

.name-input, .email-input {
  background: white;
}

.review-textarea {
  height: 100px;
  background: white;
}

.review-button {
  display: block;
  width: 100%;
  padding: 10px;
  background: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.review-button:hover {
  background: #0056b3;
}

.filled-star, .empty-star {
  font-size: 24px;
  cursor: pointer;
}

.filled-star {
  color: gold;
}

.empty-star {
  color: gray;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.popup p {
  margin: 0 0 20px;
}

.popup button {
  padding: 10px 20px;
  background: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup button:hover {
  background: #0056b3;
}

.back-button-pay {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}

.back-button-pay i {
  font-size: 24px;
}

.email-input {
  font-size: 17px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media Queries */
@media (min-width: 600px) {
  .name-email-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .name-input-container, .email-input-container {
    flex: 1;
    margin-right: 10px;
  }

  .name-input-container:last-child, .email-input-container:last-child {
    margin-right: 0;
  }

  .name-input, .email-input {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .review-container {
    margin: 20px;
    padding: 15px;
  }

  .review-button {
    padding: 8px;
    font-size: 14px;
  }

  .popup {
    width: 90%;
    padding: 15px;
  }

  .popup button {
    padding: 8px 16px;
  }

  .back-button-pay {
    font-size: 20px;
  }

  .back-button-pay i {
    font-size: 20px;
  }
  .email-input{
    width: 100%;
  }
}
