.page-title-latest {
  position: relative;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-color: black;
  color: white;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  padding: 20px 0;
  margin-top: -50px;
}
.page-title-latest h1 {
  font-size: 60px;
  color: #fff;
  font-weight: 700;
  line-height: 1em;
}
.auto-container-latest {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
.bread-crumbb {
  list-style: none;
  padding: 0;
}
.bread-crumbb li {
  display: inline;
  margin-right: 10px;
}
.latest-matches-container {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.filters {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}
.filter-section {
  display: flex;
  flex-direction: column;
}
.filter-section label {
  font-weight: bold;
  color: blue;
  margin-top: 20px;
}
.profile-cards-container-latest {
  display: grid;
  grid-template-columns: repeat(3, minmax(290px, 1fr));
  margin-left: 10%;
  gap: 20px;
}
.profile-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}
/* Media Queries */
@media screen and (max-width: 1024px) {
  .profile-cards-container-latest {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    margin-left: 30px;
    gap: 56px;
  }
}
@media screen and (max-width: 800px) {
  .profile-cards-container-latest {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
    margin-left: 80px;
    width: 50%;
  }
  .filters {
    width: 25%;
  }
}
