.emaliId{
    width:250px;
  }
  
  .sendOtp{
    background-color: deeppink;
    color: white;
    font-weight: 500;
    width:200px;
  }
  
  .otp-Form {
      width: 230px;
      height: 300px;
      background-color: rgb(255, 255, 255);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 30px;
      gap: 20px;
      position: relative;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.082);
      border-radius: 15px;
    }
    
    .mainHeading {
      font-size: 1.1em;
      color: rgb(15, 15, 15);
      font-weight: 700;
    }
    
    .otpSubheading {
      font-size: 0.7em;
      color: black;
      line-height: 17px;
      text-align: center;
    }
    
    .inputContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }
    
    .otp-input {
      background-color: rgb(228, 228, 228);
      width: 30px;
      height: 30px;
      text-align: center;
      border: none;
      border-radius: 7px;
      caret-color: rgb(127, 129, 255);
      color: rgb(44, 44, 44);
      outline: none;
      font-weight: 600;
    }
    
    .otp-input:focus,
    .otp-input:valid {
      background-color: rgba(127, 129, 255, 0.199);
      transition-duration: .3s;
    }
    
    .verifyButton {
      width: 100%;
      height: 30px;
      border: none;
      background-color: rgb(127, 129, 255);
      color: white;
      font-weight: 600;
      cursor: pointer;
      border-radius: 10px;
      transition-duration: .2s;
    }
    
    .verifyButton:hover {
      background-color: rgb(144, 145, 255);
      transition-duration: .2s;
    }
    
    .exitBtn {
      position: absolute;
      top: 5px;
      right: 5px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.171);
      background-color: rgb(255, 255, 255);
      border-radius: 50%;
      width: 25px;
      height: 25px;
      border: none;
      color: black;
      font-size: 1.1em;
      cursor: pointer;
    }
    
    .resendNote {
      font-size: 0.7em;
      color: black;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
    
    .resendBtn {
      background-color: transparent;
      border: none;
      color: rgb(127, 129, 255);
      cursor: pointer;
      font-size: 1.1em;
      font-weight: 700;
    }
   .VerifyMob-text1{
    margin-left: 20%;
   }
    @media (max-width:480px){
      .VerifyMob-text{
        text-align: center;
      }
      .VerifyMob-para{
        text-align: center;
      }
     
    }
    @media screen and(max-width:689px) {
       .VerifyMob-text{
        text-align: center;
      }
      .VerifyMob-para{
        text-align: center;
      }
     
      
    }