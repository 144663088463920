
.coming-soon-sectionR  {
    position: relative;
    display:inline-block;
    /* margin-left:65px; */
    width:100%;
    padding: 30px 80px;
    text-align: center;
    /* margin-right: 85px; */
    /* margin:auto; */
    /* background-color: #ffffff; */
    /* margin-top: -50px; */
    background: rgb(247, 0, 104);
    background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
    background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
    background: linear-gradient(to left, rgba(247, 0, 104) 0%, rgba(68, 16, 102, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.30);
    border-radius: 10px;
}
.mainRegisterFormContainer{
  display: flex;
}
@media (max-width : 768px) {
    .coming-soon-sectionR  {
        padding: 0;
    }
}
.outer-boxR {
  display: flex;
}
.main-registerR {
  width: 100%;
  display: flex;
  flex-direction: ROW;
}
.col-lg-3 .col-md-3R,
.col-sm-3R,
.form-groupR{
    width:220px;
    display:flex;
    flex-direction:column;  
    /* margin-right:90px; */
    /* margin-left:90px; */
    margin: 10px;
    margin-bottom:20px;
}
.form-groupR {
  width: 50%;
  justify-content: center;
}
.labelR {
  color: white;
  margin: 0;
  width: 100%;
  text-align: center;
}
.mainRegisterFormContainer{
  display: flex;
}


/* .col-lg-3R, 
.col-md-3R,
.col-sm-3R,
.form-groupR{
    width:300px;
    display:flex;
    flex-direction:column;
    margin-right:90px;
    margin-left:90px;
    margin-bottom:20px;
}
button.theme-btnR.btnR.btn-style-twoR.btn-style-letsbeginR{
    margin-left: 400px;
    margin-top: 30px;
    width:120px;
    font-weight:550;
}  */


@media (max-width:480px){
    .coming-soon-sectionR .outer-boxR{
        width: 90%;
        margin-left: 20px;
    }
    .main-registerR{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .col-lg-3R, 
    .col-md-3R,
    .col-sm-3R,
    .form-groupR{
        width: 94%;
    display:flex;
    flex-direction:column;
    /* margin-right:90px; */
    /* margin-left:-70px;
    margin-bottom:20px; */
}
button.theme-btnR.btnR.btn-style-twoR.btn-style-letsbeginR{
    margin-left: -20px;
    margin-top: 30px;
    width: 220px;
    font-weight: 550;
    margin-bottom: 10px;
  }
  .mainRegisterFormContainer{
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .coming-soon-sectionR .outer-boxR {
    width: 90%;
    margin-left: 20px;
  }
  .main-registerR {
    display: flex;
    flex-direction: column;
  }
  .col-lg-3R,
  .col-md-3R,
  .col-sm-3R,
  .form-groupR {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  button.theme-btnR.btnR.btn-style-twoR.btn-style-letsbeginR {
    margin-left: 10px;
    margin-top: 30px;
    width:220px;
    font-weight:550;
    margin-bottom: 1rem;
}
}


/* @media (max-width:992px) {
    .coming-soon-sectionR .outer-boxR{
        width: 95%;
        margin-left: 20px;
    }
    .main-registerR{
        display: flex;
        flex-direction: column;
    
    }
    .col-lg-3R, 
    .col-md-3R,
    .col-sm-3R,
    .form-groupR{
        width:70%;
    display:flex;
    flex-direction:column;
    margin-right:90px;
    margin-left:-50px;
    margin-bottom:20px;
}
} */