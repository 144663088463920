General Styles
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.newsletter-sections {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.auto-containers {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.inner-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact-forms {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.texts h1 {
    font-size: 2.5rem;
    color: #ec167f;
    margin-bottom: 20px;
}

.texts h2 {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 20px;
}

@media(max-width:768px) {
    u {
        display: block;

    }

    .theme-btns.btns.btn-style-ones.mt-4s.mb-4s.style {
        width: 150px;
    }

}

.contact-forms .form-groups .agile-ltextSU,
.contact-forms .form-groups .agile-ltextSU,
.contact-forms .form-groups .agile-ltextSU,
.contact-forms .form-groups select {
    position: relative;
    display: block;
}

.agile-ltextSU {
    width: 100%;
    /* margin-bottom: 1px; */
    font-size: 14px;
    color: rgb(238, 231, 231) !important;
    line-height: 32px;
    padding: 0px 13px;
    /* background-color: ffffff; */
    font-weight: 500;
    height: 50px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    border-radius: 10px !important;
    background-color: rgb(82, 34, 121);
    backdrop-filter: blur(20px);
}

@media(max-width:768px) {

    .contact-forms .form-groups .agile-ltextSU,
    .contact-forms .form-groups .agile-ltextSU,
    .contact-forms .form-groups .agile-ltextSU,
    .contact-forms .form-groups select {
        position: relative;
        display: block;
        width: 100%;
        font-size: 14px;
        color: #312d2dba;
        line-height: 32px;
        padding: 0px 13px;
        /* background-color: ffffff; */
        font-weight: 400;
        height: 50px;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;
        border-radius: 3px;
    }
}

.contact-forms.form-groups .agile-ltextSU:focus,
.contact-forms .form-groups select:focus {
    border-color: #f20487;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
}

.agile-ltextSU::placeholder {
    color: #888;
}

.form-groups {
    position: relative;
    margin-bottom: 20px;
}

input[type="checkbox"] {
    margin-right: 10px;
}

.theme-btns {
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.theme-btns {
    position: relative;
    transition: all 300ms ease;
}

.agile-ltextSU,
select {
    padding: 5px 20px;
    outline: none;
    border: 1px solid #acacac;
    margin-bottom: 5px;
}



@media (min-width:768px) {
    .d-md-blocks {
        display: block !important;
    }
}

@media (min-width:992px) {
    .d-lg-blocks {
        display: block !important;
    }
}

@media (min-width:1200px) {
    .d-xl-blocks {
        display: block !important;
    }
}



.page-titles {
    position: relative;
    background-color: #ec167f;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.theme-btns:hover {
    background-color: #d0146e;
}

input[type="checkbox"] {
    margin-right: 10px;
}

.page-titles .bread-crumbs li:last-child:before {
    display: none;
}

.page-titles .bread-crumbs li a {
    color: #ffffff;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.page-titles .bread-crumbs li a:hover {
    color: #f0c52e;
}

.page-titles {
    padding: 20px 0;
}

.page-titles {
    margin-top: 30px;
}

.password-input-containers {
    position: relative;
}

.agile-ltextSU {
    padding-right: 2.5rem;
}

.toggle-password-buttons {
    /* height: 190px; */
    position: absolute;
    top: 20%;
    left: 330px;
    background: none;
    border: none;
    cursor: pointer;

    padding-right: 15px;
}


/*! CSS Used from: https://www.matrimonysoftware.in/css/responsive.css */

@media only screen and (max-width: 767px) {
    .page-title {
        padding: 78px 0px 7px 0px !important;
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .contact-forms {
        padding: 15px;
    }

    .texts h1 {
        font-size: 2rem;
    }

    .texts h2 {
        font-size: 1.25rem;
    }

    .agile-ltextSU {
        font-size: 0.875rem;
    }

    .theme-btns {
        font-size: 0.875rem;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .newsletter-sections {
        padding: 10px;
    }

    .toggle-password-buttons {
        position: absolute;
        top: 15%;
        left: 310px;
        background: none;
        border: none;
        cursor: pointer;

        padding-right: 15px;
    }

    .contact-forms {
        padding: 10px;
    }

    .texts h1 {
        font-size: 1.5rem;
    }

    .texts h2 {
        font-size: 1rem;
    }

    .agile-ltextSU {
        font-size: 0.75rem;
    }

    .theme-btns {
        font-size: 0.75rem;
        padding: 6px 12px;
    }
}