.customDiv {
    width: 70%;
    margin: 40px auto 0;
   height: 330px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    align-items: center;
}

.customDiv h1 {
    margin-left: 5%;
    color: white;
    font-size: 400%;
}

.singleview-main-container{
    width: 98%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: auto;
    gap:10px;
}
.singleview-card-one{
    width: 24%;
    display: flex;
    flex-direction: column;
    gap: 1px;
    background: whitesmoke;
    padding: 3%;
    box-shadow: 0 30px 40px rgb(243, 197, 197); 
    border-radius: 5px;
}
.singleview-card-one:hover{
    cursor: pointer;
    transform: scale(1.03);
    transition: all ease 1.52s;
}
.singleview-card-head{
    display: flex;
    font-size: 13px;
}   
.singleview-basic-details{
    display: flex;
    justify-content: space-between;
    font-size: small;
}
.singleview-content{
    
    color:red;
    font-style: normal;
}
.singleview-values{
    color:rgb(81, 77, 77);
}
@media (max-width:786px) {
    .customDiv {
        width: 80%;
        /* height: 300px; */
    }

    .customDiv h1 {
        margin-left: 10%;
        font-size: 300%;
    }

    .singleview-main-container{
        display: flex;
        flex-wrap: wrap;
    }
    .singleview-card-one{
        width: 32%;
        flex-wrap: wrap;
        gap: 1px;
    }
    .singleview-card-head{
       font-size: 12px;
    }
    .singleview-basic-details{
    font-size: 10px;
    }
   
}
@media (max-width:512px) {
    .singleview-main-container{
        width: 98%;
        margin: auto;
        gap: 10px;
    }
    .singleview-card-one{
        width: 90%;
        margin-left: 17px;
        flex-wrap: wrap;
        gap:1px;
    }
    .singleview-card-head{
        font-size: 15px;
    }
    .singleview-basic-details{
        font-size: 12.5px;
        }
}
