/*! CSS Used from: https://www.sangam.com/static/css/82.38fd7365.chunk.css */
.container-slide{
    margin-top: 6.4% !important;
}
.container-slide,
img {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: initial;
    
}

/*! CSS Used from: https://www.sangam.com/static/css/main.b9c0949c.chunk.css */
.home-banner {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #e3b05f no-repeat top;
    height: 500px;
    background-size: cover;
    position: relative;
}

.home-banner-img {
    width: 100%;
    height: 500px;
    position: absolute;
    object-fit: cover;
    object-position: top;
}

.home-banner-copy {
    font-size: 22px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    padding-bottom: 80px;
    align-self: flex-end;
    z-index: 1;
    margin: 20px;
}

.op{
    font-size: 22px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    padding-bottom: 80px;
    align-self: flex-end;
    z-index: 1;
}

.text-homeimg{
    color:#e83e8c;
}

.subtext-homeimg{
    font-size:30px;
    color:white;
}

@media only screen and (min-width:970px) {
    .home-banner {
        margin: 0;
    }

    .home-banner-copy {
        font-size: 45px;
        text-shadow: 1px 1px 1px #1f2a1f;
        padding: 400px 0 0px;
    }
   
}


@media only screen and (max-width:480px) {
    .home-banner {
        margin: 0;
    }

    .home-banner-copy {
        font-size: 20px;
        text-shadow: 1px 1px 1px #1f2a1f;
        padding: 100px 0 0px;
    }
    .subtext-homeimg{
       display: none;
    }
    .svg .texting{
        display: none;
    }
    
   
}

@media (min-width:768px) and (max-width:1094px){
    .svg .texting{
        display: none;
    }
}

/* ---------------------------------------- */

@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

.svg {
	font-family: "Russo One", sans-serif;
	width: 100%; height: 100%;
}
.svg .texting {
	animation: stroke 5s infinite alternate;
	stroke-width: 2;
	stroke: #e7184c;
	font-size: 100px;
}
@keyframes stroke {
	0%   {
		fill: rgba(72,138,204,0); stroke: rgb(151, 26, 99);
		stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
	}
	70%  {fill: rgba(72,138,204,0); stroke: rgb(206, 49, 146); }
	80%  {fill: rgba(72,138,204,0); stroke: rgb(218, 33, 104); stroke-width: 3; }
	100% {
		fill: rgb(241, 130, 186); stroke: rgba(54,95,160,0);
		stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
	}
}

.wrapper1 {background-color:transparent};
