.diff-profile-head {
    width: 100%;
    height: 90px;
    /* margin-top: -2.5rem; */
    /* background-color: black; */
    /* border-radius: 20px; */

    
}

.diff-profile-head h1{
    position: relative;
    display: block;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;
    /* font-size: 35px; */
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #05595B;
    text-align: center;
    line-height: 1em;
    text-transform: uppercase;
    /* font-weight: 700; */
}

.form-control {
    margin-top: 5px;
}

.diff-profile-page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin-top: 1.5%;
    /* box-shadow: 5px 2px 5px 1px; */
}

.diff-profile-card {
    width: 100%;
    height: 70px;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: black; */
    color: white;
    /* font-size: xx-large;
    font-size: 250%; */
}

.diff-profile-icons {
    width: 90%;
    height: 500px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    margin: 50px;
    background-color: #f3f3f3;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
}

.diff-profiles {
    width: 25%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: 20px;
    background-color: #a3a3a357;
    border-radius: 20px;
    transition: ease-out 0.2s;
}

.diff-profiles:hover{
    /* transform: scale(1, 1); */
    translate: 2px;
    border: 2px solid #959494;
}

.Diff-profile-mapicon,
.Diff-profile-educationicon,
.Diff-profile-professionicon {
    width: 25%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #121FCF;
    background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Diff-profile-mapicon:hover {
    color: skyblue;
}

.Diff-profile-educationicon:hover {
    color: skyblue;
}

.Diff-profile-professionicon:hover {
    color: skyblue;
}

.diff-profile-icon-name {
    display: flex;
    font-size: 10px;
    margin-top: 10px;
    color: black;
    font-weight: bold;
    /* filter: drop-shadow(0 0 10px #3d3d3d); */

}

.diff-profile-icon-name:hover {
    color: gray;
}

.diff-profile-educationmodal {
    width: 106%;
    margin-top: 14%;
}

.diff-profile-modal-body {
    width: 100% !important;
    /* height: 450px; */
}

.diff-profile-educationmodal-header {
    width: 100%;
    /* height: 150px; */
    color: cadetblue !important;
}

.diff-profile-education-search {
    width: 95% !important;
    /* height: 100px !important; */
    display: flex;
    border: none !important;
    /* outline: none !important; */
    /* padding: 30px 5px 5px 10px !important; */
}

.diff-profile-educationicon-popup {
    max-width: 700px !important;
    /* height: 350px; */
}

.diff-profile-education-searchbtn {
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: orange;
    color: black;
    border-radius: 5px;
    margin-left: 150px;
    margin-top: 10px;
    font-weight: 400;
    font-size: larger;
    border: none !important;
    outline: none !important;
}

.diff-profile-education-searchbtn:hover {
    background-color: skyblue;
}

.diff-profile-closebtn {
    width: 100px;
    height: 50px;
    background-color: red;
    color: whitesmoke;
    font-size: large;
    font-weight: bold;
}

.modal-content {
    margin-right: 30px !important;
}

.diff-profile-educationmodal-header, .diff-profile-educationicon-popup, .diff-profile-modal-body, .diff-profile-modal-footer{
    background: #f3f3f3;
}

.diff-profile-educationmodal-header, .diff-profile-modal-footer{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


@media (max-width:1100px) {
    .modal-content {
        height: 430px !important;

    }
}

@media (max-width: 835px) {
    .diff-profile-head {
        font-size: 10px;
    }

    .diff-profile-icons{
        display: flex;
        /* justify-content: space-between; */
        /* grid-template-columns: 1fr; */
        height: 55vh;
        margin: 110px auto;
    }

    .diff-profiles{
        display: flex;
        margin: 20px;
        margin-top: 10%;
        margin-bottom: 10%;
        width: 50%;
        padding: 10px;
    }

    .diff-profile-head {
        margin-top: -54px;
        padding: 10px 0px;
    }
    .diff-profile-icon-name{
        text-align: center;
    }

    .modal-content {
        width: 80%;
        margin-left: 30px;
    }

    .modal-header {
        padding: 10px 5px;
    }

    .modal-title {
        font-size: 15px;
    }

    .form-label {
        font-size: small !important;
    }

    .diff-profile-educationicon-popup {
        height: 320px;
    }

    .diff-profile-education-searchbtn {
        width: 80px;
        height: 40px;
    }
}

@media (max-width:486px) {
    
    .diff-profile-head {
        font-size: 10px;
    }

    .diff-profile-icons{
        display: grid;
        grid-template-columns: 1fr;
        height: auto;
    }

    .diff-profiles{
        display: flex;
        margin: auto;
        margin-top: 10%;
        margin-bottom: 10%;
        width: 70%;
    }
}