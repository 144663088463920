.welcome-modal {
    position: relative;
    left: 0;
    width: 70%;
    height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 175px;
    margin-top: -470px;
    /* border: 1px solid black; */
    margin-bottom: 190px;

  }
  .mainWelcomecontainer{
    background-color: rgb(0,0,0,0.5);
  }
  .welcome-content {
    background: linear-gradient(270deg, #e734de, #f0e6ee);
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .welcome-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
    margin: 5px;
  }
  
  .welcome-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .welcome-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .welcome-content button:hover {
    background-color: #0056b3;
  }
  
  .welcome-img{
    width: 70%;
    height: 290px;
    margin-top: -10px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  }

  .welcome-text{
    color:rgb(7, 53, 64);
    font-weight: 700;
  }

  .welcome-para{
    /* font-weight: 600; */
    text-transform: capitalize;
  }
  
  @media (max-width: 480px) {
    .welcome-modal{
      margin-left: 55px;
      margin-top: -400px;
    }
    .welcome-img{
      display: none;
    }
  }
    
    