/*  */
.main-card1{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap:10px;
} 

.main-card2{
    display: flex;
    justify-content: center;
    align-items: center;
} 
/*  */
/* .card {
    position: relative;
    width: 300px;
    height: 200px;
    background: linear-gradient(-45deg, #dbdbda 0%, #a84572 100% );
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  } */
  
  .card img {
    width: 400px;
    border-radius: 0%;
    padding: 0px;
    fill: #333;
    transition: all 0.2s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .card:hover {
    transform: rotate(-5deg) scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .card__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .card:hover .card__content {
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 1;
  }
  
  .card__title {
    margin: 0;
    font-size: 24px;
    color: #333;
    font-weight: 700;
  }
  
  .card__description {
    margin: 10px 0 0;
    font-size: 14px;
    color: #777;
    line-height: 1.4;
  }
  
  .card:hover img {
    scale: 0;
    transform: rotate(-45deg);
  }
  



   
 
 
 