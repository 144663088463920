.happy-story-head{
    background: url('https://img.freepik.com/free-photo/background-gradient-lights_23-2149304962.jpg?size=626&ext=jpg&ga=GA1.1.1705547883.1714394798&semt=ais_user');
    background-clip: text;
    color: transparent;
    font-weight: 600;
}

.header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
 
  .subtitle {
    font-size: 20px;
    font-weight: 500;
  }
  
  .slide {
    text-align: center;
  }
  
  .content {
    width: 60%;
    margin: 0 auto;
    text-align: center;
  }
  
  .content img {
    max-width: 100%;
    max-height: 150px;
    margin-left: 28%;
  }
  
  .info {
    margin-top: 10px;
  }
  
  .info h6 {
    font-size: 23px;
    margin-left: -50px;
  }
  
  .info a {
    font-size: 130%;
    margin-left: -45px;
  }
  
  .info img {
    width: 100px;
    height: 25px;
    margin-left: 40%;
    
  }
  .slick-dots{
    margin-bottom: 20px;
  }
  .happy-main{
    margin-bottom: 50px;
    margin-top: 50px;
  }

  @media only screen and (max-width: 768px) and (min-width:360px){
    .content img {
        max-width: 100%;
        width: 800px;
       /* max-height: 150px; */
        margin-left: -20%;

      }
      .info h6 {
        font-size: 23px;
       
      }
      .info img {
        width: 100px;
        height: 25px;
        margin-left: 0%;
      }
      
  }
