.page-title p{
    position: relative;
  display: block;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding-top: 1.5rem;
  font-size: 45px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #05595B;
  text-align: center;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 150px;
}

.msg-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 600px;
    margin: 85px auto;      
    padding: 40px;
    background-color: #f3f3f3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    border-radius: 20px;
}

.theme-btn{
    text-align: center;
}