.page-heading p{
  position: relative;
  display: block;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding-top: 1.5rem;
  font-size: 45px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #05595B;
  text-align: center;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 150px;
}

.profile-have-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f3f3f3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 95px auto;
}

.profile-have-heading {
  text-align: center;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: rgb(225, 7, 138);
  background: whitesmoke;
  font-family: sans-serif;
  font-size: 42px;
  padding-top: 10px;
}

.profile-have-input-container-view {
  display: flex;
  flex-direction: column;
  margin: 50px;
  margin-bottom: 50px;
  /* color: deeppink; */
}

.profile-have-input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #232323;
  background-color: rgb(247, 218, 240);
}

.profile-have-input:hover{
  border: 1.5px solid rgb(225, 7, 138);
}

@media screen and (min-width: 768px) {
  .profile-have-container {
    max-width: 800px;
  }

  .profile-have-input-container-view {
    flex-direction: row;
  }

  .profile-have-input {
    margin-right: 10px;
    margin-bottom: 0;
  }
}


@media screen and (max-width: 600px) {
  .profile-have-input-container-view {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    width: 320px;
    align-items: center;
  }


  input.profile-have-input {
    flex: 1;
    margin-bottom: 10px;
    font-size: 12px;
    padding-left: 10px;
    /* margin-right: -20px;      */
  }

  .profile-have-input:last-child {
    margin-right: 0;
  }

  .profile-have-heading {
    font-size: 20px;
  }
}
