.save_search_container {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 130px;
}

.save_search_title {
  color: #05595B;
  margin-top: 2%;
  text-align: center;
  width: 700px;
  font-size: 50px;
}

.save_search_text {
  color: black;
  font-style: initial;
  font-size: 30px;
}

.cards-save {
  display: flex;
  align-items: center;
  margin-top: 3%;  
  gap: 2rem;
  font-size: 35px;
}

.card-save {
  width: 700px;
  height: 510px;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 9px 20px rgba(0, 0, 0.2, 0.4);
  margin: 1rem;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }
}

.card_search {
  font-weight: bold;
  text-align: center;
  color: black;
  padding-top: 130px;
  font-size: 50px;
}

.search_content {
  text-align: center;
  font-weight: 500;
  font-size: medium;
}

.search_button {
  width: fit-content;
  padding: 0.35rem 1rem;
  background-color: #ec167f;
  border-radius: .35rem;
  color: #f0f0f0;
  font-weight: bold;
  border: 1px solid transparent;

  &:hover {
    color: #ec167f;
    border: 1px solid #ec167f;
    background: #f0f0f0;
  }
}

@media only screen and (max-width: 885px) {
  .cards-save {
    width: 100%;
    gap: 1rem;
    flex-direction: column;
  }

  .card-save {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 6%;
  }
}

@media only screen and (max-width: 430px) {
  .save_search_container {
    width: 100%;
  }

  .save_search_title {
    font-size: xx-large;
  }

  .save_search_text {
    font-size: 3vh;
  }

  .cards-save {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .card-save {
    width: 90%;
    text-wrap: stable;
  }

  .card_search {
    margin-left: 2%;
    margin-top: 2%;
    font-size: medium;
  }
  .card-save{
    width: fit-content;
    height: fit-content;
    padding: 0.35rem 1rem;
    padding-bottom: 50px;
  }
  .save_search_container {
    padding-top: 28px;
    padding-bottom: 80px;
  }
}