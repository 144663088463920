.com-text1{
    background: -webkit-linear-gradient(rgb(38, 10, 94), rgb(204, 21, 164));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.com-text2{
    background: -webkit-linear-gradient(rgb(38, 10, 94), rgb(204, 21, 164));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.complete-img{
    width: 100%;
    height:100%;
}

.complete_profile-main1{
    width:100%;
    min-height:50px;
    background:whitesmoke;
    margin-top:40px;
}

.complete_profile-main2 {
    width: 100%;
    min-height: 100px;
    gap: 15px;
    padding: 0 2%;
    display: grid;
    background: whitesmoke;
    grid-template-columns: repeat(7, 1fr);
    overflow-x: scroll;
    margin-bottom: 50px;
}

.complete_profile-main2::-webkit-scrollbar {
    display: none;
}


.complete_profile-main3{
    width:100%;
    min-height:50px;
    background:whitesmoke;
    margin-top:40px;
}
.complete_profile-main4{
    width:100%; 
    min-height:100px;
     gap:15px; 
     padding:0 2%; 
     display:grid; 
     margin-bottom:50px; grid-template-columns: repeat(7,1fr);
     overflow-x: scroll;
     margin-bottom:50px;
 
}

.complete_profile-main4::-webkit-scrollbar{
    display: none;
 }
 
.userProfile-ad-card{
    /* width:320px;  */
    height:170px; 
    background-color:rgb(86, 219, 243); 
    padding:11px; 
    display:flex; 
    box-shadow: 0px 1px 5px gray; 
    border-radius:10px;
    margin: 10px 25px 10px 30px;
    flex: 0 0 calc(25% - 2px);
    /* width: 25%; */
    scroll-snap-align: start; 
}
.userProfile-ad-card1{
    width:200px; 
    height:100%;
}
.userProfile-ad-card2{
    display:flex;
    flex-direction:column; 
    justify-content:space-between;
    align-items:end;
    padding:10px 3px;
    cursor: pointer;
}
.userProfile-ad-card-btn{
    height: 45px;
    border:none;
}
.button-link {
    color: white !important;
}

.arrow-button-profile {
    position: absolute;
    /* top: 55%; */
    transform: translateY(100%);
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: rgb(12, 2, 2);
    padding: 6px;
    cursor: pointer;
    z-index: 10;
    width: 38px;
    border-radius: 5px;
  }
  
  .arrow-button-profile.left {
    left: 10px;
  }
  
  .arrow-button-profile.right {
    right: 10px;
  }

@media (max-width:360px){
    .complete-img{
        height:150px;
    }
}
@media(max-width:486px){
    /* .complete_profile-main2{
        display:flex ;
        flex-wrap: wrap;
        overflow:hidden;
    }
    .complete_profile-main4{
        display:flex ;
        flex-wrap: wrap;
        overflow: hidden;
    } */
    .userProfile-ad-card{
        margin-left: 55px;
    }
}
@media (max-width : 768px){
    .userProfile-ad-card{
        margin-left: 38px;
    }
}