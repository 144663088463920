.login-containerL {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.newsletter-sectionlog {
  background-image: url(./form-bg.jpg);
}

.social-icons-wrapper {
  display: flex;
  justify-content: center;
}


.social-icons {
  justify-content: center;
}

.social-icons a {
  display: inline-block;
  margin-right: 20px;
  color: #333;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #007bff;
}

.log-btn {
  background: #791848;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.log-btn:hover {
  background: linear-gradient(to right, #ff428e, #82befd);
}

.login-label {
  text-align: center;
  color: whitesmoke !important;
  font-size: 30px;
  font-weight: 700;
}

/* ----------------------------------------------- */
.auto-containerlog {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0%;
}

.rowlog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.labellog {
  width: 100%;
  color: black;
  display: flex;
  justify-content: center;
}

.lasl {
  color: black;
  font-size: 17px;
  font-weight: 400;
}

.form-groupcheck {
  display: flex;
  justify-content: center;
  align-items: center;
}

.capInput::placeholder {
  color: lightgrey;
  opacity: 1;
  /* Firefox */
}

.capInput::-ms-input-placeholder {
  /* Edge 12-18 */
  color: lightgrey;
}

.capInput {
  width: 400px;
  margin-right: 20px;
  padding: 8px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: black;
}

.login-formL {
  width: 450px;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.glassmob {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0px 0.5px 5px lightblue;
}

.input-log {
  width: 100%;
  padding: 10px;
  /* background-color: rgba(255, 255, 255, 0.2); */
  background: rgb(40, 20, 51);
  backdrop-filter: blur(10px);
  border: none;
  outline: none;
  border-radius: 13px;
}


button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #Ec167f;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 0px;
}

span.forget_style {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.toggle-password-buttonL {
  position: absolute;
  top: 50%;
  right: 5px;
  top: 50px;
  cursor: pointer;
  padding-right: 10px;
  /* background-color: none; */
}

.captcha-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-top: 10px;
  gap: 40px;
}

.captcha-heading {
  font-size: 28px;
  color: #333;
}

.captcha {
  margin-bottom: 10px;
}

.checkbox-log {
  margin-top: 10px;
}

.captcha p {
  font-size: 20px;
  color: rgb(14, 14, 129)
}

.captcha .captcha-btn {
  padding: 13px 50px;
  font-size: 15px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 98%;
}

.captcha .log-btn:hover {
  background-color: #0056b3;
}

.captcha-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.captcha-input .input-log {
  margin-right: 10px;
  padding: 10px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;

}

.form-grouplog {
  margin-bottom: 20px;
}


.captcha .log-btn {
  padding: 7px 14px;
  font-size: 18px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: 2px solid #007bff;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.captcha .log-btn:active {
  background-color: #004080;
  border-color: #004080;
}

.btncap {
  background-color: #007bff;
  color: #fff;
  border-radius: 10px;
  padding: 8px 20px;
  font-size: 15px;
  border: none;
  outline: none;
}

.btncap:hover {
  background-color: #31091d;
}

.login-label {
  text-align: center;
  font-family: bold;
  color: #ec167f !important;

}

.form-groupcheck {
  justify-content: left;
}

.checkbox-log {
  margin-top: 0;
}

@media (max-width: 468px) {

  .login-formL,
  .contact-forml,
  .auto-containerlog,
  .rowlog,
  .form-column,
  .login-containerL {
    width: 100%;
  }
}