.contactus-container {
  background-image: url(./form-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  height: 100%;
  margin-top: -2.3rem;
  margin-bottom: -1.9rem;
}

.contact-main {
  margin-top: 90px;
}

.contact-heading {
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 700;
  color: rgb(192, 22, 101);
}


.colorful-form {
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 20px;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg,
      rgba(2, 0, 36, 1) 9%,
      rgba(9, 9, 121, 1) 68%,
      rgba(0, 91, 255, 1) 97%);
  backdrop-filter: blur(9px);
  border-radius: 10px;
}

.form-group-contact {
  margin-bottom: 20px;
}

.form-label-contact {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: rgb(223, 217, 217);
}

.form-input-contact {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #fff;
  color: #333;
  border-radius: 5px;
}

textarea.form-input-contact {
  height: 100px;
}

.form-button-contact {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ff6f69;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button-contact:hover {
  background-color: #ff5f59;
}