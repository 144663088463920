.feedback-details-container {
    padding: 20px;
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 30px;
  }
  .feedback-details-skeleton{
    /* display: flex; */
    /* flex-direction: row; */
    width: 300px;
    margin: 10px;
    padding: 20px;
    height: 250px;
  }
  .feedback-details-card {
    display: flex;
  flex-direction: row;
  width: 300px;
  margin: 10px;
  padding: 10px;
  height: 250px;
  border: 1px solid rgb(141, 133, 134);
  border-radius: 100px 0px 100px 0px;
  background-color: #fff;
  box-shadow: 0 0 8px black;
  }
  .feedback-details-card:hover{
    background-color: rgb(230, 200, 205);
    /* transition: 0.2; */
  }
  .feedback-details-outercard{
  display: flex;
  flex-direction: row;
  width: 320px;
  height: 300px;
  margin: 10px;
  padding: 10px;
  border: 1px solid rgb(141, 133, 134);
  border-radius: 115px 0px 95px 0px;
  background-color: rgb(238, 173, 183);
  box-shadow: 0 0 10px black;
  }
  .FeedbackFormDetailsh1{
    margin: 0;
    padding-left: 38%;
    color: #333;
    text-shadow: 0 0 4px rgb(164, 98, 98);
    text-decoration: underline;
  }

 
  .line-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tittle-line{
  display: grid;
  grid-template-columns: repeat(10, 0fr);
  align-items: center;
  margin-top: 7px;
  }
  .tittle-line::before, .tittle-line::after{
    content:"";
    height: 4px;
    width: 100px;
    background-color: palevioletred;
    display: block;
  }
  .feedback-details-title {
    font-size: 1.5rem;
    margin: 0 0 10px 0;
  }
  
  
  .feedback-details-info p {
    margin: 6px;
  }
  .feedback-details-name{
    padding-left: 80px;
    font-size: 0.9rem;
    color: #333030;
    font-weight: bold;
  }
  .feedback-details-email,
  .feedback-details-subject,
  .feedback-details-message {
    font-size: 0.9rem;
    padding-left: 40px;
    color: #333030;
    font-weight: bold;
  }
  .faUserLarge-icon{
    padding-left: 50px;
    margin-top: -30px;
  }
  

  .feedback-details-email{
    color: maroon;
    padding-right: 30px;
    font-size: 10px;
  }
  .feedback-details-subject{
    color: rgb(81, 105, 32);
  }
  .feedback-details-message{
    color: rgb(225, 163, 48);
  }
/* Viewmore.css */

.ViewMoreLink {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.ViewMoreLink:hover {
  background-color: #0056b3;
}

  
  @media screen and (max-width: 768px) {
    .feedback-details-container {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      width: calc(127% - 56px); 
    }
    
    .feedback-details-card {
      width: 270px;
      height: 260px;
      margin-left: 25px;
    }
    .feedback-details-outercard{
      width: 300px;
      height: 300px;
      margin-left: 40px;
    }
    .FeedbackFormDetailsh1{
      padding-left: 4%;
      margin-top: 20px;
  
    }
  }
  

