

.title-TC{
  color:rgb(104, 2, 50);
  font-size:20px;
  font-weight: 700;

}

.page-title-TC{
  margin-top: 90px;
}

.read-more-text,
.read-less-text {
  display: none;
}


@media only screen and (max-width: 768px) {
    .page-title-TC {
      margin: 90px 0px;
    }
  
    .auto-container-TC {
      padding: 0 0px; 
      margin-right: 30px
    }    

  .read-more-text,
  .read-less-text {
    display: inline;
    cursor: pointer;
  }

  .read-more-text,
  .read-less-text {
    color: blueviolet;
  }

  .full-text.hidden {
    display: none;
  }

  .full-text.visible {
    display: block;
  }

  }
 





