.Delete-container{
    background-color:#f1f1f1 ;
    padding:15px ;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) ;
    width:40%;
    height: auto;
    margin-bottom: 10%;
    margin-top: 4%;

}
.delete-reason-button {
    width: 150px; 
    height: 40px; /* Increase the height */
    border-radius: 20px; /* Add border radius */
    margin: 5px; /* Add margin between buttons */
    background-color: #ffffff; /* Set initial background color */
    border: 1px solid #cccccc; /* Add border */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s ease; /* Smooth transition for background color change */
  }
  
  .delete-reason-button.active, .delete-reason-button.active + .Delete-content {
    background-color: rgb(102, 207, 248);
  }
  
  .delete-reason-button:hover {
    background-color: rgb(102, 207, 248); /* Change background color on hover */
  }
  .Delete-content{
    background-color:#f1f1f1 ;
    padding:15px ;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) ;
   width: 80%;
   height: 60%;
  }
  .delete-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .options-container {
    margin-top: 20px;
    background-color:#f1f1f1 ;
    padding:15px ;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) ;
   width: 80%;
   height: auto;
   display: flex;
   flex-direction: column;
  }
  
  .options {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
  }
  
  .options-column {
    display: flex;
    flex-direction: column;
  }
  .checkbox-label {
    display: flex;
    align-items: center;
  }
  
  .checkbox-label input[type="checkbox"] {
    margin-right: 8px; /* Adjust the margin as needed */
  }
  
  
  .closeoptions{
    margin-top: 5%;
    width: 150px; /* Adjust the width as needed */
    height: 40px; /* Increase the height */
    border-radius: 20px; /* Add border radius */
    margin: 5px; /* Add margin between buttons */
    background-color: #ffffff; /* Set initial background color */
    border: 1px solid #cccccc; /* Add border */
    cursor: pointer; 
  }
  @media screen and (max-width: 1280px){
    .Delete-container{
      width: 90%;
      height: auto;
    }
    .Delete-button{
      display: flex;
      flex-direction: column;
    }
    .delete-reason-button{
     margin-left: 25%;
    }
    .options-container{
     width: 100%;
    }
}