.container-FAQ{
    background-color: aliceblue;
    color:azure;
    border: 3px solid black;
    border-radius: 20px;
}
.bck-FAQ{
   width: 90%;
    background-color: wheat;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 0px;
  padding-right: 20px;
  border: 1px solid black;
  border-radius: 20px;

}
.topp-FAQ{
    margin-top: 120px;
   margin-left: 180px;
   margin-right: 180px;
}
.fam-FAQ{
    /* font-fam-TCily: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    font-weight: 600;
    color: black;
}
.name-FAQ{
    text-align: center;
    color: white;
    background: black;
    border-radius: 20px;
    margin-bottom: 10px;
    border: 2px solid black;
}


@media (max-width:768px){
    .topp-FAQ{
        text-align: center;
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
        /* display: flex;
        flex-direction: column;
        justify-content: center; */
    }
    .container-FAQ{
        /* text-align: center;
        justify-content: center; */
        width: 100%;
    } 
    .fam-FAQ{
        width: 100%;
    } 
    .bck-FAQ{
        width: 90%;
        margin-left: 0px;
    }
}

@media(max-width:768px){
    .name-FAQ{
        text-align: center;
        width:95%;
        color: white;
        background: black;
        border-radius: 20px;
        margin-bottom: 10px;
        border: 2px solid black;
    }  
}
