.create-blog-post {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.blogpost-img {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
}

.blog-image {
  max-width: 500px;
  margin-right: 20px;
  border-radius: 10px;
  flex: 1;
}

.blogpost-form {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 20px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 9%,
    rgba(9, 9, 121, 1) 68%,
    rgba(0, 91, 255, 1) 97%
  );
  backdrop-filter: blur(9px);
  border-radius: 10px;
  box-sizing: border-box;
}

.form-labelb {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: rgb(223, 217, 217);
}

.form-details-blog {
  margin-bottom: 20px;
}

.blog-heading {
  color: rgb(180, 14, 125);
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.back-button-pay {
  width: auto;
  background-color: var(--pink);
  color: rgb(224, 40, 169);
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-right: 98%;
}

.form-input-blog {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #fff;
  color: #333;
  border-radius: 5px;
  box-sizing: border-box;
}

.blog-textarea {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #fff;
  color: #333;
  border-radius: 5px;
  box-sizing: border-box;
}

button.blog-button {
  width: 150px;
  color: rgb(199, 18, 123);
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
}

button.blog-button:hover {
  background-color: #0056b3;
}

.blog-btn-text {
  color: white;
  font-size: 20px;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .blog-image {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .blogpost-form {
    max-width: 90%;
    margin-right: 20%;
  }
}

@media (max-width: 600px) {
  .create-blog-post {
    width: 100%;
    padding: 10px;
  }

  .blogpost-img {
    flex-direction: column;
    align-items: center;
  }

  .blog-image {
    display: none;
  }

  .back-button-pay {
    margin-right: 75%;
    width: 20%;
    /* text-align: center; */
  }

  button.blog-button {
    width: 100%;
    margin-left: 0;
  }

  .blog-heading {
    font-size: 24px;
  }
}
