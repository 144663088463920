.main_container{
    height: 100vh;
    margin: 0;
    padding: 0;
}

.page-title p{
    position: relative;
    display: block;
    justify-content: center;
    align-items: center;
    height: 100px;
    background: #fff;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 45px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #05595B;
    text-align: center;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 150px;
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f3f3f3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.displayIcons {
 height: 100%;
 width: 300px;

}
.icons-user-id {
     width: 800px;
    height: 900px;
}
.icons-user-id:hover{
    color: rgb(176, 174, 172);
}

.theme-btn {
    text-align: center;
    width: 200px;
    padding: 10px;
    background: rgb(225, 7, 138);
}
.btn-title{
    color: #fff;
}

.theme-btn:hover{
    background: rgb(129, 18, 84);
}

.anim-icons{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #e0dfdf;
    border-radius: 20px;
}

.anim-icons span{
    margin-top: 30px;
}

.icon{
    width: 100px;
    height: 100px;
    margin: 30px;
    background: #a385f7;
}

@media screen and(max-width:835px) {
    .page-title p{
        position: relative;
        display: block;
        justify-content: center;
        align-items: center;
        height: 100px;
        background: #fff;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        font-size: 45px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        color: #05595B;
        text-align: center;
        line-height: 1em;
        text-transform: uppercase;
        font-weight: 400;
        margin-top: 30px;

    }
    
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 700px;
        background: #0f0e0e;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    
    .displayIcons {
     height: 100%;
     width: 300px;
    
    }
    .icons-user-id {
         width: 800px;
        height: 900px;
    }
    .icons-user-id:hover{
        color: rgb(176, 174, 172);
    }
    
    .theme-btn {
        text-align: center;
        width: 200px;
        padding: 10px;
        background: rgb(225, 7, 138);
    }
    .btn-title{
        color: #fff;
    }
    
    .theme-btn:hover{
        background: rgb(129, 18, 84);
    }
    
    .anim-icons{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
        background: #e0dfdf;
        border-radius: 20px;
    }
    
    .anim-icons span{
        margin-top: 30px;
    }
    
    .icon{
        width: 100px;
        height: 100px;
        margin: 30px;
        background: #a385f7;
    }
}