.add-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed #aaa;
    padding: 8px;
    cursor: pointer;
  }
 
  .add-img .fa-clone {
    font-size: 24px;
    margin-bottom: 10px;
    color: #555;
  }
 
  .drag-drop{
    font-size: 12px;
    color: white;
  }

.image-input{
    display: none;
}

.btn-image-submit {
    text-align: center;
}

.title-name{
    align-items: center;
    text-align: center;
    margin-top: 30px;
    cursor: pointer;
    color: rgb(59, 240, 240);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.button-images {
    background-color:  rgb(106, 216, 139);
    border: none;
    color: white;
    padding: 10px 60px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 238px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 300px;
}
 
.button-images:hover {
    background-color:   rgb(240, 57, 164);
}
 
  .image-input:checked + label::after {
    content: attr(value);
    display: block;
    margin-top: 10px;
    color: #777;
  }

.image-enter{
    width: 50px;
    height: 50px;
}

  .image-outer-box {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    border: 3px solid #fff;
    max-width: 650px;
    height: auto;
    background: lightgrey;
    border-radius: 10px;
    justify-content: space-between; 
}
 
.image-container-add {
    width: calc(50% - 56px); 
    margin: 28px;
    padding: 45px;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    overflow: hidden;
}
 
.popup {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-left: 10px;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-top: 10px;
}

.popup-content h2 {
    margin-bottom: 10px;
    color: rgb(243, 105, 105);
    padding-bottom: 10px;
}

.popup-content p{
    padding-bottom: 20px;
    font-weight: bold;
}

.add-img {
    cursor: pointer;
}

.add-more-photos {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff; 
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  
}

.add-more-photos:hover{
    background-color: rgb(241, 60, 90);
}

.profile-icon{
    border: 1px solid pink;
    width: 50px;
    height: 30px;
    margin-left: 130px;
    background-color: pink;
    margin-bottom: 10px;
}




.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.dropzone p {
    margin: 0;
}

.dropzone:hover {
    background-color: #f0f0f0;
}


@media only screen and (max-width: 768px) {
    .image-container-add {
        width: calc(100% - 56px); 
    }
}
 
.add-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed #aaa;
    padding: 8px;
    cursor: pointer;
}
 
.add-img .fa-clone {
    font-size: 24px;
    margin-bottom: 10px;
    color: #555;
}
 
.image-input {
    display: none;
}
 
.btn-image-submit {
    text-align: center;
}
 
.title-name {
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    color: rgb(201, 5, 119);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
 
.button-images {
    background-color: rgb(231, 21, 142);
    border: none;
    color: white;
    padding: 10px 60px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px auto; 
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
}
 
.button-images:hover {
    background-color: rgb(240, 57, 164);
}
 
.image-input:checked + label::after {
    content: attr(value);
    display: block;
    margin-top: 10px;
    color: #777;
}