.clear {
    clear: both;
}

A:link {
    text-decoration: none;
    color: #a2a2a2;
}

A:visited {
    text-decoration: none;
    color: #a2a2a2;
}

A:active {
    text-decoration: none;
    color: #a2a2a2;
}

A:hover {
    text-decoration: underline;
    color: #a2a2a2;
}

img {
    border: 0px;
}


.r {
    padding-top: 3px;
    float: left;
}

.l {
    padding-top: 5px;
    float: left;
}

.inner-wrapper-title{
    font-size: 22px;
    color: #0f0566;
    margin: 30px 0px 19px 0px;
    text-align: center;
}

.inner-wrapper {
    padding: 15px;

}
.inner-wrapper-l-l {

}
.slider-container{
    width:80%;
    height: 200px;
    margin-left: 240px;
    /* margin:auto;   */
}
.inner-wrapper-l-l {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
}
.inner-wrapper-l-l img{
    width: 100px;
    height: 100px;
}


@media(max-width:768px){
    .center-links {
        display: block;
        margin: 0 auto; 
        text-align: center; 
        margin-left: 110px;
      }
      .slider-container{
        width: 95%;
        /* margin: auto; */
        margin-left: 30px;
        height: 300px;
      }
      .inner-wrapper {
        display: flex;
    }
    }
    
    
    
@media (min-width:768px) and (max-width:1024px){
    .slide-con{
       margin-left: -100px;
       width: 90%;
    }
}