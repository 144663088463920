.container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* .feedback-details {
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
} */

.feedback-details-p {
  text-align: center;
  margin-left: 40%;
  margin-top: 15%;
  width: 320px;
  height: 250px;
  padding: 40px;
  border: 1px solid rgb(141, 133, 134);
  border-radius: 30px;
  background-color: rgb(238, 173, 183);
  box-shadow: 0 0 10px black;
  font-size: 1.1rem;
  color: #333030;
  font-weight: bold;
  
}

.loading {
  font-weight: bold;
}

.error {
  color: red;
  font-weight: bold;
}
