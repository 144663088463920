
.tabs-boxpro {
    position: relative;
}

.tabs-boxpro {
    position: relative;
}

.schedule-tabspro {
    position: relative;
    margin-top: 30px;
}

.schedule-tabspro .btns-box {
    position: relative;
    display: block;
    margin-bottom: 40px;
}

.schedule-tabspro .tab-buttonspro li {
    position: relative;
    display: inline-block;
    height: 135px;
    width: 160px;
    border-radius: 20px;
    background-color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
    font-weight: 600;
    text-align: left;
    padding: 15px 15px;
    padding-top: 60px;
    padding-left: 90px;
    cursor: pointer;
    margin: 0 15px 20px;
    border: 1px solid #dddddd;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.schedule-tabspro .tab-buttonspro li .day {
    position: absolute;
    left: 0px;
    top: 20px;
    padding: 5px 20px;
    background-color: #222222;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 0 15px 15px 0;
}

.schedule-tabspro .tab-buttonspro li .date {
    position: absolute;
    left: -0;
    top: 80px;
    width: 150px;
    text-align: center;
    font-size: 38px;
    line-height: 20px;
    font-weight: 600;
    color: #222222;
}

.schedule-tabspro .tab-buttonspro li.active-btnpro {
    box-shadow: 0 20px 30px rgba(0, 0, 0, .10);
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 1) .day {
    background-color: #ffc20b;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 2) .day {
    background-color: #40cbb4;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 3) .day {
    background-color: #4c35a9;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 4) .day {
    background-color: #e6275a;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 5) .day {
    background-color: #05a7dc;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 1) .datepro {
    color: #ffc20b;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 2) .datepro {
    color: #40cbb4;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 3) .datepro {
    color: #4c35a9;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 4) .datepro {
    color: #e6275a;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 5) .datepro {
    color: #e6275a;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 1).active-btnpro {
    box-shadow: 0 0px 30px rgba(255, 194, 11, .30);
    border: 1px solid #ffc20b;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 2).active-btnpro {
    box-shadow: 0 0px 30px rgba(64, 203, 180, .30);
    border: 1px solid #40cbb4;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 3).active-btnpro {
    box-shadow: 0 0px 30px rgba(76, 53, 169, .30);
    border: 1px solid #4c35a9;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 4).active-btnpro {
    box-shadow: 0 0px 30px rgba(230, 39, 90, .30);
    border: 1px solid #e6275a;
}

.schedule-tabspro .tab-buttonspro li:nth-child(4n + 5).active-btnpro {
    box-shadow: 0 0px 30px rgba(43, 199, 234, .30);
    border: 1px solid #05a7dc;
}

.about-section-two-indexpro {
    position: relative;
    padding: 20px 0 20px;
}

.about-section-two-indexpro .icon-circle-1 {
    right: -200px;
    top: -200px;
    opacity: .05;
}



.schedule-tabspro .tab-buttonspro {
    position: relative;
    text-align: left;
}


@media(max-width:768px){
    .img-thumbnail {
        margin-left: 0px;
        margin-top: 70px;
    }
    .rowpro {
        flex-direction: column;
        display: -ms-flexbox;
        display: flex;
        width:300%;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
}

.rowpro {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-lg-12pro,
.col-lg-4pro,
.col-md-6pro,
.col-sm-12pro {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}



.textpro {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: black;
    font-weight: 500;
}
.textpro span{
    color: #ec167f;
}

.auto-containerpro {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

.theme-btnpro {
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.theme-btnpro {
    position: relative;
    transition: all 300ms ease;
}

.btn-style-onepro {
    position: relative;
    padding: 10px 30px;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    background-color: #ec167f;
    overflow: hidden;
    border-radius: 10px;
}

.btn-style-onepro .btn-titlepro {
    position: relative;
}

.btn-style-onepro:hover {
    color: #ec167f;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
}

.btn-style-onepro:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background-color: #ffffff;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
    -webkit-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
}

.btn-style-onepro:hover:before {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
}

.feature-block-twopro {
    position: relative;
    margin-bottom: 40px;
}

.inner-boxpro .icon-boxpro:hover{
    transform: scale(1.1);

    transition: all 0.2s;
    
}
.inner-boxpro:hover{
    transform: scale(1.1);

    transition: all 0.8s;
    
}
.feature-block-twopro{
    display: grid;
    width:30%;
    box-shadow: #007bff;
    color: #007bff;
}
.inner-boxpro.inner-boxpro.text2:hover:hover{
    color: #5b626a;
    box-shadow: #007bff;
}


.feature-block-twopro .inner-boxpro {
    position: relative;
    width:100%;
    padding: 40px 30px;
    padding-left: 100px;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.10);
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    overflow: hidden;
}

.feature-block-twopro .inner-boxpro:hover {
    box-shadow: none;
}

.feature-block-twopro .icon-boxpro {
    position: absolute;
    left: 30px;
    top: 50px;
    display: block;
    margin-bottom: 30px;
}

.feature-block-twopro h4 {
    position: relative;
    display: block;
    font-size: 22px;
    line-height: 1.2em;
    color: #12114a;
    font-weight: 700;
    margin-bottom: 10px;
}

.feature-block-twopro h4 a {
    color: #12114a;
    display: inline-block;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.feature-block-twopro h4 a:hover {
    color: #f14b59;
}

.feature-block-twopro .textpro {
    position: relative;
    display: block;
    font-size: 15px;
    line-height: 24px;
    color: black;
    font-weight: 400;
    cursor: default;
}

.gallery-item {
    position: relative;
    margin-bottom: 90px;
    margin-top: -5px;
}

.gallery-item .image-boxpro {
    position: relative;
    box-shadow: 0 0px 30px rgba(8, 13, 62, .10);
    overflow: hidden;
}

.gallery-item .overlay-boxpro {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    content: "";
    opacity: 0;
    background-color: #1d95d2;
    -webkit-transform: scale(.5);
    -moz-transform: scale(.5);
    -ms-transform: scale(.5);
    -o-transform: scale(.5);
    transform: scale(.5);
    border-radius: 500px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.gallery-item .image-box:hover .overlay-box {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: .9;
    border-radius: 0px;
}

.gallery-item .overlay-box a {
    position: absolute;
    left: 56%;
    top: 67px;
    margin-top: -20px;
    margin-left: 86px;
}

.gallery-item .overlay-box a span {
    display: block;
    height: 58px;
    width: 58px;
    background-color: #ec167f;
    color: #ffffff;
    border-radius: 50%;
    font-weight: 700;
    font-size: 18px;
    line-height: 58px;
}


.gallery-item .image-boxpro {
    position: relative;
    box-shadow: 0 0px 0px rgb(8 13 62 / 10%);
    overflow: initial;
}

.gallery-item .overlay-box {
    position: absolute;
    left: 0;
    top: 0;
    height: 99%;
    width: 39%;
    text-align: center;
    content: "";
    opacity: 0;
    background-color: transparent;
    margin-left: 14%;
}

.theme-btnpro {
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.theme-btnpro {
    position: relative;
    transition: all 300ms ease;
}


.text2 {
    position: relative;
    display: block;
    font-size: 15px;
    line-height: 24px;
    color: #f20487;
    font-weight: 400;
    cursor: default;
}

.gallery-item .overlay-boxpro a {
    position: absolute;
    left: 8%;
    top: 161px;
    margin-top: -15px;
    margin-left: 84px;
}


.gallery-item .image-boxpro {
    position: relative;
    box-shadow: 0 0px 0px rgb(8 13 62 / 10%);
    overflow: initial;
}

.gallery-item .overlay-boxpro {
    position: absolute;
    left: 0;
    top: 0;
    height: 99%;
    width: 39%;
    text-align: center;
    content: "";
    opacity: 0;
    background-color: transparent;
    margin-left: 14%;
}

.theme-btnpro {
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.theme-btnpro {
    position: relative;
    transition: all 300ms ease;
}
.back-button-paypro{
    background-color: #ec167f;
    width: 5%;
    margin-left: 1%;
    border-radius: 3%;
   }
   .editpro {
    color: #0056b3;
    cursor: pointer;
    padding: 0;
    font-size: 20px;
  }
  
  .editpro:hover {
    color:#f14b59;
  }
  
  
@media(max-width:768px){
    .theme-btnpro {
        margin-left: 70px;
        position: relative;
        transition: all 300ms ease;
    }
    
.feature-block-twopro .inner-boxpro {
    position: relative;
    width:100%;
    padding: 40px 30px;
    margin-left: 40px;
    padding-left: 100px;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    overflow: hidden;
}
}

@media only screen and (max-width: 428px){
   .back-button-paypro{
    background-color: #ec167f;
    width: 30%;
    margin-left: 33%;
    border-radius: 3%;
   }
   .image-boxpro {
    margin-left: 25%;
   }
   
}
