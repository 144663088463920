.col-lg-3Ed,
  .col-lg-9Ed,
  .col-md-12,
  .col-md-5Ed,
  .col-sm-12Ed,
  .col-sm-6Ed {
    position: relative;
  
    min-height: 15px;

    .text-centerEd {
      margin-left: 850px;
    }
    
  }


@media (max-width: 768px) {
  .contact-formEd .form-groupEd textarea,
  .contact-formEd .form-groupEd select {
    position: relative;
    display: block;
    width: 200px;
    font-size: 14px;
    margin-right: 70px;
    color: #312d2dba;
    line-height: 32px;
    padding: 0px 13px;
    border: 1px solid #a1a7a1;
    font-weight: 400;
    height: 50px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    border-radius: 3px;
  }

  .labelEd {
    margin-left: -18%;
    margin-bottom: -10px;
    color: black;
  }
}

.labelEd {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: black;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
.agile-ltextEd {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 98%;
}
button,
.agile-ltextEd {
  overflow: visible;
  
}

button,
select {
  text-transform: none;
}

.agile-ltextEd[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  height: 20px;
  width: 20px;
}
.edu-raw{
  width: 70%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 1rem;
  padding: 3rem;
  margin: 1rem auto;
}
.rowEd {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  margin-bottom: 10px;
  gap: 15px;
  justify-content: space-between;
}

.page-title-Edu h1 {
  color: #fff;
  font-size: 40px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #05595B;
  text-transform: uppercase;
}

.contact-formEd .form-groupEd textarea,
.contact-formEd .form-groupEd select {
  position: relative;
  display: block;
  width: 200px;
  font-size: 14px;
  margin-left: -0px;
  color: #312d2dba;
  line-height: 32px;
  padding: 0px 13px;
  border: 1px solid #a1a7a1;
  font-weight: 400;
  height: 50px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 3px;
}

.col-lg-3Ed,
.col-lg-9Ed,
.col-md-12,
.col-md-5Ed,
.col-sm-12Ed,
.col-sm-6Ed {
  position: relative;
  width: 100%;
  min-height: 20px;
  padding-right: 20px;
  padding-left: 15px;
}
.col-lg-10Ed {
  width: 100%;
  display: flex;
  justify-content: center;
}
.col-md-10Ed {
  width: 105%;
}
.col-sm-6Ed {
  width: 70%;
}
.form-groupEd {
  margin-bottom: 1rem;
}

.btnEd {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btnEd:focus,
.btnEd:hover {
  text-decoration: none;
}
.btnEd:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btnEd:disabled {
  opacity: 0.65;
}

.btnEd:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btnEd:not(:disabled):not(.disabled):active {
  background-image: none;
}

.collapse:not(.show) {
  display: none;
}

.dropdown {
  position: relative;
}

.clearfixEd::after {
  display: block;
  clear: both;
  content: "";
}

.d-noneEd {
  display: none !important;
}

.text-center {
  text-align: center !important;
}

.text-white {
  color: #fff !important;
}

.auto-containerEd {
  position: static;
  text-align: center;
  padding: 0px 15px;
  margin: 0 auto;
}

.page-wrapperEd {
  position: relative;
  width: 98%;
  overflow: hidden;
}

button.theme-btnEd,
.btnEd,
.btn-style-oneEd,
.mt-4Ed {
  width: 30px;
  margin-left: 600px;
  margin-bottom: 10px;
}

.theme-btnEd {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.theme-btnEd {
  position: relative;
  transition: all 300ms ease;
}

.btn-style-oneEd {
  position: relative;
  padding: 10px 30px;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  background-color: #ec167f;
  overflow: hidden;
  border-radius: 10px;
}

.btn-style-oneEd .btn-title {
  position: relative;
}

.preloaderEd {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(https://www.matrimonysoftware.in/images/icons/load.gif);
  background-size: 200px;
}

.page-titleEd {
  position: relative;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  padding: 120px 0;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.page-titleEd h1 {
  position: relative;
  display: block;
  font-size: 60px;
  color: #ffffff;
  line-height: 1em;
  font-weight: 700;
  margin-bottom: 10px;
}

.page-titleEd .bread-crumb {
  position: relative;
}

.page-titleEd .bread-crumb li {
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 500;
  cursor: default;
  padding-right: 15px;
  margin-right: 15px;
}

.page-titleEd .bread-crumb li:before {
  position: absolute;
  right: -3px;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  content: "|";
}

.page-titleEd .bread-crumb li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.page-titleEd .bread-crumb li:last-child:before {
  display: none;
}

.page-titleEd .bread-crumb li a {
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.page-titleEd .bread-crumb li a:hover {
  color: #f0c52e;
}

.contact-page-sectionEd {
  position: relative;
  /* padding: 110px 0 70px; */
}

.contact-page-sectionEd .form-columnEd {
  position: relative;
  margin-bottom: 40px;
}

.contact-page-sectionEd .sec-title {
  margin-bottom: 30px;
}

.contact-page-sectionEd .sec-title h2 {
  font-size: 32px;
}

.contact-formEd .form-groupEd {
  position: relative;
  margin-bottom: 30px;
}

.contact-formEd .form-groupEd .agile-ltextEd[type="text"],
.contact-formEd .form-groupEd textarea,
.contact-formEd .form-groupEd select {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  color: #312d2dba;
  line-height: 32px;
  padding: 0px 13px;

  border: 1px solid #a1a7a1;
  /* background-color: ffffff; */
  font-weight: 400;
  height: 50px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 3px;
}

.contact-formEd .form-groupEd .agile-ltextEd:focus,
.contact-formEd .form-groupEd select:focus,
.contact-formEd .form-groupEd textarea:focus {
  border-color: #f20487;
}

.contact-formEd .form-groupEd textarea {
  height: 75px;
  resize: none;
}

.contact-page-sectionEd .contact-column {
  position: relative;
  margin-bottom: 30px;
}

.contact-page-sectionEd .contact-info {
  position: relative;
  margin-bottom: 37px;
}

.contact-page-sectionEd .contact-info li {
  position: relative;
  padding-left: 55px;
  margin-bottom: 30px;
}

.contact-page-section .contact-info li p {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #777777;
  font-weight: 400;
  margin: 0;
}

.contact-page-sectionEd .contact-info li strong {
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: #222222;
  font-weight: 600;
}

.contact-page-sectionEd .contact-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
  line-height: 40px;
  background-color: #1c94d2;
  color: #ffffff;
}

.labelEd {
  margin-left: 5px;
  margin-bottom: 2px;
  color: black;
}

.page-titleEd {
  padding: 10px 0;
}

.page-titleEd {
  margin-top: 120px;
}

.blinks {
  animation: blinks 1s linear infinite;
}

.theme-btnEd {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.theme-btnEd {
  position: relative;
  width: 40px;
  transition: all 300ms ease;
}

.theme-btnEd {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.back-button-pay{
  display: none;
}
.theme-btnEd {
  position: relative;
  transition: all 300ms ease;
  margin-left: 10px;
  width: 30%;
}

@media (min-width: 768px) {
  .d-md-blockEd {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .d-lg-blockEd {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-blockEd {
    display: block !important;
  }
}

@media (min-width: 576px) {
  .col-sm-6Ed {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-10Ed {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .col-sm-12Ed {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-3Ed {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 100%;
  }

  .col-lg-9Ed {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10Ed {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}

@media (max-width: 821px) {
  .contact-page-sectionEd {
    padding: 21px 99px 3px 12px;
  }
  /* .rowEd {
    width: 90%;
    padding: 1rem;
  } */
  .labelchange {
    margin-left: 0px;
    text-align: center;
    display: flex;
  }

  .rowEd {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .contact-formEd .form-groupEd {
    position: relative;
    margin-bottom: 30px;
    margin-left: 50%;
    width: 600px;
  }

  .contact-formEd .form-groupEd .agile-ltextEd[type="text"],
  .contact-formEd .form-groupEd textarea,
  .contact-formEd .form-groupEd select {
    position: relative;
    display: block;
    width: 100%;
    margin-left: 10px;
    font-size: 14px;
    color: #312d2dba;
    line-height: 32px;
    padding: 0px 13px;
    margin-right: 60px;
    border: 1px solid #a1a7a1;
    /* background-color: ffffff; */
    font-weight: 400;
    height: 50px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    border-radius: 3px;
  }

  .labelEd {
    margin-left: 0px;
    margin-bottom: 2px;
    color: black;
  }

  .page-titleEd {
    padding: 78px 0px 7px 0px !important;
  }

  .page-titleEd h1 {
    position: relative;
    display: block;
    font-size: 60px;
    color: #ffffff;
    line-height: 1em;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .newsletter-sectionEd {
    padding: 0px 0px;
  }

  .contact-page-sectionEd {
    padding: 70px 0 20px;
  }

  .header-style-two .nav-outer {
    padding-top: 10px;
  }

  button.theme-btnEd,
  .btnEd,
  .btn-style-oneEd,
  .mt-4Ed {
    width: 200px;
    margin-left: 100px;
  }

  .page-title-Edu h1 {
    font-size: 34px;
    line-height: 1.2em;
  }

  .alertmesEd {
    margin-right: -34px;
    margin-left: 26px;
    margin-top: -46px;
  }
}

@media (max-width: 650px) {
  .clearfixEd {
    display: grid;
    /* grid-template-columns: repeat(1fr, 1); */
  }
  .custom-select-box-Horo,
  .col-md-5-Horo {
    width: 100%;
  }
  .rowEd {
    width: 85%;
    margin: auto;
    margin-left: 2%;
  }
  .col-lg-10Ed {
    margin-left: 2%;
  }
}

/* EducationStyle.css */

.searchable-dropdown {
  position: relative;
  width: 100%;
}

.searchable-dropdown input,
.searchable-dropdown select {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: 5px;
}

.dropdown-list {
  max-height: 150px;
  overflow-y: auto;
  display: none;
  position: absolute;
  background-color: white;
  /* background-color: #777777; */
  border: 1px solid #ccc;
  z-index: 1;
  width: 100%;
}

.dropdown-list.visible {
  display: block;
}

.dropdown-list div {
  padding: 5px;
  cursor: pointer;
}

.dropdown-list div:hover {
  background-color: #f1f1f1;
}

/* input.select-occu {
  background-color: #dcdddc;
}

input.select-qua {
  background-color: #dcdddc;
}

input.search-search {
  background-color: #a1a7a1;
} */
.special-case.agile-ltextEd {
  width: 50px;

}

select.agile-ltextEdd {
  width: 100%;
}

.text-centerEd {
  width: 200px;
  margin: 1rem auto;
  border-radius: 50%;
}

.annual-income {
  width: 100%;
}

@media (max-width: 550px) {
  .text-centerEd {
  margin-left: 25%;
  }

  .edu-raw{
    padding-top: 20px; 
    width: 95%;
    padding: .3rem;
  }
  .rowEd {
    width: 98%;
    gap: 1px;
    padding: 1rem;
}

.col-lg-3Ed,
.col-lg-9Ed,
.col-md-12,
.col-md-5Ed,
.col-sm-12Ed,
.col-sm-6Ed {
  position: relative;
  width: 100%;
  min-height: 10px;
  padding-right: 25px;
  padding-left: 15px;
  margin-left: 5px;
  gap: 1px;
}

}

