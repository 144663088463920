/* Toggle Button and Text Container */
.tgl-btn {
    display: flex;
    align-items: center;
    justify-content: normal;
}

/* Text for "Monthly" or "Yearly" */
.tgl-btn span {
    font-size: 16px;
    color: #070000;
    font-family: sans-serif;
}

/* Toggle Button Container */
.toggle-btn-btn {
    margin-left: 45%;
}

/* Switch Component */
.react-switch {
    vertical-align: middle;
    cursor: pointer;
    width: 60px;
    height: 30px;
    border-radius: 15px;
    transition: background-color 0.3s;
    background-color: #ccc;
}

/* Switch Handle */
.react-switch .react-switch-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-color: rgb(169, 8, 8);
    transition: left 0.3s;
}

/* Hide Pay Now Button */
.hide-pay-now {
    display: none;
}

/* Submit Button */
button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #Ec167f;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

/* Responsive Submit Button */
@media(max-width:768px) {
    button[type="submit"] {
        width: 80%;
        margin-left: 0;
    }
}

/* Title and Price Styling */
.title-pay,
.price-pay {
    margin-top: 0;
    margin-bottom: 0.5rem;
}


.contacts-pay{
    margin-top: 0;
    margin-bottom: 1rem;
}

.features-pay {
    margin-top: 0;
    margin-bottom: 1rem;
}


button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button,
input{
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button {
    text-transform: none;
}

[type="submit"],
button,
html [type="button"] {
    -webkit-appearance: button;
}


[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}


.page-title-pay h1,li{
    color:white;
    margin-left: 400px;
    margin-top: 20px;
}

.row-pay {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.back-button-pay{
    width:2%;
    background-color: var(--pink);
    color:white;
    font-size: 15px;
    font-weight: 700;
}
@media (max-width:768px){
    
    .page-title-pay h1,li{
        color:white;
        margin-left: 10px;
        margin-top: 10px;
        font-size: 40px;
    }
    .inner-box-pay{
        width: 790px;
        justify-content: center;
        text-align: center;
        margin-right: 30px;
        margin-top: 0px;
    }
    .col-sm-12{
        flex-direction: column;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    width: 100%;
    } 
    .tgl-btn {
        margin-left: -100px;
      }
}

.col-lg-12-pay,
.col-lg-4-pay,
.col-md-12-pay,
.col-md-4-pay,
.col-sm-12-pay,
.col-sm-4-pay {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 576px) {
    .col-sm-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .col-md-4-pay {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .col-lg-4-pay {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
    .d-md-block {
        display: block !important;
    }
}

@media (min-width: 992px) {
    .d-lg-block {
        display: block !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-block {
        display: block !important;
    }
}

/* Margin Utilities */
.mt-2 {
    margin-top: 0.5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

/* Text Alignment & Color */
.text-center {
    text-align: center !important;
}

.text-white {
    color: #fff !important;
}

/* FontAwesome Icons */
.fas,
.fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
}

.fab {
    font-family: "Font Awesome 5 Brands";
}

.fas {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

/* Pricing Section Styles */
.pricing-section-pay {
    position: relative;
    padding: 120px 0 80px;
    overflow: hidden;
}

.pricing-section-pay .outer-box-pay {
    max-width: 1100px;
    margin: 0 auto;
}

.pricing-block-pay {
    position: relative;
    padding: 0 30px;
    margin-bottom: 40px;
}

.pricing-block-pay .inner-box-pay {
    background-color: #ffffff;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
    padding: 0 0 30px;
    max-width: 370px;
    margin: 0 auto;
    border-radius: 5px;
}

.pricing-block-pay .icon-box-pay {
    background-color: #40cbb4;
    text-align: center;
    border-radius: 5px;
    padding: 50px 30px 0;
}

.pricing-block-pay .icon-box-pay .icon-outer-pay {
    height: 150px;
    width: 150px;
    background-color: #ffffff;
    border-radius: 50%;
    margin: 0 auto;
    padding: 10px;
}

.pricing-block-pay .icon-box-pay .icon {
    height: 130px;
    width: 130px;
    border: 5px solid #40cbb4;
    border-radius: 50%;
    font-size: 70px;
    color: #40cbb4;
    transition: all 600ms ease;
}

.pricing-block-pay .price-box-pay {
    text-align: center;
    padding: 10px 20px;
}

.pricing-block-pay .title {
    font-size: 24px;
    line-height: 1.2em;
    color: #222222;
    font-weight: 600;
}

.pricing-block-pay .price {
    font-size: 30px;
    color: #40cbb4;
    font-weight: 700;
}

.pricing-block-pay .features-pay {
    max-width: 200px;
    margin: 0 auto 20px;
}

.pricing-block-pay .features-pay li {
    font-size: 14px;
    line-height: 30px;
    color: #848484;
    padding: 5px 0;
    padding-left: 30px;
    border-bottom: 1px dashed #dddddd;
}

.pricing-block-pay .features-pay li:before {
    position: absolute;
    left: 0;
    top: 5px;
    font-size: 16px;
    content: "\52";
    color: #2bd40f;
}

.pricing-block-pay .btn-box-pay {
    text-align: center;
}

.pricing-block-pay .btn-box-pay a {
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    padding: 8px 30px;
    background-color: #40cbb4;
    border-radius: 10px;
    transition: all 300ms ease;
}

/* Specific Styling for Different Pricing Blocks */
.pricing-block-pay:nth-child(2) .icon-box-pay,
.pricing-block-pay:nth-child(2) .btn-box-pay a {
    background-color: #1d95d2;
}

.pricing-block-pay:nth-child(2) .price,
.pricing-block-pay:nth-child(2) .icon-box-pay .icon {
    color: #1d95d2;
}

.pricing-block-pay:nth-child(3) .icon-box-pay,
.pricing-block-pay:nth-child(3) .btn-box-pay a {
    background-color: #ffc20b;
}

.pricing-block-pay:nth-child(3) .price,
.pricing-block-pay:nth-child(3) .icon-box-pay .icon {
    color: #ffc20b;
}

/* Preloader Styling */
.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #ffffff;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(https://www.matrimonysoftware.in/images/icons/load.gif);
    background-size: 200px;
}

.preloader:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: "loading..";
    transform: translateX(-50%);
    margin-top: 45px;
    font-size: 24px;
    color: #f20487;
    text-decoration: underline;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
}
/*  converted 800 line css code to 450 lines  */