.latest-matches-container-mutual {
  display: flex;
  /* background-image: url(https://images.unsplash.com/photo-1619204715997-1367fe5812f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1889&q=80); */
}

.page-title-latest-mutual h1 {
  position: relative;
  display: block;
  background: url(https://www.yachtandboat.com/wp-content/uploads/2018/12/hero-image-2.jpg)   -20px -20px no-repeat;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display:block;
  font-size: 100px;
  font-family: arial;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  background-size: cover;
}

.page-title-latest-mutual .bread-crumbb li:last-child {
  font-size: 20px;
  color: chocolate; 
  font-weight: bold; 
}

.page-title-latest-mutual .bread-crumbb li:last-child .index_dashboardb a {
  font-size: 20px;
  color: chocolate; 
  font-weight: bold; 
}

.page-title-latest-mutual {
  position: relative;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  margin-top: -50px;
  background-color: black;
  color: white;
  padding: 20px;
}

.auto-container-latest-mutual {
  max-width: 1200px;
  margin: 0 auto;
}

.bread-crumbb-mutual {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bread-crumbb-mutual li {
  display: inline;
  margin-right: 10px;
}
.cards-section-mutual {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 5%;
  width: 150px;
  /* border: 2px solid red; */
  width: 100%;
  margin-bottom: 5%;  
 
}

 

.heroine-card-latest-mutual {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  border-radius: 12px;  
  padding: 38px;  
  filter: drop-shadow(0 30px 10px rgba(0,0,0,0.125));
  /* display: flex; */
  flex-direction: column;
  height: 70%;
  align-items: center;
  justify-content:center;
  text-align: center;
  margin: 5px;
  margin-top: 10%;
  background-color: pink;
}

.heroine-card-latest-mutual img {
  width: 100%;
  border-radius: 5px;
  height: 35vh;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.heroine-card-latest-mutual img:hover {
  transform: scale(1.1);
}

.details-latest-mutual {
  margin-top: 10px;
  color: white;
  font-weight: bolder;
}

.details-latest-mutual h2 {
  font-family: 'Righteous', sans-serif;
  color: rgba(255,255,255,0.98);
  text-transform: uppercase;
  font-size: 2.4rem;
}

.details-latest-mutual p {
  color: #fff;
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-size: 0.8rem;
  line-height: 150%;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-86-mutual {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-weight: bolder;
  color: red;
  margin-left: 45px;
}

.button-86-mutual::after,
.button-86-mutual::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.button-86-mutual::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

.button-86-mutual::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-86-mutual:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-86-mutual:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-86-mutual:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

.show-latest-mutual {
  color: blue;
  cursor: pointer;
  margin-left: -20px;
}

.show-more-btn-mutual {
  color: blue;
  cursor: pointer;
}

/* Media queries for tablets */
@media screen and (max-width: 1024px) {
  .cards-section-mutual {
    
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    margin-left: 30px;
    gap:26px;
    height: 100%;
  
  }
  /* .latest-matches-container-mutual{
    display: flex;
    flex-wrap: wrap;
  } */

  .heroine-card-latest-mutual{
    height:100%;
    display: flex;
    flex-wrap: wrap;
    
  }
}

/* Media queries for mobile phones */
@media screen and (max-width: 800px) {
  .cards-section-mutual {
    /* height: 100%;
    grid-template-columns: repeat(1, minmax(150px, 1fr)); */
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    /* width: 30%; */
    height: 60%;
  }
  .button-86-mutual {
    all: unset;
    width: 100px;
    height: 30px;
    font-size: 16px;
    background: transparent;
    border: none;
    position: relative;
    color: #f0f0f0;
    cursor: pointer;
    z-index: 1;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: bolder;
    color: red;
    margin-left: 23px;
  }
  
  .details-latest-mutual h2 {
    font-family: 'Righteous', sans-serif;
    color: rgba(255,255,255,0.98);
    text-transform: uppercase;
    font-size: 2.4rem;
    margin-left: -30px;
  }
  .page-title-latest-mutual h1 {
    position: relative;
    display: block;
    background: url(https://www.yachtandboat.com/wp-content/uploads/2018/12/hero-image-2.jpg)   -20px -20px no-repeat;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    display:block;
    font-size: 60px;
    font-family: arial;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
    background-size: cover;
  }
  
  .heroine-card-latest-mutual img {
    width: 80%;
    border-radius: 5px;
    height: 35vh;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  .heroine-card-latest-mutual{
    height:100%;
    width: 226px;
    margin-left: 30px;
    display: flex;
    flex-wrap: wrap;
  }
}




