@media only screen and (max-width: 360px){
    .notify_box{
        width: 360%;
        height: 800%;
        /* background-color: antiquewhite; */
       
    }
    .title{
        font-size: 25px;
        margin-bottom: 10%;

    }
    img{
        /* width: 25px; */
        width: 60px;
        margin-top: 10px;
        margin-left: 5px;
    }
    .user_activity{
        /* background-color: aqua; */
        width: 100%;
        height: 2%;
        font-size: 10px;
    }
    p{
        font-size: 10px;
        margin-bottom: 1%;
    }
    .time{
        /* background-color: antiquewhite; */
        margin-bottom: 2%;
    }
   
}