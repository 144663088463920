.page-title-return{
    margin-top: 90px;
}

.row-refund{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-lg-12-refund,
.col-md-12-refund,
.col-sm-12-refund {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width:576px) {
    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width:768px) {
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width:992px) {
    .col-lg-12-refund {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}




.auto-container-refund {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
 

}

.sec-title_refund{
    position: relative;
    margin-bottom: 30px;
  
}

.sec-title_refund .title_refund {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 1em;
    color: #ff8a01;
    font-weight: 500;
    background: rgb(247, 0, 104);
    background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
    background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
    background: linear-gradient(to left, rgba(247, 0, 104) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 15px;
    margin-top: 50px;
}


.title_refund{
    font-size: 4vh;
    color: #ec167f;
}

/* @media  only screen and (max-width: 160px){
    .page-title-return{
        margin-top: 90px;
    }
    
.sec-title_refund{
    margin-right: 70vh;
   
}
.title_refund{
    font-size: 3vh;
    color: #ec167f;
    margin-right: 8%;
}
.text_refund{
    width: 60%;
    margin-left: 4%;
    margin-top: 3%;
    color: black;
    font-size: 30%;
}

} */