header {
  background-color: #0c0606; 
  padding: 10px 20px;
}

.home-img img {
  width: 100%; 
  height: auto; 
}

.homepage-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }

  @media (max-width: 768px) {
    .homepage-container {
      padding: 10px;
    }
  
  }