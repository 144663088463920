.compatibility-details {
    margin: 0 auto;
    width: 80%; 
  }
  .compatibility-table {
    width: 100%;
    border-collapse: collapse;
  }
  .compatibility-table th,
  .compatibility-table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  .compatibility-table th {
    background-color: #f2f2f2;
  }
  .compatibility-table td {
    background-color: #ffffff;
    width:10px;
  }
  .compatibility-table th,
  .compatibility-table td h6 {
    margin: 0;
  }
  .compatibility-table th {
    text-align: center;
  }
  