.horoscope-container {
    width: 100%;
    background: #ffffff;
    padding: 1rem 0 2rem 0 ;
    margin-top: -2.5rem;
}

.title-of-horoscope {
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 1rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #05595B;
    text-transform: uppercase;
}

.horoscope-wraper {
    width: fit-content;
    margin: auto;
    padding: 3rem 5rem;
    box-shadow: 0 0.5rem 1rem lightgrey;
    border-radius: 0.35rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.horoscope-input-wraper {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    gap: 2rem 3rem;
}

.horoscope-input-element {
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
}

.inputs-select-tag {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid lightgrey;

    &:focus {
        border: 1px solid #E30B5C;
        outline: 1px solid #E30B5C;
        color: #E30B5C;
    }
}

.horoscope-warning-tag {
    font-size: small;
    color: #DE3163;
}
.theme-btn-profile.btn-profile.btn-style-one-profile.mt-4-profile {
    /* margin-left: 30%; */
    /* width: 99%; */
    margin-top: 1rem;
}

.horoscope-submit-button {
    padding: 0.5rem 3.5rem;
    border: 1px solid transparent;
    border: 1px solid #E30B5C;
    border-radius: 0.25rem;
    background: #E30B5C;
    color: #fff;
    transition: 0.35s all;
    }
    

@media screen and (width <=885px) {
    .horoscope-input-wraper {
        grid-template-columns: repeat(2, 300px);
        gap: 2rem 4rem;
    }

    .horoscope-wraper {
        padding: 2rem 1rem;
    }

}

@media screen and (width <=430px) {
    .horoscope-input-wraper {
        grid-template-columns: repeat(1, 300px);
        gap: 2rem;
    }

    .horoscope-wraper {
        padding: 2rem 1rem;
    }

}