
.auto-container-privacy {
    padding: 20px;
}


.page-title-privacy {
    margin-top: 90px;
    border-radius: 20px;

}




.bread-crumb-privacy {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.about-section_privacy {
    padding: 20px;
}

 /* .text_privacy {
    margin-top: 20px;
    
} */

.text_privacy {
  margin-top: 20px;
  max-height: none; 
  overflow: visible;
}

.title_privacy{
    color:rgb(99, 3, 43);
    font-size: 20px;
    font-weight: 700;
}
.moreText{
    display: none;
}
.read-more-btn{
    background-color: #ff0066;
    border-radius: none;
    outline: none;
    color:white;
    font-size: 30px;
    padding : 5px;
}

@media only screen and (max-width: 768px) {
  
    .page-title-privacy {
        margin-top: 90px;
    }

    .about-section_privacy {
        padding: 10px;
    }

    .text_privacy {
      max-height: 200px; /* Show only two paragraphs by default */
      overflow: hidden;
  }

  .read-more {
    display: block; /* Initially show 'Read more' button */
    text-align: center;
    color: blue;
    cursor: pointer;
    width: 40%;
    border: none;
    background-color: transparent;
}

.read-less {
    display: none; /* Initially hide 'Read less' button */
    text-align: center;
    color: blue;
    cursor: pointer;
    width: 40%;
}

} 
@media screen and(max-width: 468px) {
    
    
} 


/* Add this CSS to your Privacy.css file */

/* Default styling for text_privacy */
.text_privacy {
    max-height: 200px; /* Show only two paragraphs by default */
    overflow: hidden;
    
  }
  
  /* Style for 'Read more' button */
  .read-more {
    display: none; /* Initially show 'Read more' button */
    text-align: center;
    color: blue;
    cursor: pointer;
  }
  
  /* Style for 'Read less' button */
  .read-less {
    display: none; /* Initially hide 'Read less' button */
    text-align: center;
    color: blue;
    cursor: pointer;
    width:40%;
  }
  
  /* Media query for mobile devices */
  @media screen and (min-width: 320px) and (max-width: 767px) {
    /* Adjust max-height to show more text on smaller screens */
    .text_privacy {
      max-height: none; /* Show all text by default on mobile */
    }
  
    /* Hide 'Read more' button on mobile */
    .read-more {
      display: block;
      width: 40%;
      border: none;
      background-color: transparent;
      /* margin-left: 10px; */
    }
  
    /* Show 'Read less' button on mobile */
    .read-less {
      display: block;
      width: 50%;
    }
  }
  