.main-hobby{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  height: auto;
  margin: 50px auto;
  margin-bottom: 50px;
  padding: 10px;
  border: 1px solid #d2c4c4;
  background: #f3f3f3;
   /* background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 9%,
    rgb(131, 32, 101) 68%,
    rgb(182, 17, 138) 97%
  ); */
  backdrop-filter: blur(9px);
  border-radius: 20px;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.hobby-text-form{
  margin-left: -240px;
 
}

.hobby-text{
  display: flex;
  width: 85%;
  border: 1px solid #b2a6a6;
  margin-top: 15px;
  margin-left: 45px;
 border-radius: 10px;
 
}
.hobby-details {
    width: 100%;
  }
  
  .hobby-item {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 40px;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px; 
    /* margin: 10px; */
    padding: 10px;
  }
  
  .radio-group {
    display: flex;
    justify-content: space-around;
    gap: 50px;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    background-color: #95959551;
    margin-right: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    /* border: 0.1px solid #252525; */
  }
  
  .radio-btn[type="radio"] {
    margin: auto;
    padding: auto;
    accent-color: green;
  }
  
  .yes {
   accent-color: green;
  }
  
  .hobby-form-h2{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #05595B;
    text-transform: uppercase;
    text-align: center;
  }
  
  .input-box {
    margin-top: 10px;
    width: 40px;
  }
  .hobby-lable{
    justify-content: space-around;
    color: #282727;
  }
  .hobby-lable h10 {
    display: inline-block;
    width: 130px;
    margin-bottom: 10px;
    margin-left: 40px;
    font-weight: 500;
    font-size: 18px;
    color: #252525;
  }
  .hobby-lable input{
    background-color: #f3f3f3;
    padding: 10px;
    color: #282727;
  }

  .hobby-lable input:hover{
    border: 0.1px solid rgba(2, 0, 36, 1);
  }
  .hobby-btn{
    width: 40%;
    background-color: rgba(229, 35, 132, 0.884);
    color:white;
    height: 45px;
    font-size: 17px;
    font-weight: 550;
    margin-bottom: 10px;
    margin-top: 250px;
    margin-left: -270px;
    border-radius: 5px;
    transition: ease-out 0.2s;
  }
  .hobby-btn:hover{
    background-color: rgb(181, 7, 94);
    transform: scale(1.1, 1.1);
  }

    /* Media query for responsiveness */
  @media screen and (max-width: 835px) {
    .hobby-details{
    width: 100%;
    margin: 5px;
    margin-left: -0.1px;
    padding: 10px;
    }
    .main-hobby {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 90%;
      padding-right: 60px;
      /* margin-right: 20px; */
      /* margin-left: -5px; */
    }
  
    .hobby-text {
      display: flex;
      width: 80%;
      margin-left: 20px;
    }
  
    .radio-btn[type="radio"] {
      margin-left: 30px;
      margin-top: 20px;
      /* margin-right: 20px; */
      accent-color: green;
    }
    .hobby-btn {
      width: 80%;
      max-width: 250px;
      margin-left: -100px;
    }
    .hobby-text-form{
      margin-left: -220px;
      margin-top: 20px;
    }
    .radio-group {
      width: 80%;
      /* margin-left: 6px; */
    }
    
  }


  @media screen and (max-width: 430px) {
    .hobby-details{
    width: 100%;
    height: auto;
    margin: 5px;
    margin-left: -0.1px;
    padding: 10px;
    }
    .main-hobby {
      display: grid;
      grid-template-columns: 1fr;
      width: 90%;
      height: auto;
      padding-right: 60px;
      /* margin-right: 20px; */
      /* margin-left: -5px; */
      
    }
  
    .hobby-text {
      display: flex;
      width: 80%;
      margin-left: 20px;
    }
  
    .radio-btn[type="radio"] {
      margin-left: 30px;
      margin-top: 20px;
      /* margin-right: 20px; */
      accent-color: green;
    }
    .hobby-btn {
      width: 50%;
      max-width: 250px;
      margin-left: 100px;
      margin-top: 10px;
    }
    .hobby-text-form{
      margin-left: -220px;
      margin-top: 20px;
    }
    .radio-group {
      width: 80%;
      /* margin-left: 6px; */
    }
    
  }
  





