
.radiocss-compatability[type=checkbox],
.radiocss-compatability[type=radio] {
    box-sizing: border-box;
    padding: 0;
    height: 18px;
    width: 18px;
    margin-top: 5px;
}

.row-compatability {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
}

.text-compatability .content-compatability{
    color: #ec167f;
    font-size: 15px;
    font-weight: 500;
}

.text1-compatability .content-compatability{
    color: #ec167f;
    font-size: 15px;
    font-weight: 500;
}
.col-md-4-compatability{
width: 32%;

}
.col-lg-12-compatability,
.col-md-12-compatability,
.col-sm-12-compatability{
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
}


.btn-compatability:focus,
.btn-compatability:hover {
    text-decoration: none;
}

.btn-compatability:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.btn-compatability:disabled {
    opacity: .65;
}

.btn-compatability:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn-compatability:not(:disabled):not(.disabled):active {
    background-image: none;
}

.mt-4-compatability  {
    margin-top: 1.5rem !important;
}

.mb-4-compatability  {
    margin-bottom: 1.5rem !important;
}
.text-compatability{
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    padding: 10px;
    margin: 10px;
    
}

.text-compatability .i1-compatability{
    position: relative;
    font-size: 15px;
    font-weight: 700;
    color: rgb(6, 10, 10);
}

.text1-compatability .i1-compatability{
    position: relative;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
}
.text1-compatability{
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    padding: 10px;
    min-height: 250px;
}

.auto-container-compatability-one {
    max-width: 1200px;
    margin: 0 auto;
}
.schedule-timeline-compatability {
    position: relative;
}
.page-title-compatability{
  
    text-align: center;
   color:#ec167f;
   
}
/* --------------------------------------------------------responsive css------------------------------------------ */
@media (max-width:937px){
    .row-compatability{
      gap: 30px;
    }
   
    .col-md-4-compatability{
    width:46.5%;
    }
}
/* -------------------------------------------------- */
@media (max-width:702px) {
    .text-compatability .content-compatability{
       font-size:12px;
       font-weight: 500;
    }
    
    .text1-compatability .content-compatability{
        font-size:12px;
        font-weight: 500;
    }
    .i1-compatability{
        font-size: 13px !important;
        font-weight: 500;
    }
    .radiocss-compatability[type=checkbox],
.radiocss-compatability[type=radio]{
    height: 12px;
    width: 12px;
}
}
/* --------------------------------------- */
@media (max-width:601px){
    .row-compatability{
        gap:5px;
    }
    .col-md-4-compatability{
        width:95%;
        height:230px;
        margin: auto;
    }
    .text-compatability .content-compatability{
        font-size:11px;
        font-weight: 500;
     }
     
     .text1-compatability .content-compatability{
         font-size:11.8px;
         font-weight: 600;
     }
    .i1-compatability{
        font-size: 16px !important;
        font-weight: 500;
    }
    .radiocss-compatability[type=checkbox],
    .radiocss-compatability[type=radio]{
        height: 15px;
        width: 15px;
    }
}
@media (max-width:461px) {
    .title-compatability{
        font-size:25px;
    }
    .col-md-4-compatability{
        width:90%;
        margin: auto;
        margin-left: 25px !important;
    }
    .text-compatability .content-compatability{
        font-size:10px;
        font-weight: 500;
     }
     
     .text1-compatability .content-compatability{
         font-size:10px;
         font-weight: 500;
     }
    .i1-compatability{
        font-size: 16px !important;
        font-weight: 500;
    }
}