/* Default styles */
.page-title-disclamier {
  margin: 35px 0px;
  padding: 10px;
}
.auto-container_disclamier{
    padding: 30px;
}
.title_disclamier{
  color: rgb(56, 2, 38);
  font-size: 20px;
  font-weight: 700;
}

.about-section_disclaimer{
  /* height: 800px; */
  margin-top: 100px;
}

/* Styles for screens with a maximum width of 768px (smallest tablets and phones) */
@media (max-width: 768px) {
    .page-title-disclamier {
      margin: 30px 0px;
      padding: 10px;
    }
  
    .auto-container_disclamier {
      margin: 10px;
      padding: 10px; 
    }
  
    .text_disclamier {
      padding: 10px; 
    }
  
    
  }
  