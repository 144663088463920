.label-Fam {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  color: #d6d4d4;
}
.page-title1{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #05595B;
  text-align: center;
  text-transform: uppercase;
}
.auto-container-Fam5{
  padding: 3rem;
  width: 85%;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.custom-dropdown-mot {
  position: relative;
  width: 100%;
  font-size: 1rem;
}

.selected-option-mot {
  padding: 0.5rem 0.75rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  /* width: 700px; */
}

.selected-option-mot:after {
  content: "▼";
  font-size: 0.8rem;
  color: #888;
  margin-left: 0.5rem;
}

.selected-option-mot:hover {
  border-color: #888;
}

.dropdown-menu-mot {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(246, 242, 242, 0.1);
}

.custom-select-box-moth {
  width: calc(100% - 1.5rem);
  border: none;
  border-bottom: 1px solid #727171;
  outline: none;
}

.custom-select-box-moth:focus {
  border-bottom-color: #6e6d6d;
}

.dropdown-options-mot {
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-option-mot {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-option-mot:hover {
  background-color: #f0f0f0;
}

.dropdown-option-mot:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.dropdown-option-mot:active {
  background-color: #e0e0e0;
}

span[style] {
  display: block;
  margin-top: 0.25rem;
}

@media (max-width: 1200px) {
  .selected-option-mot {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .selected-option-mot {
    padding: 0.4rem 0.65rem;
  }

  .custom-select-box-moth {
    padding: 0.4rem 0.65rem;
  }
}

@media (max-width: 768px) {
  .selected-option-mot {
    font-size: 0.8rem;
    padding: 0.3rem 0.55rem;
  }

  .custom-select-box-moth {
    padding: 0.3rem 0.55rem;
  }
}

@media (max-width: 576px) {
  .selected-option-mot {
    font-size: 0.7rem;
    padding: 0.25rem 0.5rem;
    width: 98%;
  }

  .custom-select-box-moth {
    padding: 0.25rem 0.5rem;
  }
  .dropdown-menu-mot {
    width: 300px;
    margin-left: -57px;
  }
}
.radio-btn-fam[type="radio"]{
  color:black;
}

@media (max-width: 768px) {
  .custom-search-input {
    margin-right: -50px;
    width: 100%;
  }
}

.label-Fam {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: black;
}

.text-Fam {
  color: black;
  margin-left: -10px;
}

button,
.agile-ltext-Fam,
.custom-select-box-Fam,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
.agile-ltext-Fam {
  overflow: visible;
}

button,
.custom-select-box-Fam {
  text-transform: none;

}
.radio-btn {
  color: black;
}

[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

.agile-ltext-Fam[type="checkbox"],
.agile-ltext-Fam[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  height: 20px;
  width: 20px;
}

.row-Fam {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-evenly;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 1px auto;
}

.col-lg-10-Fam,
.col-lg-12-Fam,
.col-lg-3-Fam,
.col-lg-5,
.col-lg-9-Fam,
.col-md-10-Fam,
.col-md-12-Fam,
.col-md-5,
.col-sm-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.form-group-Fam {
  margin-bottom: 1rem;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.btn-Fam {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn-Fam {
    transition: none;
  }
}

.btn-Fam:focus,
.btn-Fam:hover {
  text-decoration: none;
}

.btn-Fam:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-Fam:disabled {
  opacity: 0.65;
}

.btn-Fam:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-Fam:not(:disabled):not(.disabled):active {
  background-image: none;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ec167f;
}

.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-group {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.custom-select-box-Fam {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select-box-Fam:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 5px rgba(128, 189, 255, 0.5);
}

.custom-select-box-Fam:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

/* .clearfix-Fam::after {
  display: block;
  clear: both;
  content: "";
} */

.ml-1 {
  margin-left: 0.25rem !important;
}

.mt-2-Fam {
  margin-top: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4-Fam {
  margin-top: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

button.theme-btn-Fam,
.btn-Fam,
.btn-style-one-Fam,
.mt-4-Fam {
  width: 25%;
  text-align: center;
}

.dropdown-toggle::after {
  display: none;
}

.contact-page-section-Fam {
  position: relative;
  margin: 1px auto;
  /* padding: 110px 0 70px; */
  /* margin-right: 40px;
  margin-left: 40px;
  margin-bottom: 25px; */
}

.contact-page-section-Fam .form-column-Fam {
  position: relative;
  margin-bottom: 40px;
}

.contact-page-section-Fam .form-column-Fam .inner-column-Fam {
  position: relative;
}

.contact-page-section .sec-title {
  margin-bottom: 30px;
}

.contact-page-section-Fam .sec-title h2 {
  font-size: 32px;
}

.contact-form-Fam .form-group-Fam {
  position: relative;
  margin-bottom: 30px;
}

.contact-form-Fam .form-group-Fam .agile-ltext-Fam,
.contact-form-Fam .form-group-Fam .agile-ltextarea-Fam,
.contact-form-Fam .form-group-Fam .custom-select-box-Fam {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  color: #312d2dba;
  line-height: 32px;
  padding: 0px 13px;
  border: 1px solid #a1a7a1;
  font-weight: 400;
  height: 50px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 3px;
}

.contact-form-Fam .form-group-Fam .agile-ltext-Fam:focus,
.contact-form-Fam .form-group-Fam .custom-select-box-Fam:focus,
.contact-form-Fam .form-group-Fam textarea:focus {
  border-color: #f20487;
}

.contact-form-Fam .form-group-Fam textarea {
  height: 75px;
  resize: none;
}

.contact-form-Fam .form-group-Fam button {
  text-transform: capitalize;
}

.contact-page-section-Fam .contact-column {
  position: relative;
  margin-bottom: 30px;
}

.contact-page-section-Fam .contact-info {
  position: relative;
  margin-bottom: 37px;
}

.contact-page-section-Fam .contact-info li {
  position: relative;
  padding-left: 55px;
  margin-bottom: 30px;
}

.contact-page-section-Fam .contact-info li p {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #777777;
  font-weight: 400;
  margin: 0;
}

.contact-page-section-Fam .contact-info li strong {
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: #222222;
  font-weight: 600;
}

.contact-page-section-Fam .contact-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
  line-height: 40px;
  background-color: #1c94d2;
  color: #ffffff;
}

.newsletter-section-Fam {
  position: relative;
  padding: 10px 0;
  z-index: 1;
}
/*! CSS Used from: https://www.matrimonysoftware.in/css/stylenew.css */
.theme-btn-Fam {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.theme-btn-Fam {
  position: relative;
  transition: all 300ms ease;
}

/*! CSS Used from: https://www.matrimonysoftware.in/css/regcss.css */
.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 16.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.agile-ltext::placeholder {
  color: #6c757d;
  opacity: 1;
}

/*! CSS Used from: https://www.matrimonysoftware.in/css/stylenew.css ; media=all */
.theme-btn-Fam {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.theme-btn-Fam {
  position: relative;
  transition: all 300ms ease;
}

/*! CSS Used from: Embedded */
.abtx {
  margin-left: -11px;
}

/*! CSS Used from: Embedded */
.custom-select-Fam {
  height: calc(2.25rem + 14px);
  color: #312d2dba;
  padding: 0.375rem 0.75rem 0.375rem 1.1rem;
  text-align: left;
  border-color: #a1a7a1;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: white;
}

.custom-select-box-Fam:focus {
  border-color: #ec167f;
  outline: 0;
  box-shadow: none;
}

.form-check-label {
  color: #312d2dba;
}

.btn-group {
  width: 720px;
}
/*! CSS Used from: https://www.matrimonysoftware.in/css/stylenew.css */
.theme-btn-Fam {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.theme-btn-Fam {
  position: relative;
  transition: all 300ms ease;
}

/*! CSS Used from: https://www.matrimonysoftware.in/css/googletrancss.css */
.agile-ltext-Fam,
.custom-select-box-Fam {
  padding: 5px 20px;
  outline: none;
  border: 1px solid #acacac;
  margin-bottom: 5px;
}

@media (min-width: 576px) {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-10-Fam {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-3-Fam {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-9-Fam {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10-Fam {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-12-Fam {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    
  }
}
@media (max-width: 821px) {
  /* .row-Fam {
    margin-right: -54px;
    margin-left: -109px;
  } */

  /* .contact-page-section-Fam {
    padding: 21px 99px 3px 212px;
  } */
  .newsletter-section-Fam {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* margin-left: -10px; */
  }
  .contact-form-Fam .form-group-Fam input.agile-ltext-Fam,
  .contact-form-Fam .form-group-Fam .agile-ltext-Fam,
  .contact-form-Fam .form-group-Fam select.custom-select-box-Fam {
    width: 98%;
  }
  .radio-btn {
    margin-left: 20px;
  }

  .contact-form-Fam .label-Fam {
    display: flex;
  }

  .label-Fam {
    margin-left: 5px;
    margin-bottom: 2px;
  }

  button.theme-btn-Fam,
  .btn-Fam,
  .btn-style-one-Fam,
  .mt-4-Fam {
    width: 55%;
    text-align: center;
    margin-left: 10px;
    margin-top: 20px;
  }
  .btn-group {
    width: 282px;
  }

  .alertmes-Fam {
    margin-right: -136px;
    margin-left: -188px;
  }

  .sub_btn-Fam {
    margin-top: -20px;
    margin-left: 30px;
    width: 250px;
  }
}

@media screen and (max-width: 568px) {
  /* .contact-page-section-Fam {
    padding: 21px 99px 3px 246px;
  } */
  /* .row-Fam {
    margin-right: -54px;
    margin-left: -109px;
  } */
  .btn-group {
    width: 282px;
  }
  .text-Fam {
    color: black;
    margin-bottom: 20px;
  }
  .contact-form-Fam .form-group-Fam .agile-ltext-Fam,
  .contact-form-Fam .form-group-Fam .agile-ltextarea-Fam,
  .contact-form-Fam .form-group-Fam .custom-select-box-Fam {
    position: relative;
    display: block;
    width: 98%;
  }
  /* .radio-btn-fam[type="radio"] {
    margin-left: -50px;
  } */
  .auto-container-Fam5 {
    padding: .5rem;
    width: 95%;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .alertmes {
    margin-right: -136px;
    margin-left: -188px;
  }

  .sub_btn-Fam {
    margin-top: -20px;
    margin-left: 30px;
    width: 250px;
  }
}

@media screen and (max-width: 1100px) and (min-width: 825px) {
  .contact-form-Fam .form-group-Fam .agile-ltext-Fam,
  .contact-form-Fam .form-group-Fam .agile-ltextarea-Fam,
  .contact-form-Fam .form-group-Fam .custom-select-box-Fam {
    width: 700px;
  }

  button.theme-btn-Fam.btn-Fam.btn-style-one-Fam.mt-4-Fam {
    margin-left: 250px;
  }
}


