.chatbox-container {
  background-color: white;
  margin-top: -50px;
}

.chat-container-1 {
  padding: 20px;
  height: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid lightgrey;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.search-container img {
  margin-right: 5px;
}

.heading-container {
  display: flex;
  justify-content: center;
  background-color: rgb(12, 10, 7);
}

.heading-1 {
  cursor: pointer;
  padding: 10px;
  transition: border-bottom 0.3s ease;
  color: #f3f0f0;
  font-size: 20px;
  margin-right: 30px;
}

.heading-1:hover {
  
  border-bottom: 5px solid rgb(221, 132, 148);
}

.side-bar {
  display: flex;
  margin-top: 20px;
  background-color: rgb(219, 218, 218);
  padding-left: 15px;
}

.sidebar-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 2px solid #ccc;
  margin-right: 10px; 
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar-item:first-child {
  border-radius: 5px 0 0 5px; 
}

.sidebar-item:last-child {
  border-radius: 0 5px 5px 0; 
}

.sidebar-item:hover {
  background-color: pink;
  color: white;
}

.conversations {
  margin-top: 20px;
}

.conversations h2 {
  margin-bottom: 10px;
  font-size: 25px;
}

.conversation-img{
  width: 200px;
  height: 200px;
 margin-left: 500px;
 margin-top: 110px;
}

.incoming{
  margin-left: 550px;
  font-weight: bold;
  margin-top: -10px;
}

.till-now{
  margin-left: 510px;
}
 .or-or{
  margin-left: 510px;
  font-weight: bold;
  margin-top: -15px;
 }

 .responses{
  margin-left: 490px;
  font-weight: bold;
  margin-top: -15px;
 }

 .explore-matches {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 5px;
  cursor: pointer;
  width: 150px;
  margin-left: 530px;
  background-color: white;
  border-radius: 17px;
  color: orange;
 border-color: orange;
}

.explore-matches:hover {
  background-color: pink;
  color: white;
}





.search-modal {
  position: absolute;
  right: 0;
  width: 300px;
  height: 400px;
  background-color: white;
  border: 1px solid lightgrey;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 30px;
  margin-right: 30px;
}

.search-modal h2{
  font-size: 15px;
  color: grey;
  margin-bottom: 20px;
}

.modal-divider {
  border-top: 3px solid lightgrey;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modal-image {
  height: 200px;
  margin-left: 30px;
}

.search-modal p{
  font-weight: bold;
}

.modal-p{
  margin-left: 60px;
  margin-top: -18px;
}

.shown-here{
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-top: -16px;
}

.responses-1{
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-top: -16px;
}

.till-now-1{
  margin-left: 510px;
  margin-bottom: 20px;
}

.explore-matches-1 {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 5px;
  cursor: pointer;
  width: 150px;
  margin-left: 550px;
  background-color: white;
  border-radius: 17px;
  color: orange;
 border-color: orange;
}

.explore-matches-1:hover {
  background-color: pink;
  color: white;
}

.contact-you{
  margin-left: 470px;
  margin-top: 10px;
}

.convo-matches{
  margin-left: 520px;
  font-weight: bold;
  margin-top: -19px;
}

.explore-matches-2 {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 5px;
  cursor: pointer;
  width: 150px;
  margin-left: 560px;
  background-color: white;
  border-radius: 17px;
  color: orange;
 border-color: orange;
}

.explore-matches-2:hover {
  background-color: pink;
  color: white;
}


.side-bar-1 {
  display: flex;
  background-color: rgb(219, 218, 218);
  padding-left: 15px;
  margin-bottom: 20px;
}

.sidebar-item-1 {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 2px solid #ccc;
  margin-right: 10px; 
  margin-top: 10px;
  margin-bottom: 10px;
  width: 150px;
  background-color: rgb(219, 218, 218);
}

.sidebar-item-1:first-child {
  border-radius: 5px 0 0 5px; 
}

.sidebar-item-1:last-child {
  border-radius: 0 5px 5px 0; 
}

.sidebar-item-1:hover {
  background-color: pink;
  color: white;
}

.comeback{
  margin-left: 510px;
  font-weight: bold;
  margin-top: -15px;
}

.top-bar{
  display: flex;
  justify-content: flex-end;
}

.privacy-settings{
  margin-top: -30px;
  padding-right: 5px;
}

.settings-icon{
  margin-top: -25px;
}


@media (max-width: 600px) {
  .chat-container-1 {
    height: auto;
    width: 90%; 
    margin-left: 20px;
  }

  .conversation-img {
    margin-left: 60px; 
    margin-top: 20px; 
  }

  .incoming,
  .till-now,
  .or-or,
  .responses,
  .comeback,
  .till-now-1,
  .contact-you,
  .convo-matches {
    margin-left: auto; 
    margin-right: auto; 
    text-align: center; 
    margin-top: 10px; 
    margin-bottom: 10px; 
  }


  .explore-matches{
    margin-left: 90px;
    margin-top: 10px; 
    margin-bottom: 10px;
  }

  .explore-matches-1{
    margin-left: 90px;
    margin-top: 10px; 
    margin-bottom: 10px;
  }

  .explore-matches-2{
    margin-left: 90px;
    margin-top: 10px; 
    margin-bottom: 10px;
  }

  .search-modal {
    right: 50%; 
    transform: translateX(50%);
    width: 80%; 
    margin-right: 0; 
    margin-top: 50px; 
  }


}
