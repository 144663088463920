/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f9fafb;
  color: #333;
}

.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f9fafb;
}

.main_wrapper {
  text-align: center;
}

.main {
  display: inline-block;
  position: relative;
}

.antenna {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 60px;
}

.antenna_shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.a1, .a2 {
  position: absolute;
  width: 8px;
  height: 20px;
  background: #333;
  border-radius: 5px;
}

.a1 { top: 0; left: 12px; }
.a2 { top: 0; left: 30px; }

.a1d, .a2d {
  position: absolute;
  width: 4px;
  height: 10px;
  background: #333;
  border-radius: 5px;
}

.a1d { top: 20px; left: 10px; }
.a2d { top: 20px; left: 28px; }

.a_base {
  position: absolute;
  width: 60px;
  height: 6px;
  background: #333;
  border-radius: 5px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tv {
  position: relative;
  width: 250px;
  height: 200px;
  background: #333;
  border-radius: 10px;
  overflow: hidden;
}

.curve_svg {
  width: 100%;
  height: auto;
  display: block;
  fill: none;
  stroke: white;
  stroke-width: 2;
}

.display_div {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 210px;
  height: 140px;
  background: #000;
  border-radius: 5px;
  overflow: hidden;
}

.screen {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notfound_text {
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
}

.lines {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
}

.line1, .line2, .line3 {
  height: 5px;
  background: #666;
  margin-bottom: 5px;
}

.buttons_div {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  display: flex;
  justify-content: space-between;
}

.b1, .b2 {
  width: 12px;
  height: 12px;
  background: #333;
  border-radius: 50%;
}

.speakers {
  display: flex;
  justify-content: center;
  align-items: center;
}

.g1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40px;
  margin-right: 5px;
}

.g11, .g12, .g13 {
  width: 8px;
  height: 8px;
  background: #666;
  border-radius: 50%;
}

.g {
  width: 10px;
  height: 10px;
  background: #666;
  border-radius: 50%;
  margin: 0 5px;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
}

.base1, .base2, .base3 {
  width: 50px;
  height: 6px;
  background: #333;
  margin: 3px auto;
  border-radius: 5px;
}

/* Error Message Section */
.bg-white {
  background-color: #fff;
}

.dark\:bg-gray-900 {
  background-color: #1a202c;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.text-sm {
  font-size: 0.875rem;
}

.text-blue-500 {
  color: #3b82f6;
}

.dark\:text-blue-400 {
  color: #60a5fa;
}

.text-gray-800 {
  color: #1f2937;
}

.dark\:text-black {
  color: #000;
}

.md\:text-3xl {
  font-size: 1.875rem;
}

.text-gray-500 {
  color: #6b7280;
}

.dark\:text-gray-400 {
  color: #9ca3af;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.min-h-screen {
  min-height: 100vh;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.text-2xl {
  font-size: 1.5rem;
}

.font-semibold {
  font-weight: 600;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.transition-colors {
  transition: color 0.2s;
}

.duration-200 {
  transition-duration: 200ms;
}

.bg-white {
  background-color: #fff;
}

.border {
  border: 1px solid #d1d5db;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.text-gray-700 {
  color: #374151;
}

.hover\:bg-gray-100:hover {
  background-color: #f3f4f6;
}

.dark\:bg-gray-900 {
  background-color: #1f2937;
}

.dark\:hover\:bg-gray-800:hover {
  background-color: #4b5563;
}

.dark\:text-gray-200 {
  color: #e5e7eb;
}

.dark\:border-gray-700 {
  border-color: #374151;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.text-white {
  color: #fff;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.hover\:bg-blue-600:hover {
  background-color: #2563eb;
}

.dark\:hover\:bg-blue-500:hover {
  background-color: #2563eb;
}

.dark\:bg-blue-600 {
  background-color: #2563eb;
}

@media (max-width: 768px) {
  .tv {
      width: 200px;
      height: 160px;
  }

  .display_div {
      width: 180px;
      height: 120px;
  }

  .buttons_div {
      width: 60px;
  }

  .text_4041, .text_4042, .text_4043 {
      font-size: 3rem;
  }
}

@media (max-width: 480px) {
  .tv {
      width: 160px;
      height: 130px;
  }

  .display_div {
      width: 140px;
      height: 100px;
  }

  .buttons_div {
      width: 50px;
  }

  .text_4041, .text_4042, .text_4043 {
      font-size: 2.5rem;
  }

  .text_404 {
      font-size: 1.5rem;
  }
}
/* STYLESH CHNGES 700 LINE TO 397 */