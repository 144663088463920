.text-forgot{
    text-align: center;
    margin-bottom: 30px;
}
.forgot-title{
    color:white;

}

.forgot-email{
    margin-right: -90px;
}

.forgot-input{
    margin-bottom: 30px;
    border: 1px  solid #ddd;
}
button.theme-btn-forgot.btn-forgot.btn-style-one-forgot.mb-3-forgot{
    width:300px;
    color:white;
}


@media (max-width:480px){
   
    .forgot-input{
        margin-bottom: 30px;
        border: 1px  solid #ddd;
        margin-left: -50px;
    }

    .forgot-email{
        margin-left: -90px;
        margin-right:0px;
    }
    button.theme-btn-forgot.btn-forgot.btn-style-one-forgot.mb-3-forgot{
        width:100px;
        color:white;
    }
}