.blog-details-container {
  margin-top: 90px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: lightblue;
}

.blog-details-card {
  display: flex;
  flex-direction: column;
  width: calc(33.33% - 20px); 
  max-width: 350px; 
  border: 1px solid #ccc;
  border-radius: 15px; 
  padding: 20px; 
  background-color: gainsboro;
  box-shadow: 10px 10px 10px rgba(180, 3, 71, 0.3); 
  height: 550px;
  transition: all 0.3s ease; 
}

.blog-details-card:hover {
  background-color: darkorange;
  transform: translateY(-5px); 
  box-shadow: 0 8px 20px rgba(230, 18, 18, 0.5); 
}


.blog-details-title {
  font-size: 1.5rem; 
  margin: 0;
  margin-bottom: 10px; 
  display: flex;
  justify-content: flex-start;
  border: 1px ;
  border-radius: 20px;
  padding-left: 10px;
  margin-left: -5px;
  width: 50%;
  background-color: rgb(160, 160, 34);
  color: #fff;
  padding-bottom: 10px;
}

.blog-details-date {
  font-size: 1.3rem; 
  color: black;
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin-top: -5px;
  padding-bottom: 3px;
}

.blog-details-description {
  margin: 15px 0; 
  color: black;
  font-size: 1.2rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-top: -10px;
  padding-bottom: 2px;
}

.blog-details-description-p{
  margin-top: -13px;
  color: black;
}

.blog-details-author {
  margin-top: auto;
  margin-bottom: 15px; 
}

.author-image{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.author-name{
  margin-top: -50px;
  color: black;
  font-weight: bold;
  margin-left: 60px;
}

.author-email{
  margin-top: -15px;
  color: black;
  margin-left: 60px;
}




.blog-details-loader {
  display: flex;
  flex-wrap: wrap;
}

.blog-details-card {
  width: 350px;
  margin: 10px;
  margin-left: 50px;
  margin-top: 100px;
}

.skeleton-cards-container {
  display: flex;
  justify-content: space-between;
}



.view-more-link{
  background-color: rgb(61, 61, 190);
  border-radius: 5px;
}

.view-more-link:hover{
  color: deepskyblue;
}

.blog-view-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.blog-post-details {
  max-width: 600px; 
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: darkseagreen;
}

.blog-post-details:hover{
  background-color: rgb(107, 107, 167);
}

.blog-post-details h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.blog-post-details p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.view-author{
  margin-left: 60px;
}

.view-email{
  margin-left: 60px;
}

.author-image-view{
  margin-bottom: -60px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}



/* Media Query for Mobile Phones */
@media only screen and (max-width: 600px) {
  .blog-details-card {
    width: calc(100% - 20px); 
    margin: 10px 0;
    margin-top: 90px;
    margin-left: 20px;
  }

  .skeleton-cards-container {
    flex-direction: column;
    align-items: center;
  }

  .skeleton-card {
    width: calc(100% - 20px); 
    margin: 10px;
  }
}


