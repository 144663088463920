.habits-form-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  margin: 20px auto;
  height: 750px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  background: #f3f3f3;
  /* background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 4%,
    rgb(131, 32, 101) 68%,
    rgb(182, 17, 138) 97%
  ); */
  backdrop-filter: blur(9px);
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container-habits{
  margin-left: 20px;
  margin-top: -50px;
}

.smoking-form{
  border: 1px solid #bfbfbf;
  width: 350px;
  height: 60px;
  padding-left: 10px;
  margin: 10px;
  border-radius: 15px;
  background-color: #81808046;
  margin-left: 80px;
  transition: ease-in 0.2s;
}

.smoking-form:hover{
  transform: scale(1, 1.07);
  translate: 2px;
  border: 2px solid #959494;
}

.habits {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #05595B;
  text-transform: uppercase;
}

.habit4 {
  margin-bottom: -15px;
  margin-left:15px;
  color: #414141; 
  font-weight: bold;
  font-size: 21px;
  margin-top: 30px;
}

.habit-label-doc {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-left: 10px;
  padding: 15px;
  font-weight: bold;
}

.s1 {
  color: #212121;
  width: 200px;
  margin-right: 50px;
  font-size: 20px;
  margin-left: 60px;
  text-decoration: none;
}

.s2{
  margin-left: 32%;
  margin-right: 50px;
  font-size: 20px;
  margin-left: 97px;
}

input[type="radio"] {
  margin-right: 30px;
  margin-top: 10px;
  accent-color: green;
}
.radio-btn{
  display: flex;
  margin-bottom: -35px;
}

.habit-button {
  width: 55%;
  height: 50px;
  margin-top: 320px;
  margin-left: -330px;
  padding: 10px 20px;
  background-color: rgba(229, 35, 132, 0.884);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: block;
  /* margin: 35px auto; */
}

.habit-button:hover{
  background-color: rgb(246, 85, 109);
}

.footer-container {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

#hobbyD{
   margin-top: 100px;
}

/* Media Query for smaller screens */
@media (max-width: 835px) {
  .habits-form-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    height: auto;
    margin: auto;
    margin-bottom: 50px;
    padding: 10px;
  }
  
  .container-habits {
    margin-left: 5%;
    margin-top: -50px;
    margin-bottom: 10px;
  }
  
  .smoking-form {
    width: 300px;
    /* max-width: 70%; */
    padding-top: 1px;
    margin: 10px auto;
  }
  
  
  .habit-label-doc {
    display: flex;
    width: 300px;
    margin: auto;    
  }

  .habit-button {
    width: 40%;
    margin-top: 300px;
    margin-left: -15%;
    margin-bottom: 30px;
  }
  
}

@media (max-width: 430px) {
  .habits-form-container {
    display: grid;
    grid-template-columns: 1fr;
    width: 90%;
    margin: auto;
    margin-bottom: 50px;
    padding: 2px;
  }
  
  .container-habits {
    margin-left: 5%;
    margin-top: -50px;
    margin-bottom: 10px;
  }
  
  .smoking-form {
    width: 90%;
    max-width: 90%;
    padding-top: 1px;
    margin: 9px auto;
  }
  
  .habit-label-doc {
    display: flex;
  justify-content: center;
  width: auto;
  }

  .radio-btn{
    padding: 20px;
  }
  
  .habit-button {
    width: 40%;
    margin: 20px auto;
  }

  .s1{
    margin: auto;
    font-size: 15px;
  }

  .habits{
    font-size: 20px;
  }
  
}

