.profile-heading p{
  position: relative;
  display: block;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding-top: 1.5rem;
  font-size: 45px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #05595B;
  text-align: center;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 150px;
}

.liked-profile-container {
    max-width: 600px;
    margin: 85px auto;
    padding: 20px;
    background-color: #f3f3f3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    border-radius: 20px;
  }
  
  .liked-profile-heading {
    text-align: center;
    margin-bottom: 70px;
    display: flex;
    justify-content: center;
    color: rgb(225, 7, 138);
    background: whitesmoke;
    font-family: sans-serif;
    font-size: 42px;
    padding-top: 10px;
  }
  
  .liked-profile-input-container-view {
    display: flex;
    margin: 50px;
    flex-direction: column;
    margin-bottom: 50px;
  }
  
  .liked-profile-input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #232323;
    background-color: rgb(247, 218, 240);
  }

  .liked-profile-input:hover{
    border: 1.5px solid rgb(225, 7, 138);
  }
  
  @media screen and (min-width: 768px) {
    .liked-profile-container {
      max-width: 800px;
    }
  
    .liked-profile-input-container-view {
      flex-direction: row;
    }
  
    .liked-profile-input {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

   @media screen and (max-width: 600px) {
    .input-container-view {
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      width: 320px;
      align-items: center;
    }

  
    input.liked-profile-input {
      flex: 1;
      margin-bottom: 0;
      font-size: 12px;
      padding-left: 10px;
      /* margin-right: -20px;      */
    }
  
    .liked-profile-input:last-child {
      margin-right: 0;
    }
  
    .liked-profile-heading {
      font-size: 20px;
    }   
  }
