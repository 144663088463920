.rowfea {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-lg-4fea,
.col-md-4fea,
.col-sm-4fea {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.inner-boxfea .title-feature {
  font-size: 40px;
  font-weight: 700;
  background: url("https://www.shutterstock.com/image-vector/orange-blue-pink-gradient-color-260nw-787735393.jpg");
  background-clip: text;
  color: transparent;
}
@media (min-width: 576px) {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg-4fea {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
.textfea {
  position: relative;
  font-size: 20px;
  line-height: 26px;
  color: #888888;
  font-weight: 600;
}
.auto-containerfea {
  position: static;
  max-width: 100vw;
  padding: 0px 15px;
  margin: 0 auto;
}
.sec-titlefea {
  position: relative;
  margin-bottom: 30px;
}
.sec-titlefea .titlefea {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 1em;
  color: #ff8a01;
  font-weight: 500;
  background: rgb(247, 0, 104);
  background: -moz-linear-gradient(
    to left,
    rgba(247, 0, 104, 1) 0%,
    rgba(68, 16, 102, 1) 25%,
    rgba(247, 0, 104, 1) 75%,
    rgba(68, 16, 102, 1) 100%
  );
  background: -webkit-linear-gradient(
    to left,
    rgba(247, 0, 104, 1) 0%,
    rgba(68, 16, 102, 1) 25%,
    rgba(247, 0, 104, 1) 75%,
    rgba(68, 16, 102, 1) 100%
  );
  background: linear-gradient(
    to left,
    rgba(247, 0, 104) 0%,
    rgba(68, 16, 102, 1) 25%,
    rgba(247, 0, 104, 1) 75%,
    rgba(68, 16, 102, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 15px;
}
.sec-titlefea h2 {
  position: relative;
  display: inline-block;
  font-size: 48px;
  line-height: 1.2em;
  color: #1e1f36;
  font-weight: 700;
}
.features-section-twofea {
  position: relative;
  padding: 120px 0 15px;
  margin-top: -90px;
  margin-bottom: 40px;
}
.features-section-twofea .title-blockfea {
  position: relative;
  margin-bottom: 40px;
}
.features-section-twofea.title-blockfea .inner-boxfea {
  padding: 50px 30px 0;
  background-color: #ffffff;
}
.features-section-twofea .title-blockfea .sec-titlefea {
  margin-bottom: 0;
}
.features-section-twofea .title-blockfea .sec-titlefea .title {
  margin-bottom: 5px;
}
.feature-block-twofea {
  position: relative;
  margin-bottom: 40px;
}
.feature-block-twofea .inner-boxfea {
  position: relative;
  padding: 40px 30px;
  padding-left: 100px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  overflow: hidden;
}
.feature-block-twofea .inner-boxfea:hover {
  box-shadow: none;
}
.feature-block-twofea .icon-boxfea {
  position: absolute;
  left: 30px;
  top: 50px;
  display: block;
  margin-bottom: 30px;
}
.feature-block-twofea .icon-boxfea .iconfea {
  position: relative;
  display: inline-block;
  font-size: 50px;
  line-height: 1em;
  background: rgb(247, 0, 104);
  background: -moz-linear-gradient(
    to left,
    rgba(247, 0, 104, 1) 0%,
    rgba(68, 16, 102, 1) 100%
  );
  background: -webkit-linear-gradient(
    to left,
    rgba(247, 0, 104, 1) 0%,
    rgba(68, 16, 102, 1) 100%
  );
  background: linear-gradient(
    to left,
    rgba(247, 0, 104) 0%,
    rgba(68, 16, 102, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
  background: none;
  color: #f14b59;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.feature-block-twofea .inner-boxfea:hover .icon-boxfea .iconfea {
  -webkit-transform: scale(-1) rotate(180deg);
  -moz-transform: scale(-1) rotate(180deg);
  -ms-transform: scale(-1) rotate(180deg);
  -o-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}
.feature-block-twofea h4 {
  position: relative;
  display: block;
  font-size: 22px;
  line-height: 1.2em;
  color: #12114a;
  font-weight: 700;
  margin-bottom: 10px;
}
.feature-block-twofea h4 a {
  color: #12114a;
  display: inline-block;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.feature-block-twofea h4 a:hover {
  color: #f14b59;
}
.feature-block-twofea .textfea {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #888888;
  font-weight: 600;
  cursor: default;
}
span.titlefea {
  font-size: 25px;
}
@media only screen and (max-width: 1139px) {
  .features-section-twofea .title-blockfea .inner-boxfea {
    background-color: transparent;
    padding: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .features-section-twofea .twist-line-3 {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .sec-titlefea {
    margin-bottom: 40px;
  }
  .sec-titlefea h2 {
    font-size: 42px;
  }
  .features-section-twofea {
    padding: 70px 0 50px;
    margin-top: 20px;
  }
  .feature-block-two .inner-boxfea {
    padding: 40px 20px;
    text-align: center;
  }
  .feature-block-two .icon-boxfea {
    position: relative;
    display: inline-block;
    left: 0;
    top: 0;
    margin-bottom: 20px;
  }
  .icon-boxfea {
    position: relative;
    top: -59px;
  }
  .features-section-twofea .twist-line-2 {
    display: none;
  }
}
@media only screen and (max-width: 599px) {
  .sec-titlefea h2 {
    font-size: 34px;
    line-height: 1.2em;
  }
}
.features-section-twofea {
  padding-top: 0px;
  padding-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  .sec-titlefea {
    margin-bottom: 40px;
    margin-top: 12px;
  }

  .features-section-twofea {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

