.form-and-map-container {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  margin-bottom: 3rem;
}

.form-container,
.image-container {
  flex-basis: 50%;
}

.image-container .map-element {
  width: 100%;
  height: 30rem !important;
}

.image-container {
  width: 100%;
  object-fit: cover;
  position: relative;
  position: relative;
}

.form-section-feed {
  text-align: center;
  margin-top: 90px;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 700;
  color: rgb(192, 22, 101);
}

.form-color-feed {
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 20px;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg,
      rgba(2, 0, 36, 1) 9%,
      rgba(9, 9, 121, 1) 68%,
      rgba(0, 91, 255, 1) 97%);
  backdrop-filter: blur(9px);
  border-radius: 10px;
}

.h1-tag {
  color: rgb(221, 21, 121);
  text-shadow: 0 0 2px black;
}

.form-group-feed {
  margin-bottom: 20px;
}

.exampletInputText {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: rgb(223, 217, 217);
}

.feedback-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
}

textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn-feed {
  background-color: #3498db;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary-feed {
  background-color: #2c3e50;
}


.contact-info {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background: #f7f7f7;
  border-radius: 10px;
  width: 1000px;
  margin: 0 auto;
}

.info-item {
  display: flex;
  align-items: center;
  text-align: left;
}

.info-icon {
  font-size: 20px;
  margin-bottom: 140px;
  margin-right: -90px;
  border: 2px solid black;
  border-radius: 50%;
  color: white;
  background-color: rgba(9, 9, 121, 1);
  height: 40px;
  width: 30px;
}

.info-item div p {
  margin: 0;
  line-height: 1.5;
}

@media (max-width: 600px) {

  .image-container,
  .info-icon {
    display: none;
  }

  .form-container {
    flex-basis: 100%;
  }

  .for-btn-centering.for-btn-centering {
    margin: auto;
    width: 100%;
  }

  .contact-info {
    display: none;
  }

  .contact-card {
    width: 100%;
  }
}