.card-heading {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.find-couple{
  background: url('https://images.pexels.com/photos/2680270/pexels-photo-2680270.jpeg?auto=compress&cs=tinysrgb&w=600');
}

.find-couple-section {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  /* padding: 20px; */
  /* background-color: #f8f8f8;  */
}

.step-card {
  text-align: center;
  max-width: 300px;
  margin: 30px;
  height: 300px;
}

.icon-sea {
  margin-bottom: 20px;
}

.icon-some img {
  width: 100%;
  height: 150px;
  border-radius: 10px;
}

.some-h3{
  color: #3e1313;
display:flex;
justify-content:center;
align-items:center;
/* margin-top:50px; */
}

.some-s{
  /* color: rgb(180, 14, 105); */
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  background: url('https://www.shutterstock.com/image-vector/orange-blue-pink-gradient-color-260nw-787735393.jpg');
  background-clip: text;
  color: transparent;
}

.find-your{
  /* color: rgb(185, 13, 108); */
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  background: url('https://www.shutterstock.com/image-vector/orange-blue-pink-gradient-color-260nw-787735393.jpg');
  background-clip: text;
  color: transparent;
}

.some-p{
  color: #fffdfd;
  font-weight: 600;
}
.couple-container1{
  width: 24%;
  /* min-height: 200px; */
  margin: 25px;
  border: 1px solid #441616;
  /* overflow: hidden; */
  background-color: #fff;
  /* background-image: url('https://images.pexels.com/photos/2680270/pexels-photo-2680270.jpeg?auto=compress&cs=tinysrgb&w=600'); */
  display: inline-block;
  border-radius: 1rem;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  
}

.find-couple {
  position: relative;
  padding: 20px;
  padding-left: 50px;
  overflow: hidden; 
}

.find-couple-section {
  display: flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  width: 100%;
  /* gap: 20px; */
  padding: 20px 0;
  justify-content: flex-start; 
}

.couple-container1 {
  flex: 0 0 calc(22% - 2px);
  width: 25%;
  scroll-snap-align: start; 
  border-radius: 1rem;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, 
              rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, 
              rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, 
              rgba(0, 0, 0, 0.06) 0px 2px 1px, 
              rgba(0, 0, 0, 0.09) 0px 4px 2px, 
              rgba(0, 0, 0, 0.09) 0px 8px 4px, 
              rgba(0, 0, 0, 0.09) 0px 16px 8px, 
              rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.arrow-button-register {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  padding: 6px;
  cursor: pointer;
  z-index: 10;
  width: 38px;
  border-radius: 5px;
}

.arrow-button-register.left {
  left: 10px;
}

.arrow-button-register.right {
  right: 10px;
}

/* .arrow-button-register:hover {
  background-color: rgba(0, 0, 0, 0.8);
} */

@media (max-width : 768px){
  .couple-container1{
    flex: 0 0 calc(45% - 2px);
  }
  .find-couple{
    padding-right: 35px;
  }
}
@media (max-width : 480px){
  .couple-container1{
    flex: 0 0 calc(95% + 3px);
  }
  .find-couple{
    padding-right: 35px;
  }
}