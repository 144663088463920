.latest-matches-container-comp {
  display: flex;
  background-image: url(https://images.unsplash.com/photo-1619204715997-1367fe5812f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1889&q=80);
}

.page-title-latest-comp h1 {
  position: relative;
  display: block;
  background: url(https://www.yachtandboat.com/wp-content/uploads/2018/12/hero-image-2.jpg)   -20px -20px no-repeat;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display:block;
  font-size: 100px;
  font-family: arial;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  background-size: cover;
}

.page-title-latest-comp .bread-crumbb-comp li:last-child {
  font-size: 20px;
  color: chocolate; 
  font-weight: bold; 
}

.page-title-latest-comp .bread-crumbb-comp li:last-child .index_dashboardb-copm a {
  font-size: 20px;
  color: chocolate; 
  font-weight: bold; 
}

.page-title-latest-comp {
  position: relative;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  margin-top: -50px;
  background-color: black;
  color: white;
  padding: 20px;
}

.auto-container-latest {
  max-width: 1200px;
  margin: 0 auto;
}

.bread-crumbb-comp {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bread-crumbb-comp li {
  display: inline;
  margin-right: 10px;
}
/* .cards-section-comp {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 10%;
  width: 150px;
  width: 100%;
  margin-bottom: 10%;  
 
} */

.cards-section-comp {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 30px; /* Adjust the gap between cards */
  width: calc(100% - 60px); /* Subtract padding from left and right sides */
  margin: 0 auto; /* Center align the grid */
  padding: 0 30px; /* Add padding to left and right sides */
}


 

.heroine-card-latest-comp {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  border-radius: 12px;  
  padding-left: 10px;
  padding: 38px;  
  filter: drop-shadow(0 30px 10px rgba(0,0,0,0.125));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:stretch;
  text-align: center;
  margin: 5px;
  margin-top: 10%;
  background-color: pink;
}

.heroine-card-latest-comp img {
  width: 100%;
  border-radius: 5px;
  height: 35vh;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.heroine-card-latest-comp img:hover {
  transform: scale(1.1);
}

.details-latest-comp {
  margin-top: 10px;
  color: white;
  font-weight: bolder;
}

.details-latest-comp h2 {
  font-family: 'Righteous', sans-serif;
  color: rgba(255,255,255,0.98);
  text-transform: uppercase;
  font-size: 2.4rem;
}

.details-latest-comp p {
  color: #fff;
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-size: 0.8rem;
  line-height: 150%;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-86-comp {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-weight: bolder;
  color: red;
  margin-left: 45px;
}

.button-86-comp::after,
.button-86-comp::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.button-86-comp::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

.button-86-comp::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-86-comp:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-86-comp:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-86-comp:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

.show-latest {
  color: blue;
  cursor: pointer;
  margin-left: -20px;
}

.show-more-btn {
  color: blue;
  cursor: pointer;
}

/* Media queries for tablets */
@media screen and (max-width: 1024px) {
  .cards-section-comp {
    grid-template-columns: repeat(2, minmax(200px, 1fr)); /* Change to two columns */
    gap: 5%; /* Adjust the gap between cards */
  }
}


/* Media queries for mobile phones */
@media screen and (max-width: 768px) {
  .cards-section-comp {
    grid-template-columns: repeat(1, minmax(150px, 1fr)); /* Change to one column */
    gap: 3%;
  }
}