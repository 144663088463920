/* Custom Dropdown Styling */
.custom-dropdown-cat {
    position: relative;
    width: 100%;
    height: 50px;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

.custom-dropdown-cat:hover {
    border-color: #888;
}
.page-title1{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
}
h1.d-nonepart.d-lg-blockpart.d-xl-blockpart.d-md-blockpart{
    color: #05595B;
}
.auto-container-pre{
    color: black;
    text-align: center;
    width: 85%;
    margin: 1px auto;
}
.custom-select-box-pre{
    width: 98%;
}
div.col-lg-3-pre.col-md-5-pre.col-sm-6-pre.form-group-pre{
    margin-right: 20px;
    margin-bottom: 30px;
}

/* Selected Option Styling */
.selected-option-cat {
    padding: 15px;
    margin-left: 5px;
    font-size: 15px;
    color: #1f1f1f;
    
}

.selected-option-cat::after {
    content: '▼';
    float: right;
    margin-right: -13px;
}

/* Dropdown Menu Styling */
.dropdown-menu-cat {
    position: absolute;
    top: 100%;
    left: 10px;
    right: 0;
    z-index: 1000;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.custom-select-box-cat {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    box-sizing: border-box;
}

/* Dropdown Options Styling */
.dropdown-options-cat {
    max-height: 200px;
    margin-top: 68px;
    overflow-y: auto;
}

.dropdown-option-cat {
    /* padding: 10px; */
    /* font-size: 14px; */
    /* color: #333; */
    left: 20px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-option-cat:hover {
    background-color: #f5f5f5;
}

/* Error Message Styling */
span[style*="color: red"] {
    display: block;
    margin-top: 5px;
    font-size: 12px;
}
/* @media screen and (max-width: 768px) {
    .custom-dropdown-cat {
        width: 55%;
        height: 50px;
        margin-left: 200px;
    }
    
} */

/* occ */
/* Custom Dropdown Styling */
.custom-dropdown-occ {
    position: relative;
    width: 100%;
    height: 50px;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

.custom-dropdown-occ:hover {
    border-color: #888;
}

/* Selected Option Styling */
.selected-option-occ {
    padding: 15px;
    margin-left: 5px;
    font-size: 15px;
    color: #1f1f1f;
    
}

.selected-option-occ::after {
    content: '▼';
    float: right;
    margin-right: -13px;
}

/* Dropdown Menu Styling */
.dropdown-menu-occ {
    position: absolute;
    width: 100%;
    margin-left: -10px;
    top: 100%;
    left: 10px;
    right: 0;
    z-index: 1000;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.custom-select-box-occ {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    box-sizing: border-box;
}

/* Dropdown Options Styling */
.dropdown-options-occ {
    max-height: 200px;
    margin-top: 68px;
    overflow-y: auto;
}

.dropdown-option-occ {
    /* padding: 10px; */
    /* font-size: 14px; */
    /* color: #333; */
    left: 20px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-option-occ:hover {
    background-color: #f5f5f5;
}

/* Error Message Styling */
span[style*="color: red"] {
    display: block;
    margin-top: 5px;
    font-size: 12px;
}
/* @media screen and (max-width: 768px) {
    .custom-dropdown-occ {
        width: 55%;
        height: 50px;
        margin-left: 200px;
        
    }
} */

  /* edu */
  /* Custom Dropdown Styling */
.custom-dropdown-edu {
    position: relative;
    width: 100%;
    height: 50px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
   color: black;
   z-index: 10;
}

.custom-dropdown-cat:hover,
.custom-dropdown-occ:hover,
.custom-dropdown-edu:hover {
    border-color: red;
}

/* Selected Option Styling */
.selected-option-cat,
.selected-option-occ,
.selected-option-edu {
    padding: 15px;
    margin-left: 5px;
    font-size: 15px;
    color: #1f1f1f;
}

.selected-option-cat::after,
.selected-option-occ::after,
.selected-option-edu::after {
    content: '▼';
    float: right;
    margin-right: -13px;
    color: black;
}

/* Dropdown Menu Styling */
.dropdown-menu-cat,
.dropdown-menu-occ,
.dropdown-menu-edu {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.dropdown-menu-cat {
    top: 100%;
    left: 10px;
}

.dropdown-menu-occ {
    top: 100%;
    margin-left: -10px;
    left: 10px;
}

.dropdown-menu-edu {
    top: 100%;
    left: 10px;
}

/* Select Box Styling */
.custom-select-box-cat,
.custom-select-box-occ,
.custom-select-box-edu,
.custom-select-box-pre-cas,
.custom-select-box-pre-edu,
.custom-select-box-pre-occ {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    box-sizing: border-box;
}

/* Dropdown Options Styling */
.dropdown-options-cat,
.dropdown-options-occ,
.dropdown-options-edu {
    max-height: 200px;
    margin-top: 68px;
    overflow-y: auto;
}

.dropdown-option-cat,
.dropdown-option-occ,
.dropdown-option-edu {
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-option-cat:hover,
.dropdown-option-occ:hover,
.dropdown-option-edu:hover {
    background-color: #f5f5f5;
}

/* Error Message Styling */
span[style*="color: red"] {
    display: block;
    margin-top: 5px;
    font-size: 12px;
}

/* General Button Styles */
button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
.custom-select-box-pre,
.agile-ltext-pre {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

[type=submit],
button,
html [type=button] {
    -webkit-appearance: button;
    appearance: true;
}

input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
    height: 20px;
    width: 20px;
}

.agile-ltext-pre {
    overflow: auto;
    resize: vertical;
}



.row-pre {
    
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 30px; */
    /* background: linear-gradient(90deg, rgba(2, 0, 36, 1) 9%, rgba(9, 9, 121, 1) 68%, rgba(0, 91, 255, 1) 97%); */
    /* margin-right: -15px;
    margin-left: -15px; */
    /* background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 9%,
      rgba(9, 9, 121, 1) 68%,
      rgba(0, 91, 255, 1) 97%
    ); */
    /* margin-left: 30px;
    margin-right: 30px; */
   
}
.row-pre1{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* width: 80%; */
    background-color: #f3f3f3;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: .5rem;
    padding: 2.5rem;
    margin: 4rem;
}

.col-lg-10-pre, .col-lg-12-pre, .col-lg-2, .col-lg-3-pre, .col-lg-5, .col-lg-9, 
.col-md-10-pre, .col-md-12-pre, .col-md-2, .col-lg-8-pre, .col-md-5-pre, 
.col-sm-12-pre, .col-sm-2 {
    position: relative;
    width: 80%;
    
    /* margin: 80px 30px 0; */
    padding: 0 15px;
    min-height: 1px;
}

/* Repeated class styles consolidated */
.col-lg-10-pre, .col-md-10-pre {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.col-lg-12-pre, .col-md-12-pre, .col-sm-12-pre {
    flex: 0 0 100%;
    max-width: 100%;
}

/* Other media query adjustments */
@media (min-width: 576px) {
    .col-sm-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
}
@media screen and (max-width: 498px) {
    .auto-container-pre {
        width: 98%;
        margin: 1px auto;
    }
    .col-lg-10-pre, .col-lg-12-pre, .col-lg-2, .col-lg-3-pre, .col-lg-5, .col-lg-9, .col-md-10-pre, .col-md-12-pre, .col-md-2, .col-lg-8, .col-md-5-pre, .col-sm-12-pre, .col-sm-2 {
        position: relative;
        width: 100%;
        min-height: 1px;
       
    }
    .row-pre1{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: .5rem;
        margin: .1rem;
    }
    .col-lg-10-pre, .col-lg-12-pre, .col-lg-2, .col-lg-3-pre, .col-lg-5, .col-lg-9, .col-md-10-pre, .col-md-12-pre, .col-md-2, .col-lg-8, .col-md-5-pre, .col-sm-12-pre, .col-sm-2 {
        margin: 15px auto;
    }
    
}
@media (min-width: 768px) {
    .col-md-2, .col-lg-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-lg-8-pre, .col-md-5-pre {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-md-5-pre {
        -ms-flex: 0 0 50.666667%;
        flex: 0 0 35.666667%;
        /* max-width: 41.666667%; */
    }
    .form-group-pre{
        margin-top: 0px !important;
    }
    .col-md-10-pre {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-md-12-pre {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .col-lg-3-pre {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
}

.form-group-pre {
    margin-top: 30px;
}

/* Button styling */
.btn-pre {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    width: 50%;
}

.btn-pre:focus, .btn-pre:hover {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.btn-pre:disabled {
    opacity: .65;
    cursor: not-allowed;
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    appearance: none;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
    background-size: 8px 10px;
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 5px rgba(128, 189, 255, .5);
}

.clearfix-pre::after {
    display: block;
    clear: both;
    content: "";
}

/* Media Queries */
@media screen and (max-width: 768px) {
    /* .custom-dropdown-cat,
    .custom-dropdown-occ,
    .custom-dropdown-edu {
        width: 55%;
        margin-left: 200px;
    } */
    
    .input-container-cas,
    .input-container-edu,
    .input-container-occ {
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        width: calc(100% - 20px);
        margin-left: 20px;
    }
    
    .input-container-cas-2,
    .input-container-edu-2,
    .input-container-occ-2 {
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        width: calc(100% - 50px);
        margin-right: 0;
    }
    
    .custom-search-cas,
    .custom-search-edu,
    .custom-search-occ {
        width: calc(47%);
        margin-left: 230px;
        margin-top: -35px;
    }
    
    .custom-select-box-pre-cas,
    .custom-select-box-pre-edu,
    .custom-select-box-pre-occ {
        width: calc(60% - 5px);
        margin-left: 150px;
        margin-top: -1px;
    }
    
    .btn-pre:not(:disabled):not(.disabled) {
        cursor: pointer;
        width: 50%;
    }

    .labp,
    .labp-cas {
        display: flex;
        margin-bottom: 10px;
        margin-right: 0;
        color: black;
    }
    
    .contact-form-pre .form-group-pre input[type="text"] {
        margin-bottom: -70px;
    }
    
    .form-group-pre {
        margin-top: 80px;
    }
    
    .contact-form-pre .form-group-pre input[type="text"],
    .contact-form-pre .form-group-pre .agile-ltext-pre,
    .contact-form-pre .form-group-pre .custom-select-box-pre {
        width: 100%;
        font-size: 14px;
        color: #312d2dba;
        line-height: 32px;
        padding: 0px 13px;
        border: 1px solid #a1a7a1;
        font-weight: 400;
        height: 50px;
        border-radius: 3px;
        transition: all 300ms ease;
    }

    .theme-btn-pre .btn-pre .btn-style-one-pre .mt-4-pre {
        width: 55%;
    }
    
    .col-lg-10-pre,
    .col-lg-12-pre,
    .col-lg-2,
    .col-lg-3-pre,
    .col-lg-5,
    .col-lg-9,
    .col-md-10-pre,
    .col-md-12-pre,
    .col-md-2,
    .col-lg-8,
    .col-md-5-pre,
    .col-sm-12-pre,
    .col-sm-2 {
        position: relative;
        width: 100%;
        margin-top: 8px;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .row-pre1 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1.1rem;
        margin: .3rem;
    }
}
/* Responsive adjustments */
@media screen and (max-width: 768px) {
    /* .row-pre {
        margin: 0 -129px 0 -54px;
    } */
    .contact-form-pre .form-group-pre .agile-ltext-pre {
        width: 55%;
        margin: 0 200px 0 0;
    }
    .contact-form-pre .form-group-pre .agile-ltext-pre:focus {
        border-color: #f20487;
        margin-left: 200px;
        margin-bottom: 0px;
        font-size: 14px;
        color: #312d2dba;
        line-height: 32px;
        padding: 0px 13px;
        border: 1px solid #a1a7a1;
        /* background-color: ffffff; */
        font-weight: 400;
        height: 50px;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;
        border-radius: 3px;
    } 
}

.contact-formpre .form-group-pre input:focus,
.contact-form-pre .form-group-pre select:focus,
.contact-form-pre .form-group-pre .agile-ltext-pre:focus {
    border-color: #f20487;
}

.contact-form-pre .form-group-pre .agile-ltext-pre {
    height: 75px;
    resize: none;
    color: black;
}
@media(max-width:768px){
    .contact-form-pre .form-group-pre .agile-ltext-pre {
        height: 65px;
        width: 98%;
    }   
}

.contact-form-pre .form-group-pre button {
    text-transform: capitalize;
}
.contact-page-section-pre {
    position: relative;
    /* margin: 0px; */
    /* padding: 10px 0 70px; */
}

.contact-page-section-pre .contact-column {
    position: relative;
    margin-bottom: 30px;
}

.contact-page-section-pre .contact-info {
    position: relative;
    margin-bottom: 37px;
}

.contact-page-section-pre .contact-info li {
    position: relative;
    padding-left: 55px;
    margin-bottom: 30px;
}

.contact-page-section-pre .contact-info li p {
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #777777;
    font-weight: 400;
    margin: 0;
}

.contact-page-section-pre .contact-info li strong {
    display: block;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
    font-weight: 600;
}

.contact-page-section-pre .contact-info li .icon {
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 5px;
    font-size: 18px;
    line-height: 40px;
    background-color: #1c94d2;
    color: #ffffff;
}



/*! CSS Used from: https://www.matrimonysoftware.in/css/responsive.css */
@media only screen and (max-width: 1023px) {
    .contact-page-section-pre .contact-column {
        order: 0;
    }
}

/* Other styles */
.theme-btn-pre {
    display: inline-block;
    transition: all 300ms ease;
}

.dropdown-menu {
    max-height: 180px !important;
    overflow-y: scroll !important;
}

.custom-select-box-pre-cas {
    height: calc(2.25rem + 14px);
    color: #312d2dba;
    padding: 0.375rem 0.75rem 0.375rem 1.10rem;
    text-align: left;
    border-color: #a1a7a1;
}

.custom-select-box-pre-cas:focus {
    border-color: #ec167f;
    outline: 0;
    box-shadow: none;
}

.form-check-label {
    color: #312d2dba;
}

@media screen and (max-width: 568px) {
    .form-group-pre{
        margin-top: 0px;
    }
    .page-title1{
        margin-bottom: 0px !important;
    }
    .Partner_Pref_form-pre {
        margin-top: 0px;
    }
}

.agile-ltext::placeholder {
    color: #6c757d;
    opacity: 1;
}


/*  1234 line code converted to 438 line code  */
