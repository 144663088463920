

a {
    background-color: transparent;
}

a:active,
a:hover {
    outline: 0;
}

img {
    border: 0;
}

button,
input {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button {
    text-transform: none;
}

button {
    -webkit-appearance: button;
    cursor: pointer;
}

input {
    line-height: normal;
}


/*! CSS Used from: Embedded */
.bcObJX {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 960px;
}

@media (max-width: 768px) {
    .bcObJX {
        min-width: 100%;
    }
}

.ibWcOp {
    width: 100%;
    height: 68px;
    background: linear-gradient(to right,
            rgb(57, 185, 196) 0%,
            rgb(66, 187, 183) 17%,
            rgb(110, 195, 130) 77%,
            rgb(124, 198, 113) 100%);
    margin: 50px 0px 0px;
}

.kENsbf {
    width: 940px;
    margin: 0px auto;
    text-align: center;
}

.dfsYEl {
    color: rgb(255, 255, 255);
    font: 300 22px / 68px Roboto, sans-serif;
    height: 68px;
    margin-top: 23px;
}

.cRFfri {
    display: inline-block;
    width: 20px;
    height: 18px;
    margin: 2px 8px;
    vertical-align: middle;
    background: url("https://my.shaadi.com/assets/new-tt-icon-normal.png") center 0px no-repeat;
    transition: all 300ms ease 0s;
    cursor: help;
}

.cRFfri:hover {
    background: url("https://my.shaadi.com/assets/new-tt-icon-hover.png") center 0px no-repeat;
}

.hmXycG {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
    background: rgb(255, 255, 255);
    display: flex;
    margin: 0px 0px 10px;
}

.bVZwEb {
    text-decoration: none;
    outline: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    height: 60px;
}

.eczwh {
    width: 430px;
    padding: 22px 8px 75px 15px;
}

.dktKwX {
    background: url("https://img2.shaadi.com/assests/2018/payment/cart-icon-v2.png") left -41px no-repeat;
    width: 20px;
    height: 18px;
    display: inline-block;
    margin: 0px 10px 0px 0px;
    vertical-align: middle;
}

.kHPvxg {
    background: url("https://img2.shaadi.com/assests/2018/payment/cart-icon-v2.png") left -66px no-repeat;
    width: 18px;
    height: 18px;
    display: inline-block;
    margin: 0px 10px 0px 0px;
    align-self: center;
}

.jTQKJY {
    background: url("https://img2.shaadi.com/assests/2018/payment/cart-icon-v2.png") left -187px no-repeat;
    width: 24px;
    height: 16px;
    display: inline-block;
    margin: 0px 2px 0px 0px;
    vertical-align: middle;
}

.ipJjwY {
    background: url("https://img2.shaadi.com/assests/2018/payment/cart-icon-v2.png") left -212px no-repeat;
    width: 25px;
    height: 18px;
    display: inline-block;
    margin: 0px 2px 0px 0px;
    vertical-align: middle;
}

.hpQWFC {
    display: inline-block;
}

.qDvlY {
    font-size: 11px;
    display: inline-block;
}

.ciGct {
    font: 500 14px Roboto, sans-serif;
    padding: 0px 0px 6px;
}

.gjnmID {
    font: 300 11px Roboto, sans-serif;
    color: rgb(114, 114, 125);
    padding: 0px 3px 0px 8px;
    display: inline-block;
}

.kmzIQb {
    display: flex;
}

.hdxXlm {
    width: 127px;
    text-align: left;
    color: rgb(81, 80, 93);
    font: 300 11px / 15px Roboto, sans-serif;
    align-self: center;
    margin-left: 8px;
    margin-top: 6px;
}

.cdjJsO {
    display: block;
    position: relative;
    text-align: center;
    width: 200px;
    height: 44px;
    font: 400 18px / 42px Roboto, sans-serif;
    color: rgb(255, 255, 255);
    background: rgb(0, 188, 213);
    border: 1px solid rgb(0, 188, 213);
    border-radius: 3px;
    cursor: pointer;
    outline: 0px;
    text-decoration: none;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 5px 0px,
        rgba(0, 0, 0, 0.12) 0px 2px 10px 0px;
    transition: all 300ms ease 0s;
    padding: 0px;
}

.cdjJsO:hover {
    background: rgb(1, 148, 168);
    border: 1px solid rgb(1, 148, 168);
    box-shadow: rgba(0, 0, 0, 0.18) 0px 5px 11px 0px,
        rgba(0, 0, 0, 0.15) 0px 4px 15px 0px;
}

.cdjJsO:active {
    padding: 0px;
}

.fOqjyz {
    text-align: center;
    font: 400 14px Roboto, sans-serif;
    color: rgb(114, 114, 125);
    padding: 12px 0px 0px;
    width: 200px;
    white-space: nowrap;
}

.ijhwRi {
    text-align: left;
    font: 500 11px Roboto, sans-serif;
    color: rgb(229, 58, 65);
}

.jBdDqR {
    height: 20px;
    padding: 5px 0px 0px;
}

.cJAYxD {
    display: block;
}

.bOwaUF {
    padding: 4px 0px 0px;
}

.jqlTtN {
    margin-bottom: 0px;
}

.lbYRBc {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.crgPQk {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 180px;
}

.dgIwMm {
    border: 1px solid rgb(223, 224, 227);
    box-sizing: border-box;
    background-clip: padding-box;
    border-radius: 3px;
    width: 260px;
    padding: 0px 40px 0px 8px;
    height: 34px;
    color: rgb(81, 80, 93);
    outline: none;
    transition: all 300ms ease 0s;
    font: 300 14px Roboto, sans-serif;
}

.jrfvuD {
    border: 1px solid rgb(223, 224, 227);
    box-sizing: border-box;
    background-clip: padding-box;
    border-radius: 3px;
    width: 260px;
    padding: 0px 8px;
    height: 34px;
    color: rgb(81, 80, 93);
    outline: none;
    transition: all 300ms ease 0s;
    font: 300 14px Roboto, sans-serif;
}

.fyvnBc {
    border: 1px solid rgb(223, 224, 227);
    box-sizing: border-box;
    background-clip: padding-box;
    border-radius: 3px;
    padding: 0px 8px;
    height: 34px;
    color: rgb(81, 80, 93);
    outline: none;
    transition: all 300ms ease 0s;
    font: 300 14px Roboto, sans-serif;
    width: 106px;
    margin-right: 10px;
    margin-top: 6px;
}

.irWuZh {
    border: 1px solid rgb(223, 224, 227);
    box-sizing: border-box;
    background-clip: padding-box;
    border-radius: 3px;
    padding: 0px 8px;
    height: 34px;
    color: rgb(81, 80, 93);
    outline: none;
    transition: all 300ms ease 0s;
    font: 300 14px Roboto, sans-serif;
    width: 64px;
    margin-top: 6px;
}

.fPUHwy {
    font: 500 14px Roboto, sans-serif;
    width: 194px;
}

.diDDwV {
    font: 500 14px Roboto, sans-serif;
}

.isjDnd {
    display: none;
}

.fNIYhF {
    border: 0px;
}

.BlJEc {
    width: 940px;
    margin: 24px auto 0px;
    display: flex;
}

.fUKKrN {
    width: 630px;
}

.hgzgVh {
    width: 290px;
    margin: 0px 0px 0px 20px;
}

.iQlaBc {
    background: url("https://img2.shaadi.com/assests/2018/payment/cart-icon-v2.png") left -323px no-repeat;
    width: 66px;
    height: 53px;
}

.hlPiFu {
    background: rgb(255, 255, 255);
    border-radius: 3px;
    width: 200px;
    height: 50px;
    margin: 0px 0px 0px 10px;
    position: relative;
}

.fpigcE {
    display: flex;
    padding: 18px 0px;
    margin: 0px 0px 0px 7px;
}

.jUbPgw {
    background: url("https://img2.shaadi.com/assests/2018/payment/cart-icon-v2.png") left -389px no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.iAWbmT {
    background: url("https://img2.shaadi.com/assests/2018/payment/cart-icon-v2.png") -40px -389px no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.cfQlpx {
    font: 300 12px / 18px Roboto, sans-serif;
    padding: 7px;
    text-align: center;
    color: rgb(81, 80, 93);
    display: block;
}

.eNHslR {
    padding: 20px 0px;
    font: 400 11px Roboto, sans-serif;
    text-align: center;
}

.BnmjI {
    background: url("https://img2.shaadi.com/assests/2018/payment/cart-icon-v2.png") left -270px no-repeat;
    width: 76px;
    height: 37px;
    display: inline-block;
    margin: 20px 0px 10px;
}

.fxWEPO {
    border-top: 1px solid rgb(216, 216, 216);
    margin-top: -80px;
    margin-left: 190px;
    margin-right: 14px;
}

.frWMHv {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
    background: rgb(255, 255, 255);
}

.icDnOi {
    padding: 18px 20px 11px;
    border-bottom: 1px dashed rgb(205, 206, 209);
    width: 250px;
}

.gLbDjK {
    background: rgb(244, 244, 247);
    padding: 16px 20px;
    width: 250px;
    border-top: 1px solid rgb(223, 224, 227);
    border-radius: 0px 0px 3px 3px;
}

.dNydxh {
    font: 500 14px Roboto, sans-serif;
    color: rgb(81, 80, 93);
    padding: 0px 0px 25px;
}

.dppBpF {
    display: flex;
}

.hdbkPH {
    width: 160px;
    font: 500 14px Roboto, sans-serif;
    color: rgb(81, 80, 93);
    flex: 1 1 0%;
    align-self: center;
}

.gdAvER {
    width: 110px;
    font: 400 14px Roboto, sans-serif;
    color: rgb(81, 80, 93);
    flex: 1 1 0%;
    align-self: center;
}

.dtRYQg {
    width: 130px;
    font: 500 24px Roboto, sans-serif;
    color: rgb(81, 80, 93);
    text-align: right;
}

.gTHJvb {
    width: 73px;
    font: 500 16px / 35px Roboto, sans-serif;
    color: rgb(81, 80, 93);
    text-align: right;
}

.jglwSZ {
    font: 400 14px Roboto, sans-serif;
    margin-top: 5px;
}

.kCKEoZ {
    width: 160px;
    font: 400 14px Roboto, sans-serif;
    color: rgb(81, 80, 93);
    flex: 1 1 0%;
    align-self: center;
}

.ijufJk {
    color: rgb(0, 188, 213);
    text-decoration: none;
}

.bZVwpz {
    display: inline-block;
    font-size: 0px;
    width: 9px;
    height: 9px;
    background: url("https://img2.shaadi.com/assests/2018/payment/cart-icon-v2.png") -9px top no-repeat;
    margin: 3px 0px 0px 5px;
    cursor: pointer;
    vertical-align: middle;
}

.iQXFIx {
    height: 18px;
    padding: 7px 10px 6px;
    position: relative;
    z-index: 999;
    font: 300 14px Roboto, sans-serif;
    transition: all 300ms ease 0s;
    align-self: center;
}

.iQXFIx:hover {
    background: rgb(241, 241, 242);
    border-radius: 3px;
}

.eUgWhv {
    background: rgb(251, 251, 251);
    border-radius: 3px;
    box-shadow: rgba(43, 59, 93, 0.35) 0px 4px 12px;
    font: 400 14px Roboto, sans-serif;
    position: absolute;
    right: 0px;
    top: 33px;
    width: 118px;
    z-index: 998;
    padding: 8px;
    margin: 0px;
    display: none;
}

.bzbhbu {
    text-decoration: none;
    outline: none;
    padding: 5px;
    display: block;
    font: 300 12px Roboto, sans-serif;
}

.bzbhbu:hover {
    background: rgb(241, 241, 242);
}

.blkwaB {
    margin: 3px 0px;
    color: rgb(0, 188, 213);
}

.fnPibn {
    background: rgb(255, 255, 255);
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0px;
    transition: all 300ms ease 0s;
    will-change: top;
    display: block;
    box-shadow: none;
}

@media (max-width: 768px) {
    .fnPibn {
        display: block;
    }
}

.iTkpGa {
    background: rgb(255, 255, 255);
    margin: 0px auto;
    width: 940px;
    display: flex;
    height: 50px;
}

.iDBUis {
    flex: 1 1 0%;
    width: 500px;
    align-self: center;
}

.gcMSym {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 56px;
}

/*! CSS Used from: https://my.shaadi.com/assets/checkout-script-6e4dadc9.css */
iframe {
    height: 38px !important;
}

/*! CSS Used from: https://my.shaadi.com/assets/desktop-b2bee9cf.css */
.upiIcon {
    background: url(https://img2.shaadi.com/assests/2019/payment/upi-icon.png) no-repeat left;
    width: 18px;
    height: 18px;
    display: inline-block;
    margin: 0 10px 0 0;
    align-self: center;
}

.paytmIcon {
    background: url(https://img2.shaadi.com/assests/2019/images/paytm-icon.png) no-repeat left;
    width: 20px;
    height: 19px;
    display: inline-block;
    margin: 0 10px 0 0;
    align-self: center;
}

.paytmLogo {
    background: url(https://img2.shaadi.com/assests/2019/images/paytm-logo.svg) no-repeat left;
    width: 52px;
    height: 20px;
    display: inline-block;
    background-size: 52px;
    margin: -2px 0 0;
}

.paytmDescWrapper {
    display: inline-block;
}

/*! CSS Used fontfaces */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"), local("sans-serif-light"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Light.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"), local("sans-serif"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"),
        local("sans-serif-medium"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"), local("sans-serif-light"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Light.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"), local("sans-serif"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"),
        local("sans-serif-medium"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"), local("sans-serif-light"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Light.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"), local("sans-serif"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"),
        local("sans-serif-medium"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"), local("sans-serif-light"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Light.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"), local("sans-serif"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"),
        local("sans-serif-medium"),
        url("https://my.shaadi.com/assets/fonts/Roboto-Medium.woff2") format("woff2");
}