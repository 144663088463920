/* ---------------------------------------------------------- */

.profilecard-title{
    /* color:rgb(145, 7, 92); */
    background: -webkit-linear-gradient(rgb(38, 10, 94), rgb(204, 21, 164));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display:flex;
    justify-content: center;
    margin-bottom:20px;
    font-size:33px;
    font-weight: 600;
}

.single-card-profile-container {
    width: 93%;
    margin: auto;
    height: 300px;
}

.single-card-profile-card {
    width:200px;
    height: 260px;
    margin:20px;
    border-radius:7px;
    background: linear-gradient(45deg, #ffbb01, #ff0058);
    cursor: pointer;
}

.single-card-profile-image {
    margin:0 10% 0 10%;
    width:80%;
    height:55%;
    background:gray;
    border-radius:10px;
    position:relative;
    margin-top:-20px;
    object-fit:cover;
    transition: all ease 1s;
    filter: blur(4px);
}
.single-card-profile-name {
    
text-align:center;
margin:0;
font-size:20px;
font-weight: 600;
font-style: italic;
background: linear-gradient(red,rgb(36, 36, 175),rgb(209, 145, 156));
color: rgb(225, 222, 231);
margin-top: 3px;
}
.single-card-profile-head {
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    margin-top:4px;
}
.single-card-profile-icn {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: whitesmoke;
}

/* .single-card-profile-btn {
    margin-top:30px;
    width:80%;
    height: 40px;
    margin:10px 10% 0 10%;
    border-radius:7px;
    transition: all ease 1s;
    color: rgb(74, 71, 71);
    border: none;
} */
.single-card-profile-btn{
    margin-top:30px;
    width:79%;
    height: 40px;
    margin:10px 10% 0 10%;
    background: rgb(207, 125, 18);
    color: white;
    border: none;
    border-radius: 0.625em;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    z-index: 1;
    overflow: hidden;
   }
   
   .single-card-profile-btn:hover {
    color: rgb(216, 26, 26);
   }
   
   .single-card-profile-btn:after {
    content: "";
    background: white;
    position: absolute;
    z-index: -1;
    left: -20%;
    right: -20%;
    top: 0;
    bottom: 0;
    transform: skewX(-45deg) scale(0, 1);
    transition: all 0.5s;
   }
   
   .single-card-profile-btn:hover:after {
    transform: skewX(-45deg) scale(1, 1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
   }

.single-card-profile-heart{
    color: white;
    font-Size:23px;
}

.red {
    color: red;
  }
  
.slick-slide {
    margin: 0 5px;
}
.slick-list,
.slick-track {
    max-height: 280px;
}


.slick-arrow
{
   background: #ff0058 !important;
   border-radius: 50%;
}
@media (max-width:735px) {
    .single-card-profile-container{
        width: 90%;
    }
}

@media (max-width:480px) {
    .single-card-profile-container{
        width: 85%;
    }
}
/* ------------------------------------popup css--------------------------------- */
.profile-card-pop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.profilecard-popup-container{
    width: 30%;
    height: 350px;
    display: flex;
    flex-direction: column;
    background:whitesmoke;
    border-radius: 10px;
    text-align: center;
}
.profilecard-popup-heading{
    width: 100%;
    height: 15%;
    background:#ff0058;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    border-radius: 10px 10px 0px 0px;
}
.profilecard-closeicon{

}
.close-icon{
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.profilecard-popup-body{
    width: 100%;
    height:65%;
    background:whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}
.error-image{
   width: 80px;
   height: 80px;
   border-radius: 50%;
}
.profilecard-popup-footer{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    margin: auto;
}
.profilecard-upgrade-btn{
    width:35%;
    height: 50px;
    border-radius: 10px;
    color:black;
    font-weight: 700;
    font-size: small;
    border: none;
    outline: none;
    margin: auto;
    background-color:#ffbb01;
}
@media (max-width:768px) {
    .profilecard-popup-container{
        width: 50%;
    }
    .pop-up-head{
     font-size: 16px;
    }
    .close-icon{

    }
    .profilecard-popup-body{
    font-size:13px;
    }

    .profilecard-upgrade-btn{
        width: 40%;
        height: 50%;
        font-size: 11px;
        font-weight: 700;
    }
}
@media (max-width:486px) {
    .profilecard-popup-container{
        width: 60%;
    }
    .profilecard-popup-body{
        font-size: 11px;
    }
    .error-image{
        width: 50px;
        height: 50px;
    }
    .profilecard-upgrade-btn{
        width: 45%;
        font-size:10px;
    }

}
