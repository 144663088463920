.interestlink {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 90%;
    margin: 50px auto;
    box-shadow: 0 0 10px black;
    padding: 50px;
    font-family: 'popins',sans-serif;
    box-sizing: border-box;
    border-radius: 30px;
    text-shadow: 0 0 100px rgba(151, 3, 3,0.6);
    /* background: rgb(2, 0, 36); */
    background: #f3f3f3;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    
  }

  .radio-btn{
  width: 20px;
  height: 30px;
  margin-bottom: 20px;
  }

 .interest-title{
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #05595B;
  font-size: 2.5rem;
 }
  .interestlist {
     margin-bottom: 2px;
    width: 95%;
      padding-bottom: 55px;
     margin-left: 100px; 
  }
 
  .radio-group {
    display: flex;
    padding-left: 100px;
    gap: 50px;
    width: 80%;
    padding-bottom: 8px;
    background-color: #ffffff;
  }


  .radio-btn[type="radio"] {
    margin: auto;
    padding: auto;
    accent-color:green; 
  }
 
  .yes {
   accent-color: green;
  }
 
  .no {
  accent-color:  red;
  }
 
  .input-box {
    margin-top: 10px;
    width: 40px;
  }
 
  .label-interest h10 {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 1.5em;
    padding-bottom: 10px;
    color: #232323;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 70px;
  }

  .label-yes{
    width: 10%;
    text-align: center;
  }
 
  .radio-btn[type="radio"][checked="checked"] {
    background-color: green;
  }
 
  .radio-btn[type="radio"]:not([checked="checked"]) {
    background-color: green;
  }

  .interest-btn{
    background-color: rgb(211, 12, 121);
    color: white;
    font-size: 20px;
    font-weight: 700;
    margin-top: 230px;
    width: 50%;
    height: 50px;
    border-radius: 5px;
    margin-left: -330px;
  }

  .interest-btn:hover{
    background-color: rgb(101, 16, 63)
  }


  .details-form{
    margin-top: 10px;
    width: 200px;
  }

  .input-details-text{
    margin-bottom: 15px;
    padding: auto;
    width: 90%;
    border-radius: 5px;

  }
 

  @media only screen and (max-width: 835px) {
    .interestlink {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 90%;
      margin: auto;
      margin-bottom: 50px;
      border-radius: 30px;
    
    }
    .interestlist {
       margin-bottom: 2px;
       width: 95%;
        padding-bottom: 55px;
       margin: auto;
    }
   
    .radio-group {
      display: flex;
      padding-left: 50px;
      gap: 50px;
      width: 80%;
      padding-bottom: 8px;
      background-color: #ffffff;
    }
  
    .label-yes{
      width: 35%;
      text-align: center;
    }
   
    .interest-btn{
      font-weight: 700;
      margin-top: 230px;
      width: 50%;
      margin-left: -130px;
    }

}

  @media only screen and (max-width: 430px) {
    .interestlink {
      display: grid;
      grid-template-columns: 1fr;
      max-width: 90%;
      margin: 20px;
      margin-bottom: 50px;
      border-radius: 30px;
    
    }
    .interestlist {
       margin-bottom: 2px;
       width: 90%;
      padding-bottom: 25px;
       margin: auto;
    }
   
    .radio-group {
      display: flex;
      /* padding-left: -10px; */
      margin-left: -40px;
      gap: 30px;
      width: 80%;
      padding-bottom: 8px;
      background-color: #ffffff;
    }

    .label-interest h10 {
      margin-bottom: 10px;
      font-size: 1.5em;
      padding-bottom: 10px;
      color: #232323;
      font-weight: 400;
      font-family: Arial, Helvetica, sans-serif;
      margin-left: -10px;
    }

    .interest-title{
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
     }
  

     label{
      margin-left: 10px;
     }
    .label-yes{
      width: 10%;
      text-align: center;
    }
   
    .interest-btn{
      font-weight: 700;
      margin-top: 30px;
      width: 30%;
      margin-left: 40px;
    }

}