/* Addphoto.css */

/* Photo card container */
.photo-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff; /* Added background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added box shadow */
}

/* Container for photo section */
.container-photo {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
}

/* Call to action section */
.call-to-action {
  text-align: center;
  margin-bottom: 20px;
}

.call-to-action h1 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333; /* Added text color */
}

/* File input */
.file-input {
  margin-top: 20px;
  margin-left: 50px;
  
}

/* Extra detail section */
.extra-detail {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.extra-detail p {
  margin: 0;
}

/* Add Photo Now button */
.add-photo-now-btn {
  text-align: center;
  margin-bottom: 20px;
}

.add-photo-now-btn button {
  font-size: 1rem;
  padding: 10px 20px;
  background-color: #ff6600;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
}

.add-photo-now-btn button:hover {
  background-color: #e65c00;
}

/* Submit button */
.Add-submit-btn {
  text-align: center;
  margin-top: 20px;
}

.Add-submit-btn button {
  font-size: 1rem;
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
}

.Add-submit-btn button:hover {
  background-color: #43a047;
}

/* User profile section */
.user-profile {
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}

/* User icon */
.user-photo{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
}

/* Add icon */
.add-icon {
  position: absolute;
  bottom: -10px;
  right: -10px;
  left:250px;
  font-size: 2rem;
  background-color: #fff;
  color: #333;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-icon:hover {
  background-color: #f0f0f0;
}

/* "OR" section */
.or {
  text-align: center;
  margin-top: 20px;
}

.oror {
  font-weight: bold;
  margin: 0;
}

/* Main button */
.main-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-photo {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-photo:hover {
  background-color: #0056b3;
}

/* Popup modal */
.popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.success-popup p {
  margin-bottom: 10px;
}

.success-popup button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.success-popup button:hover {
  background-color: #0056b3;
}

/* WhatsApp details */
.whatsapp-details {
  text-align: center;
  margin-bottom: 20px;
}

.whatsapp-details img {
  width: 50px;
  margin-bottom: 10px;
}

.whatsapp-details p {
  font-size: 1rem;
  margin: 5px 0;
}

/* Facebook details */
.facebook-details {
  text-align: center;
}

.facebook-details img {
  width: 50px;
  margin-bottom: 10px;
}

.facebook-details p {
  font-size: 1rem;
  margin: 5px 0;
  color: #666;
}


/* Media Queries for responsiveness */

@media (max-width: 768px) {
  .photo-card {
    width: 80%;
  }

  .file-input {
    margin-left: 0;
    width: 100%;
  }

  .call-to-action h1 {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #333; /* Added text color */
  }

  .add-photo-now-btn button {
    width:200px;
    font-size: 1rem;
    padding: 5px 10px;
    background-color: #ff6600;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  }
  .Add-submit-btn button {
    width:200px;
    font-size: 1rem;
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  }

}

@media (max-width: 480px) {
  .photo-card {
    width: 90%;
  }
  
}
