
/* ------------------------------------------------ updated footer css --------------------------------------------------------- */
.footer-card{
    width: 100%;
    min-height: 200px;
    /* background: black; */
    background: linear-gradient(270deg, #4d0930, #000000);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    padding: 2% 10%;
    /* position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px; */
}
.footer-logo {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        
}

.company-links-footer {
    color: white;

}

.company-links-footer p{
    margin-bottom: 5px;
    cursor: pointer;
}

.company-links-footer:hover{
    color: palevioletred;
}



.address-links-footer {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 5px;
}

.address-links-footer-item {
    margin-bottom: 4px;
}

.address-links-footer-item:nth-child(1):hover {
    color: palevioletred;
    transition: all ease-in-out 0.4s;
    cursor: pointer;
}

.address-links-footer-item:nth-child(1):hover .address-links-icon-footer {
    background: whitesmoke;
    transition: all ease-in-out 0.7s;
}

.address-links-footer-item:nth-child(2):hover {
    color: palevioletred;
    transition: all ease-in-out 0.4s;
    cursor: pointer;
}


.address-links-footer-main {
    width: fit-content;
}
.address-links-icon-footer {
    margin-right: 10px;
    padding:  0 5px 5px;
    background: palevioletred;
    height: fit-content;
    border-radius: 3px;
}
 .address-links-text-footer {
    line-height:17px;
    font-size:85%;
    color: rgb(172, 183, 157);
 }

.footer-icons {
    display: flex;
    justify-content: space-evenly;
    color: palevioletred;
  
} 

.footer-icon-links{
    display: flex;
    margin: 0;
    padding: 3px;
}
.footer-icon-links:hover{
            color: whitesmoke;
            background-color: palevioletred;
            transition: all ease-in-out 0.4s;
            border-radius: 7px;
}

@media (max-width : 768px) {
    .footer-card{
        grid-template-columns: repeat(3, 1fr);
        padding: 2% 5%;
        gap: 20px;
    }
    .address-links-footer-main {
        /* width: 200%; */
        margin-left: 25%;
    }
}

@media (max-width : 480px) {
    .footer-card{
        grid-template-columns: repeat(2, 1fr);
        padding: 2% 5%;
        gap: 10px;
    }
    .company-links-footer-1 {
        margin-left: 20%;
    }
    .address-links-footer-main {
        /* width: 200%; */
        margin-left: 0%;
    }
    .quicklinks{
        /* display: none; */
        font-size: 13px;
    }
    .footer-gmail{
        font-size: 12px;
    }
}

@media (min-width:768px) and (max-width:1024px){
    .footer-logo{
       margin-left: -30px;
       width: 90%;
    }
}