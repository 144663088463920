.logo-navimg {
  vertical-align: middle;
  border-style: none;
}
.btnL-nav {
  display: inline-block;
  margin-right: 30px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.collapse-nav:not(.show) {
  display: none;
}
.dropdown-nav {
  position: relative;
}
.navbar-collapse-nav {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-toggler-nav {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler-nav:focus,
.navbar-toggler-nav:hover {
  text-decoration: none;
}
.navbar-toggler-nav:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.clearfix-nav::after {
  display: block;
  clear: both;
  content: "";
}
.auto-container-nav {
  position: static;
  padding: 0px 15px;
  margin: 0 auto;
}
.logo-navimg {
  display: inline-block;
}
.theme-btnL-nav {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  transition: all 300ms ease;
}
.btn-style-oneL-nav {
  position: relative;
  padding: 7px 17px;
  line-height: 30px;
  color: #f8f3f3fa;
  text-align: center;
  background-color: #ec167f;
  overflow: hidden;
  border-radius: 10px;
}
.btn-style-oneL-nav .btn-titleL-nav {
  position: relative;
  font-size: 17px;
  font-weight: 550;
  color: rgb(248, 244, 244);
}

.btn-style-oneL-nav:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: #ffffff;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
  -webkit-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.btn-style-oneL-nav:hover:before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}
.logo-navimg {
  display: inline-block;
  height: auto;
  width: 60px;
  margin-top: 10px;
}
.main-header-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  background-color: #000000;
  height: 90px;
}
.main-header-nav .main-box-nav {
  position: relative;
  padding: 0px 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header-nav .logo-box-nav {
  position: relative;
  float: left;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header-nav .logo-box-nav .logo-nav .logo-navimg {
  display: inline-block;
  max-width: 100%;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header-nav .nav-outer-nav {
  position: relative;
  float: right;
  margin-top: -30px;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
}
.main-header-nav .nav-outer-nav .main-menu-nav {
  position: static;
  float: left;
}
.main-menu-nav .navigation-nav {
  position: static;
  margin-top: 15px;
}
.main-menu-nav .navigation-nav > li {
  position: relative;
  float: left;
  margin-left: 24px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu-nav .navigation-nav > li > a {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  padding: 10px 0;
  color: #fefefe;
  opacity: 1;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu-nav .navigation-nav > li > a:before {
  position: absolute;
  left: 50%;
  bottom: 10px;
  height: 2px;
  width: 0%;
  content: "";
  -webkit-transform: scale(-1);
  -moz-transform: scale(-1);
  -ms-transform: scale(-1);
  -o-transform: scale(-1);
  transform: scale(-1);
  background: rgb(247, 0, 104);
  background: -moz-linear-gradient(
    to left,
    rgba(247, 0, 104, 1) 0%,
    rgba(68, 16, 102, 1) 100%
  );
  background: -webkit-linear-gradient(
    to left,
    rgba(247, 0, 104, 1) 0%,
    rgba(68, 16, 102, 1) 100%
  );
  background: linear-gradient(
    to left,
    rgba(247, 0, 104) 0%,
    rgba(68, 16, 102, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu-nav .navigation-nav > li.current-nav > a:before,
.main-menu-nav .navigation-nav > li:hover > a:before {
  left: 0;
  width: 100%;
}
.main-menu-nav .navigation-nav > li > ul {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 250px;
  z-index: 100;
  display: none;
  opacity: 0;
  visibility: hidden;
  padding: 20px 0px;
  background: #ffffff;
  border-radius: 3px;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  -moz-transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transform: rotateX(90deg);
  border-bottom: 3px solid #f20487;
  border-radius: 0 0 6px 6px;
}
.main-menu-nav .navigation-nav > li > ul:before {
  position: absolute;
  content: "";
  left: 0px;
  top: -30px;
  width: 100%;
  height: 30px;
  display: block;
}
.main-menu-nav .navigation-nav > li > ul > li {
  position: relative;
  margin-bottom: 0px;
}
.main-menu-nav .navigation-nav > li > ul > li:last-child {
  margin-bottom: 0px;
}
.main-menu-nav .navigation-nav > li > ul > li > a {
  position: relative;
  display: block;
  padding: 5px 25px;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #222222;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.main-menu-nav .navigation-nav > li > ul > li:hover > a {
  color: #e1137b;
}
.main-menu-nav .navigation-nav > li.dropdown-nav:hover > ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.main-menu-nav .navbar-collapse-nav > ul li.dropdown-nav .dropdown-btn-nav {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #ffffff;
  line-height: 28px;
  border: 1px solid #ffffff;
  background-size: 20px;
  cursor: pointer;
  z-index: 5;
  display: none;
}
.outer-box-nav {
  position: relative;
  float: right;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 10px;
  display: flex;
}
.main-header-nav .search-box-btn {
  position: relative;
  float: left;
  font-size: 24px;
  line-height: 30px;
  cursor: pointer;
  background: none;
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
  margin-top: 10px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header-nav .outer-box-nav .btn-boxL-nav {
  position: relative;
  float: right;
  padding: 5px 0;
  margin-left: 20px;
}
.main-header-nav .outer-box-nav .btn-boxL-nav a {
  position: relative;
  float: right;
  line-height: 20px;
  font-size: 14px;
}
.btn-boxL-nav {
  margin-top: -5px;
}
.nav-outer-nav .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  color: #e1137b;
  margin: 7px 0px 2px;
  display: none;
}
.nav-outer-nav .mobileapp_Icon1-nav {
  display: none;
}
.header-style-two-nav .main-box {
  padding: 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.header-style-two-nav .outer-box-nav,
.header-style-two-nav .logo-box-nav,
.header-style-two-nav .main-menu-nav .navigation-nav > li {
  padding: 0px 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.theme-btnL-nav {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.theme-btnL-nav {
  position: relative;
  transition: all 300ms ease;
}
.main-menu-nav .navigation-nav > li > ul > li > a {
  padding: 5px 16px;
}
.dropdown-nav {
  position: relative;
}
.dropdown-nav ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.dropdown-nav:hover ul {
  display: block;
}
.dropdown-nav ul li {
  margin: 5px 0;
}
@media only screen and (max-width: 1139px) {
  .main-menu-nav .navigation-nav > li {
    margin-left: 35px;
  }
}
@media only screen and (min-width: 968px) {
  .main-menu-nav .navigation-nav > li > ul {
    display: block !important;
  }
}
@media only screen and (max-width: 1023px) {
  .main-header-nav,
  .main-header-nav .main-box {
    background-color: #1e1f36;
  }
  .main-header-nav .main-box {
    padding: 10px 0;
    background-color: #1e1f36;
  }
  .header-style-two-nav,
  .header-style-two-nav .main-box {
    background-color: #000000;
    padding: 0;
  }
  .header-style-two-nav .outer-box-nav,
  .header-style-two-nav .logo-box,
  .header-style-two-nav .main-menu-nav .navigation-nav > li {
    padding: 10px 0;
  }
  .header-style-two-nav .outer-box-nav .theme-btnL-nav {
    background-color: #e1137b;
    color: #ece9e9;
  }
  .header-style-two-nav .nav-outer-nav {
    float: right;
  }
  .main-header-nav .outer-box-nav {
    display: none;
  }
}
@media only screen and (max-width: 1100px) {
  .main-header-nav .nav-outer-nav .main-menu-nav {
    display: none !important;
  }
  .nav-outer-nav .mobileapp_Icon1-nav {
    position: absolute;
    left: -44px;
    top: 22px;
    display: block;
  }
  .main-header-nav .outer-box-nav {
    position: absolute;
    left: 0;
    top: 0;
  }
  .nav-outer-nav .mobile-nav-toggler {
    display: block;
    margin: 0;
  }
  .header-style-two-nav .nav-outer-nav {
    padding-top: 60px;
  }
  .main-header-nav,
  .header-style-two-nav {
    height: 80px;
  }
}
@media (max-width: 1100px) {
  .outer-box-nav .btn-boxL-nav {
    display: none;
  }
  .nav-outer-nav .menu-icon-button {
    margin-top: -20px;
    display: flex;
    justify-content: end;
  }
}
@media (min-width: 1100px) {
  .nav-outer-nav .menu-icon-button {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md-nav {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md-nav .navbar-collapse-nav {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}
