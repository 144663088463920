.help-desk-heading {
    width: 100%;
    height: 30px;
    display: flex;
    margin-top: 30px;
    align-items: center; /* Remove quotes */
    justify-content: center;
    color: red;
}

.help-desk-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 30px;
}

.help-desk-cont-one {
    width: 40%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 1.5px 0.5px 5.5px 2px pink;
}

.cont-one-map-data {
    background: whitesmoke;
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px; /* Corrected */
    padding: 5px;
    cursor: pointer;
}

.cont-one-map-data h6 {
    margin: 0;
}

.help-desk-cont-two {
    width: 28%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 1.5px 0.5px 5.5px 2px pink;
}

.cont-two-input-fields {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.cont-two-input-fields span {
    width: 100%;
    margin-bottom: 10px;
}

.cont-two-input-fields label {
    width: 30%;
    margin-left: 10px;
    font-weight: 500;
    font-size: 15px;
}

.cont-two-input-fields input {
    width: 60%;
    outline: none;
    border-radius: 5px;
    border: solid 0.5px gray;
}

.help-desk-cont-three {
    width: 23%;
    height: 200px;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 1.5px 0.5px 5.5px 2px pink;
}

.cont-three-head {
    color: red;
    font-size: 20px;
    display: flex;
    margin-left: 10px;
    margin-top: 10px;
}

@media (max-width: 786px) {
    .help-desk-heading {
        width: 100%;
    }

    .help-desk-heading h6 {
        font-size: 14px;
    }

    .help-desk-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: 20px 0px 0px 60px;
    }

    .help-desk-cont-one {
        width: 80%;
        gap: 15px;
    }

    .help-desk-cont-two {
        width: 80%;
    }

    .help-desk-cont-three {
        width: 80%;
    }
}

@media (max-width: 468px) {
    .help-desk-heading {
        width: 100%;
    }

    .help-desk-heading h6 {
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    .help-desk-container {
        margin: 20px 0px 0px 30px;
    }

    .help-desk-cont-two h5 {
        color: red;
    }

    .help-desk-cont-three .cont-three-head {
        color: red;
    }
}
